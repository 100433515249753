import { FC } from "react";

const EquipmentIcon: FC = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_1080)">
        <path
          d="M31.4986 0.455322H2.5075C1.1232 0.455322 0 1.63926 0 3.09638V19.2464H34V3.09638C34 1.63926 32.8768 0.455322 31.4986 0.455322ZM13.26 9.60497C13.8062 10.1781 12.9332 11.0184 12.3857 10.4428C12.3857 10.4428 10.4429 8.42711 10.4429 8.42711C10.2182 8.19032 10.2182 7.81997 10.4429 7.58318L12.3857 5.56746C12.9342 4.99694 13.8074 5.83429 13.26 6.40535C13.26 6.40532 11.7239 8.00819 11.7239 8.00819L13.26 9.60497ZM20.6793 12.7621C21.4741 12.7687 21.4821 13.9642 20.6793 13.9764H7.5407C6.74502 13.9657 6.73874 12.7702 7.54073 12.7621C7.5407 12.7621 20.6793 12.7621 20.6793 12.7621ZM14.9236 10.625L16.7571 5.00889C17.0114 4.25647 18.1443 4.61934 17.9107 5.38535C17.9107 5.38532 16.0771 11.0014 16.0771 11.0014C15.8117 11.7555 14.6883 11.3887 14.9236 10.625ZM20.4486 10.4428C19.8971 11.0159 19.0293 10.1795 19.5743 9.60494C19.5743 9.60497 21.1104 8.00818 21.1104 8.00818L19.5743 6.40532C19.3436 6.16852 19.3496 5.77994 19.5925 5.54926C19.8293 5.31855 20.2178 5.32458 20.4486 5.56746L22.3914 7.58318C22.6161 7.81997 22.6161 8.19032 22.3914 8.42711L20.4486 10.4428ZM6.04108 13.3692C6.04108 13.7032 5.77393 13.9764 5.43393 13.9764H3.96466C3.6307 13.9764 3.35752 13.7032 3.35752 13.3692C3.35752 13.0292 3.6307 12.7621 3.96466 12.7621H5.43393C5.77393 12.7621 6.04108 13.0292 6.04108 13.3692ZM15.7311 16.8785H3.96466C3.6307 16.8785 3.35752 16.6114 3.35752 16.2714C3.35752 15.9375 3.6307 15.6642 3.96466 15.6642H15.7311C16.5256 15.6781 16.5316 16.8672 15.7311 16.8785ZM24.8322 16.8785H18.4754C18.1414 16.8785 17.8682 16.6114 17.8682 16.2714C17.8682 15.9375 18.1414 15.6642 18.4754 15.6642H24.8322C25.63 15.6756 25.6323 16.8706 24.8322 16.8785ZM30.0414 16.8785H27.625C26.8269 16.8709 26.8249 15.6761 27.625 15.6642C27.625 15.6642 30.0414 15.6642 30.0414 15.6642C30.8391 15.6791 30.8394 16.8675 30.0414 16.8785ZM30.0414 13.9764H23.3628C22.5666 13.962 22.5637 12.7732 23.3629 12.7621C23.3628 12.7621 30.0414 12.7621 30.0414 12.7621C30.8369 12.7725 30.8414 13.9612 30.0414 13.9764Z"
          fill="#f8a101"
        />
        <path
          d="M0 20.4607V22.0332C0 23.4843 1.1232 24.6682 2.5075 24.6682H14.705V27.1635H11.5114C10.8739 27.1635 10.3032 27.54 10.0543 28.1289L8.68821 31.3407C8.22338 32.3429 9.04561 33.576 10.1454 33.5446C10.1454 33.5446 23.8546 33.5446 23.8546 33.5446C24.9526 33.5746 25.7784 32.3443 25.3118 31.3407C25.3118 31.3407 23.9457 28.1289 23.9457 28.1289C23.6968 27.54 23.1261 27.1635 22.4886 27.1635H19.295V24.6682H31.4986C32.8768 24.6682 34 23.4843 34 22.0332V20.4607H0ZM17 21.4139C17.9025 21.4272 17.9024 22.785 17 22.7982C16.0975 22.7849 16.0976 21.427 17 21.4139Z"
          fill="#f8a101"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_1080">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EquipmentIcon;
