export const UpStart = {autoAlpha: 0,y:20,opacity:0,duration: 0.3, stagger: 0.3};
export const InFinish = {autoAlpha: 1,y:0,opacity:1,duration: 0.3, stagger: 0.3};//,clearProps: "all"

export const anime1Start = {
    opacity:0,
    y: '5vw',
    duration: 1,
    delay: 0.1,
    stagger: 0.3, // Задержка между появлением каждой буквы
};
export const anime1Finish = {
    opacity:1,
    y: '0vw',
    duration: 1,
    delay: 0.1,
    stagger: 0.3, // Задержка между появлением каждой буквы
}

export const anime2Start = {
    opacity:0,
    y: '-50px',
    stagger: 0.3, // Задержка между появлением каждой буквы
};
export const anime2Finish = {
    opacity:1,
    y: '0vw',
    duration:  0.3,
    stagger: 0.3, // Задержка между появлением каждой буквы
}