import {FC} from 'react';
import LayoutTemplate from "../templates/LayoutTemplate";
import ContactsSection from "../organisms/ContactsSection/ContactsSection";

const ContactsPage:FC = () => {
    return (
        <LayoutTemplate>
            <ContactsSection/>

        </LayoutTemplate>
    );
};

export default ContactsPage;