import { FC } from "react";

const MarketingIcon: FC = () => {
  return (
    <svg
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.4171 32.6735H29.0578V20.4764C30.0983 20.2057 30.7178 19.5442 30.7178 18.6962C30.7207 17.9401 30.2427 17.225 29.5329 16.952C29.4006 16.098 28.7571 15.3823 27.8971 15.2078V13.6682C26.9054 13.4209 25.8813 13.4188 24.8899 13.6682C24.8899 13.6682 24.8959 15.1958 24.8959 15.1958C24.0057 15.3883 23.3562 16.104 23.2419 16.9821C22.5803 17.2828 22.1473 17.9444 22.1473 18.6962C22.1473 19.6103 22.7367 20.2779 23.7291 20.5065V32.6735H22.1534V22.1364C22.1534 21.8898 21.9488 21.6853 21.7023 21.6853H17.2757C17.0231 21.6853 16.8246 21.8898 16.8246 22.1364V32.6735H15.2488V24.9932C15.2488 24.7466 15.0444 24.5421 14.7978 24.5421H10.3712C10.1186 24.5421 9.92014 24.7466 9.92014 24.9932V32.6735H8.34439V28.6319C8.34439 28.3793 8.13988 28.1808 7.89331 28.1808H3.46676C3.21413 28.1808 3.01568 28.3793 3.01568 28.6319V32.6735H1.5121C0.937219 32.6775 0.907433 33.5644 1.51213 33.5757C2.74142 33.573 29.2374 33.5775 30.417 33.5757C30.998 33.5669 31.0131 32.6823 30.4171 32.6735Z"
        fill="#f8a101"
      />
      <path
        d="M29.1059 13.1088V12.0383C26.5678 10.8234 24.3546 11.6895 23.6749 12.0202V13.1088C25.4793 12.4119 27.2987 12.4104 29.1059 13.1088Z"
        fill="#f8a101"
      />
      <path
        d="M26.3935 0.364502C25.8341 0.911831 25.3529 1.52527 24.962 2.18687H27.8249C27.4279 1.52527 26.9468 0.911831 26.3935 0.364502Z"
        fill="#f8a101"
      />
      <path
        d="M29.106 11.0521C29.0394 8.76134 29.4487 5.10229 28.2639 3.08911H24.4929C23.365 5.17542 23.7243 8.73927 23.675 11.0401C24.6974 10.6251 26.7603 10.0838 29.106 11.0521ZM24.4208 6.41502C24.5025 3.80779 28.2849 3.80836 28.3662 6.41506C28.2845 9.02232 24.5021 9.02169 24.4208 6.41502Z"
        fill="#f8a101"
      />
      <path
        d="M27.464 6.41507C27.4258 5.00373 25.3608 5.00405 25.3229 6.41511C25.361 7.82643 27.426 7.82619 27.464 6.41507Z"
        fill="#FF600C"
      />
      <path
        d="M31.8124 9.17546L30.0081 7.2749C30.008 8.79879 30.0081 11.3204 30.0081 12.8442L31.1568 14.1012C31.2783 14.2409 31.4786 14.2786 31.65 14.2155C31.8244 14.1493 31.9387 13.9809 31.9387 13.7945V9.48822C31.9387 9.36792 31.8965 9.25966 31.8124 9.17546Z"
        fill="#f8a101"
      />
      <path
        d="M21.1309 14.2156C21.3052 14.2792 21.5006 14.2401 21.6301 14.1013L22.7728 12.8443C22.7728 12.8443 22.7729 8.1319 22.7728 7.28101L20.9685 9.17554C20.8903 9.25975 20.8422 9.36801 20.8422 9.4883V13.7946C20.8422 13.981 20.9564 14.1494 21.1309 14.2156Z"
        fill="#f8a101"
      />
      <path
        d="M6.28143 18.3294C8.78338 18.3294 10.8223 16.2965 10.8223 13.7946C10.5727 7.78378 1.99291 7.77961 1.74658 13.7946C1.74659 16.2965 3.77944 18.3294 6.28143 18.3294ZM6.28143 14.2456C4.24541 14.2313 3.83275 11.7857 5.8304 11.3106C5.83036 11.3106 5.83036 10.9558 5.83036 10.9558C5.83036 10.7092 6.03482 10.5047 6.28144 10.5047C6.75264 10.5279 6.74974 10.9508 6.73249 11.3106C7.49634 11.479 8.05567 12.0624 8.05567 12.7541C8.05567 13.0007 7.85117 13.2052 7.60459 13.2052C7.35196 13.2052 7.15352 13.0007 7.15352 12.7541C7.15352 12.4353 6.75655 12.1647 6.28143 12.1647C5.1442 12.1893 5.1445 13.319 6.28146 13.3435C7.26177 13.3435 8.05567 14.0111 8.05567 14.835C8.05567 15.5267 7.49634 16.1101 6.7325 16.2785C6.75636 16.6248 6.7238 17.0182 6.28141 17.0363C5.83947 17.011 5.80721 16.6334 5.83037 16.2785C5.07254 16.1101 4.5132 15.5267 4.5132 14.835C4.5132 14.5884 4.71169 14.3839 4.96428 14.3839C5.21085 14.3839 5.41536 14.5884 5.41536 14.835C5.43852 15.6074 7.13259 15.603 7.15352 14.835C7.15352 14.5163 6.75655 14.2456 6.28143 14.2456Z"
        fill="#f8a101"
      />
      <path
        d="M11.0456 21.4045L6.74099 24.0278C6.45903 23.7726 6.08987 23.6163 5.68 23.6159C4.80189 23.6159 4.08618 24.3317 4.08618 25.2097C4.14559 27.302 7.21477 27.3036 7.27378 25.2097C7.27354 25.0649 7.25605 24.9258 7.21897 24.7931L11.5197 22.1723C11.8024 22.4311 12.1754 22.5875 12.5845 22.5875C13.4626 22.5875 14.1783 21.8718 14.1783 20.9937C14.1783 20.9215 14.1723 20.8493 14.1662 20.7772L18.2801 18.9127C18.5748 19.2495 19.0078 19.466 19.4889 19.466C21.5806 19.4178 21.5841 16.3382 19.4889 16.2845C18.5301 16.2777 17.7793 17.1485 17.9072 18.0948C17.9072 18.0948 13.7873 19.9592 13.7873 19.9592C12.7209 18.6936 10.6009 19.7889 11.0456 21.4045Z"
        fill="#f8a101"
      />
    </svg>
  );
};

export default MarketingIcon;
