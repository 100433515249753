import { FC, useEffect, useRef } from "react";
import "./OnlineSection.scss";
import qtw_online_block from "../../../assets/images/qtw_online_block.png";
import { useTranslation, Trans } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useDispatchEx } from "../../../hooks/redux";
import { anime1Finish, anime1Start } from "../../../helpers/animations";

const OnlineSection: FC = () => {
  gsap.registerPlugin(ScrollTrigger);

  const targetSection = useRef(null);
  useEffect(() => {
    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: targetSection.current,
        start: "+=20% bottom",
      },
    });
    timeline.fromTo(".online-section__online", anime1Start, anime1Finish, 0.1);
    timeline.fromTo(".online-section__image", anime1Start, anime1Finish, 0.4);
    timeline.fromTo(".online-section__inner", anime1Start, anime1Finish, 0.4);
  }, [targetSection]);
  const { t } = useTranslation();
  const dispatch = useDispatchEx();
  return (
    <section className={"online-section"} ref={targetSection}>
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-6">
              <div className="online-section__wrapper">
                <div className="online-section__online">
                  <div className="online-section__online-title">
                    <span>All in one</span> smart system
                  </div>
                  <div className="online-section__online-wrapper">
                    <div className="online-section__online-word">
                      <svg
                        width="252"
                        height="86"
                        viewBox="0 0 252 86"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.92 65.68C13.92 68.72 14.52 70.88 15.72 72.16C17 73.36 18.68 73.96 20.76 73.96C22.84 73.96 24.48 73.36 25.68 72.16C26.96 70.88 27.6 68.72 27.6 65.68V20.32C27.6 17.28 26.96 15.16 25.68 13.96C24.48 12.68 22.84 12.04 20.76 12.04C18.68 12.04 17 12.68 15.72 13.96C14.52 15.16 13.92 17.28 13.92 20.32V65.68ZM0.72 21.16C0.72 14.36 2.44 9.15999 5.88 5.55999C9.32 1.88 14.28 0.0399995 20.76 0.0399995C27.24 0.0399995 32.2 1.88 35.64 5.55999C39.08 9.15999 40.8 14.36 40.8 21.16V64.84C40.8 71.64 39.08 76.88 35.64 80.56C32.2 84.16 27.24 85.96 20.76 85.96C14.28 85.96 9.32 84.16 5.88 80.56C2.44 76.88 0.72 71.64 0.72 64.84V21.16ZM61.1381 24.16V85H49.2581V0.999994H65.8181L79.3781 51.28V0.999994H91.1381V85H77.5781L61.1381 24.16ZM100.589 0.999994H113.789V73H135.509V85H100.589V0.999994ZM141.488 0.999994H154.688V85H141.488V0.999994ZM176.099 24.16V85H164.219V0.999994H180.779L194.339 51.28V0.999994H206.099V85H192.539L176.099 24.16ZM228.75 36.4H246.87V48.4H228.75V73H251.55V85H215.55V0.999994H251.55V13H228.75V36.4Z"
                          fill="#f8a101"
                        />
                      </svg>
                    </div>
                    <div className="online-section__online-list">
                      <div className="online-section__online-list__block">
                        <div className="online-section__online-list__block-item">
                          MENU
                        </div>
                        <div className="online-section__online-list__block-item">
                          WAITER
                        </div>
                      </div>
                      <div className="online-section__online-list__block">
                        <div className="online-section__online-list__block-item">
                          MANAGER
                        </div>
                        <div className="online-section__online-list__block-item">
                          PAYMENT
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="online-section__image">
                  <img
                    src={qtw_online_block}
                    alt="Quick Touch Waiter app screenshots"
                  />
                  <div className="online-section__image-title">
                    All restaurant management in the palm of your hand
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="online-section__inner">
                <div className="online-section__header">
                  <h2>
                    <span>No more expenses</span>
                  </h2>
                </div>
                <div className="online-section__subtitle">
                  Installation, subscription, developers & no additional fee
                </div>
                <div className="online-section__description">
                  <p>
                    You only pay, transaction fees when your restaurant is up
                    and running
                  </p>
                  <p>We carry out your publishing and hosting</p>
                </div>
                <div className="online-section__benefits">
                  <div className="online-section__benefit">
                    <div className="online-section__benefit-wrapper">
                      <div className="online-section__benefit-title online-section__benefit-free-plan-title">
                        0
                      </div>
                      <div className="online-section__benefit-subtitle">
                        Free
                      </div>
                      <div className="online-section__benefit-description">
                        per month
                      </div>
                    </div>
                  </div>
                  <div className="online-section__benefit">
                    <div className="online-section__benefit-wrapper">
                      <div className="online-section__benefit-title">6.5%</div>
                      <div className="online-section__benefit-subtitle">
                        Processing
                      </div>
                      <div className="online-section__benefit-description">
                        per each payment
                      </div>
                    </div>
                  </div>
                </div>
                <div className="online-section__header">
                  <h2>
                    <span>All in one app</span>
                  </h2>
                </div>
                <div className="online-section__subtitle">
                  Restaurant management and customer service
                </div>
                <div className="online-section__description">
                  <p>
                    Get your own online marketing tools and points of sales
                    instantly without investment or development costs.
                  </p>
                  <p>
                    Manage all types of online orders and bookings, without
                    waiters and additional staff help.
                  </p>
                  <p>
                    Accept online payments and tips through your mobile device.
                    No bank terminal or additional equipment needed.
                  </p>
                  <p>
                    All management, data and statistics are collected on one
                    screen with online orders and payments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OnlineSection;
