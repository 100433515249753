import './SocialItem.scss';
import {FC, ReactNode} from 'react';

interface ISocialItem {
    link?: string
    children?: ReactNode
}

const SocialItem:FC<ISocialItem> = ({link,children}) => {
    return (
        <a className={'social__item'} href={link}>
            {children}
        </a>
    );
};

export default SocialItem;