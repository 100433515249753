import React from 'react';

const PolicyDe = () => {
    return (
        <>
            <p><strong>Datenschutzerkl&auml;rung</strong></p>
            <p>Mit der Online-Registrierung auf der Website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu. eu</a> oder in der mobilen Anwendung vertrauen Sie AuraTrust O&Uuml;, Registrierungsnummer in der Republik Estland 16562585, Sitz: Ravi tn&nbsp;2, 10134, Tallinn, Estland, als dem Verantwortlichen f&uuml;r die Verarbeitung personenbezogener Daten (nachfolgend Gesellschaft oder wir oder der Verantwortliche genannt) Ihre personenbezogenen Daten und r&auml;umen uns das Recht ein, diese in &Uuml;bereinstimmung mit dieser Richtlinie (nachfolgend Richtlinie genannt) und im Rahmen des Herunterladens und der Nutzung der mobilen Anwendung und in Verbindung mit der Registrierung und dem Beitritt und der Bereitstellung der Dienste in &Uuml;bereinstimmung mit den entsprechenden Nutzungsbedingungen (nachfolgend Bedingungen genannt) zu verarbeiten. Bitte beachten Sie, dass wir Ihre personenbezogenen Daten nur dann verarbeiten, wenn Sie uns Ihre personenbezogenen Daten zur Verf&uuml;gung stellen.</p>
            <p>Mit der Umsetzung der Verordnung (EU) 2016/679 des Europ&auml;ischen Parlaments und des Rates vom 27.&nbsp;April 2016 zum Schutz nat&uuml;rlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung) bieten wir Ihnen eine besonders transparente und faire Verarbeitung Ihrer personenbezogenen Daten und k&uuml;mmern uns au&szlig;erdem um Ihre Privatsph&auml;re und die Sicherheit Ihrer personenbezogenen Daten.</p>
            <p>In dieser Richtlinie finden Sie alle Informationen dar&uuml;ber, welche Daten wir erheben und verarbeiten, wof&uuml;r wir sie verwenden, wie lange wir sie aufbewahren usw. Diese Informationen sind wichtig, daher bitten wir Sie, sich sorgf&auml;ltig mit den Bestimmungen dieser Richtlinie vertraut zu machen, bevor Sie uns Informationen &uuml;ber Ihre personenbezogenen Daten zur Verf&uuml;gung stellen.</p>
            <p>Bitte beachten Sie auch, dass sowohl die Richtlinie als auch die Bedingungen ge&auml;ndert, erg&auml;nzt und aktualisiert werden k&ouml;nnen.</p>
            <p>Wenn Sie die Bedingungen, diese Richtlinie oder bestimmte ihrer Bestimmungen nicht akzeptieren, k&ouml;nnen wir Ihnen leider nicht die M&ouml;glichkeit geben, alle oder einige unserer Dienste (nachfolgend Dienste genannt) zu nutzen.</p>
            <ol>
                <li><strong> Was sind personenbezogene Daten und welche Ihre personenbezogenen Daten verarbeiten wir und warum?</strong></li>
            </ol>
            <p><strong>Anmeldung</strong></p>
            <p><strong>Personenbezogene Daten sind alle Informationen, die sich auf eine bestimmte oder bestimmbare nat&uuml;rliche Person (&bdquo;betroffene Person&ldquo;) beziehen; als bestimmbar wird eine nat&uuml;rliche Person angesehen, die direkt oder indirekt identifiziert werden kann, insbesondere durch Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck ihrer physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identit&auml;t sind.</strong></p>
            <p>Wenn Sie sich auf der Website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> oder in der mobilen Anwendung registrieren, m&uuml;ssen Sie die Nutzungsbedingungen der Website oder der mobilen Anwendung akzeptieren (dem Vertrag zustimmen). Die &Uuml;bermittlung Ihrer pers&ouml;nlichen Daten ist obligatorisch, da eine Nichtbereitstellung Sie daran hindert, den Vertrag abzuschlie&szlig;en und unsere Dienste zu nutzen. Mit der Anmeldung schlie&szlig;en Sie den Vertrag ab und best&auml;tigen, dass die von Ihnen angegebenen personenbezogenen Daten korrekt sind und Sie nicht j&uuml;nger als 18&nbsp;Jahre sind. Wir &uuml;bernehmen keine Haftung f&uuml;r ungenaue, unvollst&auml;ndige oder fehlerhafte personenbezogene Daten, die Sie uns zur Verf&uuml;gung stellen. F&uuml;r den Fall, dass Sie andere personenbezogene Daten zur Verf&uuml;gung gestellt haben, beh&auml;lt sich AuraTrust O&Uuml; das Recht vor, sich mit einem Regressanspruch an Sie zu wenden.</p>
            <p><strong>F&uuml;r den Vertrag erforderliche Angaben:</strong></p>
            <ul>
                <li>Datenkategorien: Ihr Name, Adresse, E-Mail-Adresse und Telefonnummer.</li>
                <li>Die Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist der Abschluss und die Erf&uuml;llung eines Vertrags, dessen Partei Sie sind, sowie die Wahrung unserer berechtigten Interessen.</li>
                <li>Datenverarbeitungszeitraum&nbsp;&ndash; der gesamte Zeitraum, in dem der Vertrag in Kraft ist und Sie unsere Dienste nutzen. Wir k&ouml;nnen den Vertrag und die Nachweise &uuml;ber seinen Abschluss mit Ihnen auch l&auml;nger aufbewahren, w&auml;hrend die Haftungsanspr&uuml;che auslaufen, die sich aus vertraglichen Verpflichtungen ergeben k&ouml;nnen, sowie wenn es f&uuml;r uns notwendig ist, unsere berechtigten Interessen gegen Forderungen, Anspr&uuml;che oder Klagen gegen uns zu verteidigen.</li>
            </ul>
            <p>Wir erkennen Sie an den personenbezogenen Daten, die Sie uns zur Verf&uuml;gung stellen, wenn Sie z.&nbsp;B. Ihre Daten aktualisieren oder &auml;ndern m&ouml;chten, wenn Sie uns im Zusammenhang mit der &Uuml;bermittlung relevanter personenbezogener Informationen kontaktieren, wenn Sie Rechte im Zusammenhang mit der Verarbeitung personenbezogener Daten aus&uuml;ben m&ouml;chten usw. Anhand der personenbezogenen Daten, die Sie bei der Registrierung auf der Website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> und/oder der mobilen Anwendung angegeben haben, wird nach Abschluss des Vertrages ein eindeutiges Benutzerprofil (Konto) f&uuml;r Sie erstellt, nach dem wir Sie als registrierten Benutzer der Website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> und/oder der mobilen Anwendung identifizieren, was Ihnen die Nutzung der f&uuml;r registrierte Benutzer geltenden Dienste gem&auml;&szlig; den Bedingungen des Vertrages und den Bestimmungen dieser Richtlinie erm&ouml;glicht.</p>
            <p>Wir k&ouml;nnen die von Ihnen angegebenen Kontaktdaten (E-Mail, Telefonnummer) verwenden, um mit Ihnen zu kommunizieren, um unsere Verpflichtungen aus dem Vertrag zu erf&uuml;llen, einschlie&szlig;lich der Beantwortung Ihrer Anfragen, Reklamationen, der &Uuml;bermittlung wichtiger Informationen &uuml;ber die Dienste, deren Bereitstellung und/oder &Auml;nderungen dieser Richtlinie, der Kontaktaufnahme mit Ihnen, wenn Sie Ihre Eink&auml;ufe vergessen haben oder wenn wir bei der Erf&uuml;llung der Verpflichtungen aus dem Vertrag im Zusammenhang mit den von Ihnen get&auml;tigten K&auml;ufen auf Schwierigkeiten sto&szlig;en usw.</p>
            <p>Es ist sehr wichtig, dass die von Ihnen zur Verf&uuml;gung gestellten personenbezogenen Daten korrekt und richtig sind. Wenn Sie unrichtige (falsche) personenbezogene Daten angeben, vergessen oder vers&auml;umen, Ihre personenbezogenen Daten zu aktualisieren, wenn sich Ihre personenbezogenen Daten &auml;ndern, kann es f&uuml;r uns schwierig sein, unseren Verpflichtungen aus dem Vertrag nachzukommen und Ihnen die Dienste zur Verf&uuml;gung zu stellen, sowie es k&ouml;nnen andere Schwierigkeiten f&uuml;r die Aus&uuml;bung Ihrer Rechte auftreten. Wir haften in keinem Fall f&uuml;r Sch&auml;den, die Ihnen durch die Angabe falscher oder ungenauer personenbezogener Daten entstehen.</p>
            <p>Wenn sich die von Ihnen angegebenen Daten &auml;ndern, m&uuml;ssen Sie uns dies unverz&uuml;glich mitteilen, indem Sie die entsprechenden personenbezogenen Daten in Ihrem registrierten Benutzerprofil auf der Website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> oder in der mobilen Anwendung &auml;ndern. Unter Punkt&nbsp;6.2 dieser Richtlinie finden Sie Informationen dar&uuml;ber, wie Sie Ihre personenbezogenen Daten, die sich ge&auml;ndert haben, aktualisieren k&ouml;nnen.</p>
            <p>Die Gesellschaft hat keine M&ouml;glichkeit, die Richtigkeit und &Uuml;bereinstimmung der personenbezogenen Daten, die Sie uns zur Verf&uuml;gung stellen, zu &uuml;berpr&uuml;fen, wenn Sie uns diese nicht zur Verf&uuml;gung stellen. Indem wir Ihre Anmeldung akzeptieren und einen Vertrag mit uns abschlie&szlig;en, gehen wir davon aus, dass die von Ihnen angegebenen personenbezogenen Daten korrekt sind.</p>
            <p><strong>1.2 Verwaltung Ihres Kontos</strong></p>
            <p>Wir verarbeiten Ihre personenbezogenen Daten, um Ihr Konto zu &uuml;berwachen und zu verwalten.</p>
            <p><strong>Kontodaten:</strong></p>
            <p>Datenkategorien&nbsp;&ndash; Daten, die bei der Registrierung (auf der Website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a>) angegeben werden, Kontoanmeldedaten, Kontoaktivit&auml;ten, einschlie&szlig;lich technischer Browsing-Daten (IP-Adresse, technische Informationen zu Login und Browsing).</p>
            <p>Die Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist der Vertrag, den Sie abgeschlossen haben, und das Konto, das Sie erstellt haben, f&uuml;r die Erf&uuml;llung der Verpflichtungen aus dem Vertrag und f&uuml;r die Wahrung unserer berechtigten Interessen.</p>
            <p>Dauer der Datenverarbeitung&nbsp;&ndash; der gesamte Zeitraum, in dem Sie der Nutzer des Kontos sind. Wir k&ouml;nnen den Nachweis des Vertragsabschlusses auch l&auml;nger aufbewahren, wenn dies erforderlich ist, um uns gegen Forderungen, Anspr&uuml;che oder Klagen gegen uns zu verteidigen.</p>
            <p>Wir betrachten Ihre Registrierung f&uuml;r ein Konto auf der Website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> als eingegangen, wenn Sie die f&uuml;r die Erstellung eines Kontos gem&auml;&szlig; den Bedingungen erforderlichen Schritte abgeschlossen und Ihre Registrierung best&auml;tigt haben.</p>
            <p><strong>1.3 Verarbeitung Ihrer Kaufdaten</strong></p>
            <p><strong>Verarbeitung von Kaufdaten</strong></p>
            <p>Zus&auml;tzlich zu Ihren Registrierungsdaten verarbeiten wir Daten &uuml;ber Ihre Kauftransaktionen (nachfolgend Kaufdaten genannt), wenn wir Ihnen die Dienste und verschiedene Vorteile zur Verf&uuml;gung stellen.</p>
            <p><strong>Ihre Kaufdaten, die zum Zweck der Verwaltung der Dienste verarbeitet werden:</strong></p>
            <p>Datenkategorien&nbsp;&ndash; Ihr Name, E-Mail-Adresse, Telefonnummer, Adresse, Produktbezeichnungen, Mengen, Kaufpreise und gew&auml;hrte Rabatte, Zahlungsart und Zahlungsdetails.</p>
            <p>Die Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist der Vertrag, den Sie abgeschlossen haben, und das Konto, das Sie erstellt haben, f&uuml;r die Erf&uuml;llung der Verpflichtungen aus dem Vertrag sowie f&uuml;r die Wahrung unserer berechtigten Interessen und f&uuml;r die Erf&uuml;llung rechtlicher Verpflichtungen, die f&uuml;r die Gesellschaft entstehen und gelten.</p>
            <p>Dauer der Datenverarbeitung&nbsp;&ndash; 5&nbsp;Jahre nach der Kauftransaktion. Wir k&ouml;nnen Dokumente auch l&auml;nger aufbewahren, w&auml;hrend die Haftungsanspr&uuml;che auslaufen, die sich aus vertraglichen Verpflichtungen ergeben k&ouml;nnen, sowie wenn es f&uuml;r uns notwendig ist, unsere berechtigten Interessen gegen Forderungen, Anspr&uuml;che oder Klagen gegen uns zu verteidigen.</p>
            <p><strong>1.4 Datenprofilierung bei der Verwaltung der Dienste</strong></p>
            <p>Bei der Verwaltung der Dienste und bei der Verfolgung der in den Bedingungen dargelegten Zwecke k&ouml;nnen wir eine automatisierte Analyse personenbezogener Daten (einschlie&szlig;lich Ihrer Kaufdaten) und eine automatisierte Entscheidungsfindung, einschlie&szlig;lich Profilierung verwenden. Wir k&ouml;nnen Ihre Daten nach den Produkten, die Sie gekauft haben, und/oder nach anderen Merkmalen (z.&nbsp;B. Name, Adresse usw.) gruppieren und analysieren.</p>
            <p>Zum Beispiel k&ouml;nnen wir Ihre Kaufdaten in Ihrem Interesse analysieren und profilieren, indem wir Fehler in unseren Verfahren korrigieren. Wenn wir feststellen, dass unseren Kunden irrt&uuml;mlich ein falscher Preis berechnet wurde oder sie einen unangemessenen Rabatt erhalten haben, k&ouml;nnen wir die K&auml;ufer, die diese Artikel gekauft haben, anhand der Kaufdaten identifizieren und unseren Fehler korrigieren (Erstattung der Preisdifferenz usw.).</p>
            <p>Es ist wichtig zu betonen, dass unsere Analyse und Profilierung Ihrer Daten Sie weder rechtlich noch in irgendeiner anderen Weise beeintr&auml;chtigt.</p>
            <p>Wenn Sie jedoch der automatisierten Analyse und Profilierung Ihrer Daten zu den in diesem Abschnitt der Richtlinie genannten Zwecken widersprechen, werden wir Ihre Einw&auml;nde akzeptieren und ber&uuml;cksichtigen. Aufgrund der Anforderungen der Dienste kann Ihr Widerspruch jedoch dazu f&uuml;hren, dass wir Ihnen nicht die M&ouml;glichkeit geben k&ouml;nnen, unsere Dienste zu nutzen.</p>
            <p><strong>1.5 Bereitstellung von Angeboten und Informationen</strong></p>
            <p>Wenn Sie sich auf der Website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> oder in der mobilen Anwendung registrieren, haben Sie zugestimmt, Angebote und/oder Aktualisierungen in Ihrer E-Mail oder mobilen Anwendung oder SMS-Newsletter auf Ihrem Telefon von uns zu erhalten. Dann verarbeiten wir Ihre personenbezogenen Daten, um Ihnen solche Angebote und Informationen zukommen zu lassen, wie z.&nbsp;B. allgemeine Newsletter, Informationen &uuml;ber Angebote, Rabatte, Aktionen, Verk&auml;ufe, die von uns und unseren Gesch&auml;ftspartnern bereitgestellt werden, Einladungen zur Teilnahme an Aktionen, Lotterien und Preisen, Erfragen Ihrer Meinung zu angebotenen Dienstleistungen und Produkten usw. Wir stellen Ihnen Angebote und Informationen in Ihrem Konto und in der mobilen Anwendung sowie auf jede andere von Ihnen gew&auml;hlte Weise zur Verf&uuml;gung, z.&nbsp;B. per E-Mail oder SMS an die von Ihnen angegebene Telefonnummer.</p>
            <p><strong>Personenbezogene Daten, die zum Zweck der Bereitstellung von Angeboten und Informationen verarbeitet werden:</strong></p>
            <p>Datenkategorien:&nbsp;Ihr Name, E-Mail-Adresse und/oder Telefonnummer.</p>
            <p>Rechtsgrundlage f&uuml;r die Datenverarbeitung:&nbsp;Ihre Einwilligung zum Erhalt von Angeboten und Informationen &uuml;ber aktuelle Neuigkeiten und zur Wahrung unserer berechtigten Interessen.</p>
            <p>Dauer der Datenverarbeitung: Solange Ihre Einwilligung zum Erhalt von Angeboten und Newslettern g&uuml;ltig ist. Wir k&ouml;nnen Ihre Einwilligung und den Nachweis dar&uuml;ber auch l&auml;nger aufbewahren, wenn dies erforderlich ist, um uns gegen Forderungen, Anspr&uuml;che oder Klagen gegen uns zu verteidigen.</p>
            <p>Sie k&ouml;nnen Ihre bevorzugten Kommunikationskan&auml;le in den Datenschutzeinstellungen der Website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> in Ihrem Benutzerkonto oder Ihrer mobilen Anwendung ausw&auml;hlen. Sie k&ouml;nnen diese Einstellungen jederzeit uneingeschr&auml;nkt &auml;ndern.</p>
            <p>Wenn Sie nicht m&ouml;chten, dass wir Ihnen Angebote und/oder Newsletter zusenden, k&ouml;nnen Sie uns Ihre Einwilligung nicht erteilen. Wenn Sie Ihre Einwilligung zum Erhalt von Angeboten und/oder Newslettern erteilt haben, k&ouml;nnen Sie sich jederzeit abmelden oder Ihre bevorzugten Mittel (Kan&auml;le) zum Erhalt von Mitteilungen &auml;ndern. Ihre Weigerung, Angebote und Newsletter zu erhalten, wird Ihre Nutzung der Dienste und die Erf&uuml;llung Ihrer vertraglichen Verpflichtungen nicht beeintr&auml;chtigen.</p>
            <p><strong>1.6 Datenprofilierung f&uuml;r Erstellung individueller Angebote</strong></p>
            <p>Wenn Sie sich bei der Registrierung auf der Website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> oder in der mobilen Anwendung auch daf&uuml;r entschieden haben, individuelle (personalisierte) Angebote entsprechend Ihrer Bestellhistorie zu erhalten und der Profilierung zu diesem Zweck zugestimmt haben, um Sie so gut wie m&ouml;glich kennenzulernen, werden wir nicht nur die von Ihnen zu diesem Zweck bereitgestellten Daten verarbeiten, sondern auch zus&auml;tzlich Ihre Kaufdatenhistorie analysieren und nutzen.</p>
            <p><strong>Personenbezogene Daten, die zum Zwecke der Erstellung individueller Angebote verarbeitet werden:</strong></p>
            <p>Datenkategorien:&nbsp;Ihr Name, Adresse, E-Mail-Adresse und Telefonnummer, Kaufdaten, Historie der individuellen Angebote und Informationen &uuml;ber deren Nutzung.</p>
            <p>Rechtsgrundlage f&uuml;r die Datenverarbeitung:&nbsp;Ihre Einwilligung zur Profilierung Ihrer Daten, um Ihnen personalisierte Angebote unterbreiten zu k&ouml;nnen und zur Wahrung unserer berechtigten Interessen.</p>
            <p>Dauer der Datenverarbeitung: Solange Ihre Einwilligung zur Profilierung Ihrer Daten zum Zwecke der Erstellung individueller (personalisierter) Angebote gilt. Wir k&ouml;nnen Ihre Einwilligung und den Nachweis dar&uuml;ber auch l&auml;nger aufbewahren, wenn dies erforderlich ist, um uns gegen Forderungen, Anspr&uuml;che oder Klagen gegen uns zu verteidigen.</p>
            <p>Wenn Sie der Profilierung Ihrer Daten zustimmen, um Ihnen individuelle (personalisierte) Angebote zu unterbreiten, k&ouml;nnen Sie personalisierte, auf Ihre Bed&uuml;rfnisse zugeschnittene Angebote und andere speziell f&uuml;r Sie entwickelte Vorteile erhalten. Wir verwenden automatisierte Datenanalyse und Entscheidungsfindung, einschlie&szlig;lich Profilierung, um den oben genannten Zweck zu erreichen. Wir gruppieren und analysieren Ihre und/oder Ihre Kaufdaten und stellen Ihnen aktuelle, interessante und n&uuml;tzliche Angebote und andere Informationen &uuml;ber individuelle Angebote zur Verf&uuml;gung, die auf Ihren Einkaufsgewohnheiten und/oder anderen Merkmalen basieren (z.&nbsp;B. Adresse, bestimmte Eink&auml;ufe usw.).</p>
            <p>Auf der Grundlage Ihrer Kaufhistorie und Ihrer Einkaufsgewohnheiten k&ouml;nnen wir Ihnen zum Beispiel Rabatte auf Ihre bevorzugten Produktkategorien gew&auml;hren, Sie &uuml;ber Neuigkeiten in einzelnen Sortimenten informieren usw.</p>
            <p>Wenn Sie nicht m&ouml;chten, dass Ihre Daten profiliert werden, wenn wir Ihnen individuelle Angebote unterbreiten, <b>k&ouml;nnen Sie uns Ihre Einwilligung verweigern oder, wenn Sie Ihre Einwilligung zum Erhalt personalisierter Angebote gegeben haben, k&ouml;nnen Sie diese jederzeit widerrufen</b>. In diesem Fall hindert Sie Ihre Weigerung, individuelle Angebote zu erhalten, nicht daran, die Dienste weiterhin zu nutzen und Ihre Verpflichtungen aus dem Vertrag zu erf&uuml;llen, aber Sie werden keine individuellen Angebote und Informationen mehr erhalten k&ouml;nnen, die auf Sie pers&ouml;nlich zugeschnitten sind.</p>
            <p><strong>1.7 Statistiken, Markt- und Verhaltensforschung unserer Kunden</strong></p>
            <p>Wir arbeiten st&auml;ndig daran, dass unsere Produktpalette, Werbeaktionen und Rabatte den Bed&uuml;rfnissen unserer Kunden, darunter auch Ihren Bed&uuml;rfnissen am besten entsprechen.</p>
            <p><strong>Daten, die zu Zwecken der Statistik, Markt- und Verhaltensforschung verarbeitet werden:</strong></p>
            <p>Datenkategorien: Adresse, Kaufdaten.</p>
            <p>Rechtsgrundlage f&uuml;r die Datenverarbeitung:&nbsp;Unser berechtigtes Interesse besteht darin, die Kaufdaten in einer bestimmten Region nach Kundenadresse zu analysieren und die f&uuml;r unsere Gesch&auml;ftsaktivit&auml;ten notwendigen Berichte zu erstellen, um unsere Gesch&auml;ftsaktivit&auml;ten zu bewerten und Vorteile f&uuml;r Sie als Kunden und f&uuml;r die Gesch&auml;ftsaktivit&auml;ten der Gesellschaft zu schaffen.</p>
            <p>Zeitraum der Datenverarbeitung:&nbsp;5&nbsp;Kalenderjahre ab dem Datum der Bestellung.</p>
            <p>Wir verwenden <b>automatisierte Datenanalysen</b>, um statistische Daten, Markt- und Kundenverhalten zu analysieren und Berichte f&uuml;r unsere Gesellschaft zu erstellen. W&auml;hrend der Analyse verwenden wir <b>nicht personenbezogene Daten</b> und verarbeiten nicht Ihren Namen, Ihre Kontaktdaten und andere personenbezogene Daten. Die Datenanalyse zum Zweck der statistischen, Markt- und Verhaltensforschung unserer Kunden (Adress- und Kaufdaten) hilft uns, wichtige gesch&auml;ftliche Entscheidungen im Hinblick auf die Bed&uuml;rfnisse unserer Kunden zu treffen, ein angemessenes Sortiment zu erstellen, Preise festzulegen usw.</p>
            <p>Diese Aktivit&auml;ten der Datenanalyse ber&uuml;hren weder Ihre legitimen oder &auml;hnlichen Interessen noch die k&uuml;nftige Erbringung der Dienstleistungen oder die Erf&uuml;llung Ihre Verpflichtungen aus dem Vertrag.</p>
            <p><strong>1.8 Bearbeitung von Kundenanfragen, Beschwerden, W&uuml;nschen und Feedback</strong></p>
            <p>Wir verwenden Ihre personenbezogenen Daten, um auf Ihre Anfragen, Beschwerden und W&uuml;nsche zu reagieren und Ihr Feedback zu verwalten (nachfolgend <b>Anfrage</b> genannt).</p>
            <p><strong>Daten, die zum Zweck der Bearbeitung von Kundenanfragen, Beschwerden, W&uuml;nschen und Feedback verarbeitet werden:</strong></p>
            <p>Datenkategorien:&nbsp;</p>
            <ul>
                <li>Identifikations- und Kontaktdaten, die Sie uns zur Verf&uuml;gung gestellt haben: Name, Telefonnummer, E-Mail-Adresse, Adresse usw.;</li>
                <li>Inhalt Ihrer Anfrage: der Fall, auf den Sie sich beziehen, Umst&auml;nde, Datum, Ort, Ihre Anfrage, Ihr Anspruch oder Ihr Feedback, das Produkt und andere Informationen, die in der Anfrage enthalten sind;</li>
                <li>Andere Dokumente und (oder) Daten, die mit der Anfrage eingereicht werden: z.&nbsp;B. Details der Rechnung/des Kassenbons/des Lieferscheins, Fotos der Ware;</li>
                <li>Eine Aufzeichnung des Telefongespr&auml;chs, wenn Sie sich an unsere Kundendienst-Hotline wenden.</li>
            </ul>
            <p>Rechtsgrundlage f&uuml;r die Datenverarbeitung:&nbsp;Die Erf&uuml;llung unserer gesetzlichen Verpflichtungen, Ihre Anfragen zu pr&uuml;fen und zu beantworten, sowie unsere berechtigten Interessen, Ihr Feedback als eines Kunden zu bewerten und Telefongespr&auml;che aufzuzeichnen, um unsere Leistung und die Qualit&auml;t unseres Services f&uuml;r Sie zu verbessern, und zum Schutz unserer berechtigten Interessen.</p>
            <p>Zeitraum der Datenverarbeitung:&nbsp;Bis zu 12&nbsp;Monate. Wir k&ouml;nnen Ihre Warenforderungen auch l&auml;nger aufbewahren, w&auml;hrend die Haftungsanspr&uuml;che auslaufen, die sich aus vertraglichen Verpflichtungen im Zusammenhang mit dem Warenkauf ergeben k&ouml;nnen, sowie wenn es f&uuml;r uns notwendig ist, unsere berechtigten Interessen gegen Forderungen, Anspr&uuml;che oder Klagen gegen uns zu verteidigen.</p>
            <p>Wir verwenden Ihre Daten nur zu dem Zweck, Ihre Anfrage ordnungsgem&auml;&szlig; und objektiv zu pr&uuml;fen, Ihnen die gew&uuml;nschten Informationen zukommen zu lassen, Ihre Fragen zu beantworten und Ihre Anfragen oder Anspr&uuml;che (Beschwerden) zu bearbeiten. Wir k&ouml;nnen das von Ihnen &uuml;bermittelte Feedback analysieren, um unsere Abl&auml;ufe und die Qualit&auml;t der von uns f&uuml;r Sie erbrachten Dienstleistungen zu verbessern, wobei wir Ihr Feedback und Ihre Vorschl&auml;ge ber&uuml;cksichtigen.</p>
            <p><strong>1.9 Wie lange verarbeiten und speichern wir Ihre Daten?</strong></p>
            <p>Wir verarbeiten und speichern Ihre Anfrage und die damit verbundenen Daten, solange wir die Anfrage bearbeiten, Ihnen eine Antwort geben und die getroffenen Entscheidungen umsetzen, und bis zu 6&nbsp;(sechs) Monate nach der Erledigung der Anfrage, jedoch nicht k&uuml;rzer als:</p>
            <ul>
                <li>Anfragen in elektronischer Form per E-Mail&nbsp;&ndash; nicht l&auml;nger als 6&nbsp;Monate;</li>
                <li>Beschwerden&nbsp;&ndash; 12&nbsp;Monate;</li>
                <li>Callcenter-Aufzeichnungen&nbsp;&ndash; 14&nbsp;Tage;</li>
                <li>Feedback-Bewertungen (Speicherung von Sternen)&nbsp;&ndash; nicht l&auml;nger als 12&nbsp;Monate.</li>
            </ul>
            <p>Wenn im Zusammenhang mit einer Anfrage ein Rechtsstreit eingeleitet wird oder zu erwarten ist, k&ouml;nnen wir Ihre Daten l&auml;nger speichern, bis die gesetzlichen Verj&auml;hrungsfristen f&uuml;r die Einreichung einer Beschwerde oder eines Anspruchs abgelaufen sind und (oder) die endg&uuml;ltige Entscheidung in Kraft tritt.</p>
            <p>Nach Ablauf des in dieser Richtlinie festgelegten Zeitraums f&uuml;r die Verarbeitung und Speicherung Ihrer Daten werden wir die Dokumente, die Ihre personenbezogenen Daten enthalten, selbst&auml;ndig vernichten oder Ihre personenbezogenen Daten in elektronischer Form zuverl&auml;ssig und unwiederbringlich l&ouml;schen oder anonymisieren, sobald dies in angemessener Weise und innerhalb eines angemessenen Zeitraums f&uuml;r solche Ma&szlig;nahmen m&ouml;glich ist.</p>
            <ol start={2}>
                <li><strong> Aus welchen Quellen erhalten und sammeln wir Ihre personenbezogenen Daten?</strong></li>
            </ol>
            <p>Wir erheben und verarbeiten Ihre personenbezogenen Daten gem&auml;&szlig; den folgenden Rechtsgrundlagen:</p>
            <ul>
                <li>Ihre Einwilligung;</li>
                <li>unsere berechtigten Interessen;</li>
                <li>Erf&uuml;llung rechtlicher Verpflichtungen, die f&uuml;r die Gesellschaft entstehen und gelten.</li>
            </ul>
            <p>Eine oder mehrere der oben genannten Rechtsgrundlagen k&ouml;nnen auf die Verarbeitung Ihrer Daten zutreffen.</p>
            <p>Wir erhalten fast alle Ihre personenbezogenen Daten nur von Ihnen. Wenn Sie sich auf der Website www.quicktouchmenu.eu oder in der mobilen Anwendung registrieren, &uuml;bermitteln Sie Daten direkt an uns und Kaufdaten&nbsp;&ndash; unter Verwendung der Dienste.</p>
            <p>Wir erhalten Ihre Daten auch direkt von Ihnen, wenn Sie eine Anfrage auf die von Ihnen gew&auml;hlte Weise einreichen: elektronisch, indem Sie uns eine E-Mail schreiben und an unsere E-Mail-Adresse senden, indem Sie uns eine schriftliche Anfrage in Papierform &uuml;bermitteln, oder m&uuml;ndlich, indem Sie unser Kundenservice-Callcenter anrufen usw.</p>
            <p>Wenn wir zus&auml;tzliche Informationen einholen oder relevante Umst&auml;nde untersuchen m&uuml;ssen, um Ihre Anfrage qualitativ und objektiv zu bearbeiten, k&ouml;nnen wir Ihre Anfragedaten mit unseren bestehenden Daten und (oder) Daten, die w&auml;hrend der Bearbeitung Ihrer Anfrage gesammelt wurden, verkn&uuml;pfen, wir k&ouml;nnen z.&nbsp;B. Daten zu Kauftransaktionen, Ihre Kontonutzungshistorie &uuml;berpr&uuml;fen, unsere Mitarbeiter befragen usw.</p>
            <ol start={3}>
                <li><strong> Wie werden Daten gespeichert und vernichtet?</strong></li>
            </ol>
            <p>Nach Ablauf des in dieser Richtlinie festgelegten Zeitraums f&uuml;r die Verarbeitung und Speicherung Ihrer Daten (au&szlig;er in den unten genannten F&auml;llen) werden wir Ihre Daten sicher vernichten und in den in der Richtlinie festgelegten F&auml;llen so schnell wie m&ouml;glich innerhalb eines angemessenen und vertretbaren Zeitraums, der f&uuml;r diese Ma&szlig;nahme erforderlich ist, zuverl&auml;ssig und unwiederbringlich anonymisieren.</p>
            <p>Ihre personenbezogenen Daten d&uuml;rfen nur dann l&auml;nger aufbewahrt werden als in dieser Richtlinie angegeben, wenn einer der folgenden Umst&auml;nde zutrifft:</p>
            <ul>
                <li>es ist notwendig, damit wir uns gegen Forderungen, Anspr&uuml;che oder Klagen verteidigen und unsere Rechte aus&uuml;ben k&ouml;nnen;</li>
                <li>die Aufbewahrungsfrist der personenbezogenen Daten ist in den Rechtsvorschriften der Republik Lettland und der Europ&auml;ischen Union festgelegt oder ergibt sich aus diesen;</li>
                <li>es besteht ein begr&uuml;ndeter Verdacht auf rechtswidriges Verhalten und in diesem Fall wird eine Untersuchung durchgef&uuml;hrt;</li>
                <li>Ihre Daten sind f&uuml;r die ordnungsgem&auml;&szlig;e Bearbeitung eines Streitfalls oder einer Beschwerde erforderlich;</li>
                <li>es ist notwendig, eine Sicherungskopie und andere &auml;hnliche Zwecke bereitzustellen;</li>
                <li>bei Vorliegen anderer Rechtsgrundlagen.</li>
            </ul>
            <p>In einem solchen Fall werden die Informationen, die personenbezogene Daten enthalten, so lange gespeichert, bis das betreffende Verfahren (Strafverfahren, Ordnungswidrigkeitenverfahren, Verwaltungsverfahren) an die Strafverfolgungsbeh&ouml;rde &uuml;bergeben wurde oder deren endg&uuml;ltige Entscheidung ergangen ist.</p>
            <ol start={4}>
                <li><strong> Wann und an welche Empf&auml;nger geben wir Ihre pers&ouml;nlichen Daten weiter?</strong></li>
            </ol>
            <p>Wir k&ouml;nnen Ihre Daten zur Verarbeitung an Empf&auml;nger personenbezogener Daten weitergeben, d.&nbsp;h. an Personen, die uns bei der Erbringung und Verwaltung der Dienste unterst&uuml;tzen und uns Dienstleistungen im Zusammenhang mit der Verwaltung von Kundenanfragen erbringen. Dazu k&ouml;nnen Anbieter von IT-Datenbanksoftware, Anbieter von Datenbankverwaltungsdiensten, Anbieter von Rechenzentren, Wartungsdiensten und Cloud-Diensten, Anbieter von Direktmarketingdiensten, Anbieter von Marktforschungs- oder Gesch&auml;ftsanalysediensten usw. geh&ouml;ren. In jedem Fall stellen wir Datenverarbeitern nur so viele Daten zur Verf&uuml;gung, wie f&uuml;r die Durchf&uuml;hrung einer bestimmten Aufgabe oder die Erbringung einer bestimmten Dienstleistung erforderlich sind. Die von uns beauftragten Datenverarbeiter k&ouml;nnen Ihre personenbezogenen Daten nur auf unsere Anweisung hin verarbeiten und d&uuml;rfen sie nicht f&uuml;r andere Zwecke verwenden oder ohne unsere Einwilligung an andere Parteien weitergeben. Au&szlig;erdem m&uuml;ssen sie Ihre Daten in &Uuml;bereinstimmung mit geltendem Recht und gem&auml;&szlig; einer schriftlichen Vereinbarung zwischen uns sch&uuml;tzen.</p>
            <p>Wenn wir Ihnen das Recht einr&auml;umen, die Dienste unserer Partner (z.&nbsp;B. die Vorteile ihrer Treueprogramme) im Rahmen der Bereitstellung der Dienste zu nutzen, werden einige Ihrer Daten auch unseren Partnern zur Verf&uuml;gung gestellt, jedoch nur f&uuml;r die Zwecke einer solchen allgemeinen Bereitstellung von Diensten oder anderer Bedingungen der Zusammenarbeit.</p>
            <p>Wenn der in der Anfrage beschriebene Fall als Versicherungsfall eingestuft wird, werden wir Ihre Anfrage und Ihre Daten an die Versicherungsgesellschaft(en) weiterleiten, bei der/denen wir unsere Haftpflicht oder unser Eigentum versichert haben oder die uns einen anderen Versicherungsschutz bietet, der f&uuml;r den in Ihrer Anfrage beschriebenen Fall relevant ist. Versicherungsgesellschaften handeln als unabh&auml;ngige/getrennte Datenverantwortliche und verarbeiten Ihre Daten in &Uuml;bereinstimmung mit den Bedingungen der Versicherungsgesellschaft.</p>
            <p>Daten k&ouml;nnen auch an zust&auml;ndige/&uuml;berwachende staatliche, lokale oder Strafverfolgungsbeh&ouml;rden wie Polizei, Staatsanwaltschaft, Gericht oder Kontroll-/Aufsichtsbeh&ouml;rden weitergegeben werden, jedoch nur auf deren begr&uuml;ndete Anfrage oder nur, wenn dies nach geltendem Recht oder in den gesetzlich festgelegten F&auml;llen und Verfahren erforderlich ist, um die Rechte staatlicher, lokaler oder Strafverfolgungsbeh&ouml;rden und/oder uns, die Sicherheit unserer Kunden, Mitarbeiter und IT-Ressourcen zu sch&uuml;tzen, Rechtsanspr&uuml;che geltend zu machen, einzureichen und zu verteidigen usw.</p>
            <p>Wenn wir einen Website-Analysedienst (z.&nbsp;B. Google Analytics) verwenden, der dazu dient, zu messen, wie Sie auf der Website www.quicktouchmenu.eu oder in der mobilen Anwendung surfen, k&ouml;nnen wir Ihre anonymisierten Daten an Personen weitergeben, die auf diese Informationen angewiesen sind, um auszuwerten, wie die Website oder die mobile Anwendung durchsucht wird, um Berichte f&uuml;r Website-Betreiber &uuml;ber die Website oder die mobile Anwendung zu erstellen und um andere Dienstleistungen im Zusammenhang mit der Nutzung der Website, des Internets und der mobilen Anwendung zu erbringen. Weitere Informationen dar&uuml;ber finden Sie in der Cookie-Richtlinie Ihres Browsers.</p>
            <ol start={5}>
                <li><strong> In welchem Gebiet verarbeiten wir Ihre personenbezogenen Daten?</strong></li>
            </ol>
            <p>Wir verarbeiten Ihre personenbezogenen Daten nur innerhalb der Europ&auml;ischen Union. Wir haben derzeit nicht die Absicht, Ihre personenbezogenen Daten in Drittl&auml;nder zu &uuml;bermitteln und tun dies auch nicht. F&uuml;r den Fall, dass wir <a href="http://www.facebook.com/">www.facebook.com</a> oder Google Analytics-Dienste f&uuml;r die Bereitstellung von Diensten oder Kommunikation verwenden, gelten die jeweiligen Dienstanbieter in Bezug auf die &Uuml;bermittlung von Daten in Drittl&auml;nder als Dritte und wir bitten Sie, die Datenschutzrichtlinien dieser Dienstanbieter zu pr&uuml;fen.</p>
            <ol start={6}>
                <li><strong> Welche Rechte haben Sie und wie k&ouml;nnen Sie diese wahrnehmen?</strong></li>
            </ol>
            <p>Die Datenschutzgesetze geben Ihnen viele Rechte, die Sie frei aus&uuml;ben k&ouml;nnen, und wir erm&ouml;glichen es Ihnen, diese auszu&uuml;ben. Wir informieren Sie in dieser Richtlinie &uuml;ber Ihre spezifischen Rechte und wie Sie diese aus&uuml;ben k&ouml;nnen. Bitte lesen Sie sie sorgf&auml;ltig:</p>
            <p><strong>6.1 Recht auf Zugang zu Ihren personenbezogenen Daten, die wir verwalten</strong></p>
            <p>Sie haben das Recht, von uns eine Best&auml;tigung dar&uuml;ber zu erhalten, ob wir Ihre personenbezogenen Daten verarbeiten, sowie das Recht auf Einsicht in die personenbezogenen Daten, die wir &uuml;ber Sie verarbeiten, und auf Informationen &uuml;ber die Zwecke der Verarbeitung, die Kategorien der verarbeiteten Daten, die Kategorien der Datenempf&auml;nger, den Zeitraum der Verarbeitung, die Quellen der Daten, die automatisierte Entscheidungsfindung, einschlie&szlig;lich Profilierung, sowie deren Bedeutung und Folgen f&uuml;r Sie.</p>
            <p>Wir stellen die meisten dieser Informationen in dieser Richtlinie zur Verf&uuml;gung und hoffen, dass Sie sie n&uuml;tzlich finden.</p>
            <p>Wenn Sie ein registrierter Benutzer von www.quicktouchmenu.eu sind, k&ouml;nnen Sie jederzeit auf Ihre von uns verarbeiteten personenbezogenen Daten in Ihrem Benutzerkonto zugreifen (z.&nbsp;B. die von Ihnen angegebenen personenbezogenen Daten &uuml;berpr&uuml;fen, Informationen und die von Ihnen erteilten Einwilligungen aktualisieren usw.), Rechnungen f&uuml;r die letzten 12&nbsp;Monate erhalten. Wenn Sie ein Benutzer der mobilen Anwendung sind, k&ouml;nnen Sie sich mit allen genannten Informationen in der mobilen Anwendung vertraut machen.</p>
            <p>Wenn Ihnen die Informationen in dieser Richtlinie, in Ihrem Benutzerkonto auf der Website www.quicktouchmenu.eu oder in der mobilen Anwendung nicht ausreichen, oder wenn Sie eine Kauftransaktionshistorie f&uuml;r einen l&auml;ngeren Zeitraum als 12&nbsp;Monate erhalten m&ouml;chten, k&ouml;nnen Sie uns jederzeit auf die in Abschnitt&nbsp;8 dieser Richtlinie beschriebene Weise kontaktieren.</p>
            <p><strong>6.2 Recht auf Berichtigung personenbezogener Daten</strong></p>
            <p>Wenn sich die Angaben, die Sie bei der Registrierung gemacht haben, ge&auml;ndert haben oder wenn Sie feststellen, dass die Informationen, die wir &uuml;ber Sie verarbeiten, ungenau oder falsch sind, haben Sie das Recht, die &Auml;nderung, Aktualisierung oder Berichtigung dieser Informationen zu verlangen, indem Sie uns auf die in Abschnitt&nbsp;8 dieser Richtlinie beschriebene Weise kontaktieren oder</p>
            <p>Sie k&ouml;nnen Ihre Daten im Konto auf der Website www.quicktouchmenu.eu oder in der mobilen Anwendung selbstst&auml;ndig korrigieren (aktualisieren).</p>
            <p><strong>6.3 Recht auf Widerruf der Einwilligung</strong></p>
            <p>Wenn wir Ihre Daten in &Uuml;bereinstimmung mit Ihrer Einwilligung verarbeiten, haben Sie das Recht, Ihre Einwilligung jederzeit zu widerrufen, was ein Grund f&uuml;r die Beendigung der Verarbeitung sein wird. Der Widerruf der Einwilligung hat keinen Einfluss auf die Rechtm&auml;&szlig;igkeit der Verarbeitung, die auf der Einwilligung vor dem Widerruf beruht.</p>
            <p>So k&ouml;nnen Sie beispielsweise Ihre Einwilligung zum Erhalt von Angeboten und Newslettern jederzeit widerrufen, ebenso wie Ihre Einwilligung zur Erstellung von Datenprofilierung, wenn wir Ihnen personalisierte Angebote unterbreiten. Der Widerruf dieser Einwilligungen schr&auml;nkt Ihre weitere Nutzung der Dienste und die Erf&uuml;llung Ihrer Verpflichtungen aus dem Vertrag nicht ein, bedeutet aber, dass wir Ihnen keine aktuellen Angebote und Newsletter mehr zur Verf&uuml;gung stellen k&ouml;nnen.</p>
            <p>Sie k&ouml;nnen Ihre Einwilligungen korrigieren (zur&uuml;ckziehen oder neu erteilen), indem Sie Ihre aktualisierten Registrierungsdaten f&uuml;r die von Ihnen erteilten Einwilligungen einreichen, indem Sie die Bedingungen Ihrer Einwilligungen in Ihrem registrierten Benutzerkonto oder Ihrer mobilen Anwendung &auml;ndern oder indem Sie uns auf die in Abschnitt&nbsp;8 dieser Richtlinie beschriebene Weise kontaktieren.</p>
            <p>Wenn Sie Ihre Einwilligung widerrufen, werden wir die Verarbeitung von Daten auf der Grundlage Ihrer Einwilligung einstellen und in den in der Richtlinie genannten F&auml;llen die Daten zuverl&auml;ssig und unwiderruflich l&ouml;schen oder anonymisieren.</p>
            <p>In jedem Fall k&ouml;nnen wir Ihre Einwilligung und den Nachweis Ihrer Einwilligung f&uuml;r einen l&auml;ngeren Zeitraum aufbewahren, wenn dies erforderlich ist, um unsere berechtigten Interessen gegen Forderungen, Anspr&uuml;che oder Klagen gegen uns zu verteidigen.</p>
            <p><strong>6.4 Recht auf Einreichung einer Beschwerde</strong></p>
            <p>Wenn Sie der Meinung sind, dass wir bei der Verarbeitung Ihrer Daten gegen die Anforderungen der datenschutzrechtlichen Vorschriften versto&szlig;en, wenden Sie sich bitte zun&auml;chst direkt an uns. Wir sind zuversichtlich, dass wir alle Ihre Zweifel ausr&auml;umen, Ihre W&uuml;nsche erf&uuml;llen und unsere Fehler beseitigen k&ouml;nnen, falls es welche gibt.</p>
            <p>Wenn Sie mit der von uns vorgeschlagenen L&ouml;sung des Problems nicht zufrieden sind oder wenn wir Ihrer Meinung nach nicht die erforderlichen Ma&szlig;nahmen gem&auml;&szlig; Ihrer Anfrage ergreifen, haben Sie das Recht, eine Beschwerde bei der Aufsichtsbeh&ouml;rde einzureichen, die in der Republik Lettland die Staatliche Datenschutzbeh&ouml;rde ist.</p>
            <p><strong>6.5 Recht auf Widerspruch gegen die Datenverarbeitung, wenn die Verarbeitung auf berechtigten Interessen beruht</strong></p>
            <p>Sie haben das Recht, der Verarbeitung personenbezogener Daten zu widersprechen, wenn die Verarbeitung auf unseren berechtigten Interessen beruht. Unter Ber&uuml;cksichtigung der Zwecke der Bereitstellung der Dienste und der Abw&auml;gung der berechtigten Interessen beider Parteien (Sie als betroffene Person und wir als f&uuml;r die Datenverarbeitung Verantwortlicher) kann Ihr Widerspruch jedoch dazu f&uuml;hren, dass wir Ihnen die weitere Nutzung der Dienste nicht mehr erm&ouml;glichen k&ouml;nnen und der Vertrag gek&uuml;ndigt und beendet wird, wenn wir die Verarbeitung Ihrer Daten aufgrund unserer berechtigten Interessen einstellen.</p>
            <p>Wenn Sie die in diesem Abschnitt dargelegten Rechte aus&uuml;ben m&ouml;chten, richten Sie Ihre Anfrage bitte schriftlich an unseren Datenschutzbeauftragten, dessen Kontaktdaten in Abschnitt&nbsp;8 dieser Richtlinie aufgef&uuml;hrt sind.</p>
            <p><strong>6.6 Recht auf L&ouml;schung von Daten (Recht auf Vergessenwerden)</strong></p>
            <p>Unter bestimmten, in den Rechtsvorschriften zur Datenverarbeitung festgelegten Umst&auml;nden (z.&nbsp;B. wenn personenbezogene Daten unrechtm&auml;&szlig;ig verarbeitet werden, die Rechtsgrundlage f&uuml;r die Verarbeitung nicht mehr besteht, die personenbezogenen Daten f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder anderweitig verarbeitet wurden, nicht mehr erforderlich sind usw.) haben Sie das Recht, die L&ouml;schung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie diese Rechte aus&uuml;ben m&ouml;chten, bitte richten Sie Ihre Anfrage schriftlich an unseren Datenschutzbeauftragten, dessen Kontaktdaten in Abschnitt&nbsp;8 dieser Richtlinie aufgef&uuml;hrt sind.</p>
            <p>Bitte beachten Sie, dass Ihre Daten als eines registrierten Nutzers vom Konto auf der Website www.quicktouchmenu.eu ohne gesonderte Anfrage von Ihnen gel&ouml;scht werden und andere Daten gel&ouml;scht oder sicher anonymisiert werden, wenn Sie die Dienste auf der Website www.quicktouchmenu.eu nicht mehr nutzen und 2&nbsp;(zwei) Jahre seit Ihrer letzten Aktivit&auml;t als eines registrierten Nutzers auf der Website www.quicktouchmenu.eu vergangen sind.</p>
            <p><strong>6.7 Recht auf Einschr&auml;nkung der Datenverarbeitung</strong></p>
            <p>Unter bestimmten Umst&auml;nden, die in den Rechtsvorschriften zur Datenverarbeitung festgelegt sind (wenn personenbezogene Daten unrechtm&auml;&szlig;ig verarbeitet werden, Sie die Richtigkeit der Daten bestreiten, Sie der Verarbeitung zum Zwecke unserer berechtigten Interessen widersprechen usw.), haben Sie auch das Recht, die Verarbeitung Ihrer Daten einzuschr&auml;nken. Wir weisen jedoch darauf hin, dass wir aufgrund der Einschr&auml;nkung der Datenverarbeitung und f&uuml;r den Zeitraum dieser Einschr&auml;nkung m&ouml;glicherweise nicht in der Lage sind, Ihnen die Dienste zur Verf&uuml;gung zu stellen und unsere Verpflichtungen aus dem Vertrag zu erf&uuml;llen.</p>
            <p>Wenn Sie die in diesem Abschnitt dargelegten Rechte aus&uuml;ben m&ouml;chten, richten Sie Ihre Anfrage bitte schriftlich an unseren Datenschutzbeauftragten, dessen Kontaktdaten in Abschnitt&nbsp;8 dieser Richtlinie aufgef&uuml;hrt sind.</p>
            <p><strong>6.8 Recht auf Daten&uuml;bertragbarkeit</strong></p>
            <p>Sie haben das Recht, die Daten, die wir auf der Grundlage Ihrer Einwilligung verarbeiten und deren Verarbeitung automatisiert erfolgt, an einen anderen f&uuml;r die Datenverarbeitung Verantwortlichen zu &uuml;bertragen. Wir stellen Ihnen die Daten, die Sie &uuml;bermitteln m&ouml;chten, in einem einfachen, computerlesbaren Format zur Verf&uuml;gung, das in unseren Systemen verwendet werden kann. Wenn Sie es w&uuml;nschen und es technisch m&ouml;glich ist, &uuml;bermitteln wir die Daten jedoch direkt an den von Ihnen angegebenen anderen Datenverantwortlichen.</p>
            <p>Wenn Sie Ihr Recht auf Daten&uuml;bertragbarkeit aus&uuml;ben m&ouml;chten, richten Sie Ihre Anfrage bitte schriftlich an unseren Datenschutzbeauftragten, dessen Kontaktdaten in Absatz&nbsp;8 dieser Richtlinie aufgef&uuml;hrt sind.</p>
            <ol start={7}>
                <li><strong> Wie behandeln und bearbeiten wir Ihre Anfragen?</strong></li>
            </ol>
            <p>Um die Daten unserer Kunden vor unrechtm&auml;&szlig;iger Offenlegung zu sch&uuml;tzen, m&uuml;ssen wir Ihre Identit&auml;t &uuml;berpr&uuml;fen, wenn wir von Ihnen eine Anfrage zur Bereitstellung Ihrer Daten oder zur Aus&uuml;bung Ihrer sonstigen Rechte erhalten. Zu diesem Zweck k&ouml;nnen wir Sie bitten, uns Ihre aktuellen Daten (wie Name, Adresse, E-Mail-Adresse oder Telefonnummer) mitzuteilen, die in Ihrem Benutzerkonto auf der Website www.quicktouchmenu.eu oder in Ihrem Benutzerprofil in der mobilen Anwendung registriert sind, und wir werden dann &uuml;berpr&uuml;fen, ob die von Ihnen angegebenen Daten mit den entsprechenden Daten des registrierten Benutzers &uuml;bereinstimmen. Im Rahmen dieser &Uuml;berpr&uuml;fung k&ouml;nnen wir auch eine Kontrollmitteilung an die im Registrierungsformular angegebenen Kontaktdaten senden (per SMS oder E-Mail) und um eine Autorisierung bitten. Wenn das Verifizierungsverfahren nicht erfolgreich ist (z.&nbsp;B. wenn die von Ihnen angegebenen Daten nicht mit den Daten &uuml;bereinstimmen, die auf der Website www.quicktouchmenu.eu oder in dem auf der mobilen Anwendung registrierten Benutzerprofil angegeben sind, oder wenn Sie sich nach dem Erhalt einer SMS- oder E-Mail-Benachrichtigung nicht anmelden), m&uuml;ssen wir feststellen, dass Sie nicht die betroffene Person der angeforderten Daten sind, und wir m&uuml;ssen die von Ihnen gestellte Anfrage ablehnen.</p>
            <p>Nach Erhalt Ihrer Anfrage auf Aus&uuml;bung eines Ihrer Rechte und nach erfolgreichem Abschluss des oben genannten &Uuml;berpr&uuml;fungsverfahrens verpflichten wir uns, Ihnen unverz&uuml;glich, in jedem Fall aber innerhalb eines Monats nach Erhalt Ihrer Anfrage und nach Abschluss des &Uuml;berpr&uuml;fungsverfahrens die gew&uuml;nschten Informationen zur Verf&uuml;gung zu stellen. Angesichts der Komplexit&auml;t und der Anzahl der Anfragen haben wir das Recht, die einmonatige Frist um weitere zwei Monate zu verl&auml;ngern, wobei wir Sie vor Ablauf des ersten Monats informieren und die Gr&uuml;nde f&uuml;r die Verl&auml;ngerung angeben.</p>
            <p>Wenn Ihre Anfrage auf elektronischem Wege erfolgt, werden wir auch auf elektronischem Wege antworten, es sei denn, dies ist nicht m&ouml;glich (z.&nbsp;B. wegen der gro&szlig;en Menge an Informationen) oder wenn Sie uns bitten, auf andere Weise zu antworten.</p>
            <p>Wir werden Ihre Anfrage mit einer begr&uuml;ndeten Antwort ablehnen, wenn die in den Rechtsvorschriften genannten Umst&auml;nde festgestellt werden, in F&auml;llen, in denen wir Ihnen die Informationen nicht zur Verf&uuml;gung stellen k&ouml;nnen und wir Sie schriftlich dar&uuml;ber informieren.</p>
            <ol start={8}>
                <li><strong> Wie k&ouml;nnen Sie uns kontaktieren?</strong></li>
            </ol>
            <ul>
                <li>per E-Mail an die folgende Adresse: rolands@elaw.ee</li>
                <li>indem Sie unser Callcenter unter +37126488822 anrufen</li>
            </ul>
            <p>Kontaktinformationen des Datenschutzbeauftragten:</p>
            <ul>
                <li>E-Mail-Adresse: rolands@elaw.ee,</li>
                <li>Korrespondenzadresse: Laki tn&nbsp;30 Mustamae linnaosa, Tallinn Harju maakind 12915, Estland, adressieren Sie Ihr Schreiben an: den Datenschutzbeauftragten von AuraTrust O&Uuml;</li>
            </ul>
            <ol start={9}>
                <li><strong> Wie sicher sind Ihre Daten?</strong></li>
            </ol>
            <p>Wir respektieren Ihre Privatsph&auml;re, daher hat die Sicherheit Ihrer personenbezogenen Daten f&uuml;r uns Priorit&auml;t. Wir setzen geeignete organisatorische und technische Ma&szlig;nahmen ein, um die kontinuierliche Sicherheit Ihrer personenbezogenen Daten und die &Uuml;bereinstimmung der Datenverarbeitung mit den Anforderungen der Rechtsvorschriften zur Datenverarbeitung und unserer internen Richtlinie zu gew&auml;hrleisten.</p>
            <p>Wir halten uns konsequent an den Grundsatz der Minimierung personenbezogener Daten und erheben keine Daten, die nicht notwendig sind, um die in dieser Richtlinie dargelegten Zwecke zu erreichen.</p>
            <p>Wir verwenden eine Vielzahl von Technologien und Verfahren zum Schutz und zur Sicherheit personenbezogener Daten, um Ihre pers&ouml;nlichen Informationen vor unrechtm&auml;&szlig;igem Zugriff, unrechtm&auml;&szlig;iger Nutzung oder unrechtm&auml;&szlig;iger Offenlegung zu sch&uuml;tzen. Unsere Gesch&auml;ftspartner werden sorgf&auml;ltig ausgew&auml;hlt und wir verlangen von ihnen, dass sie angemessene Mittel zum Schutz der Vertraulichkeit personenbezogener Daten und zur Gew&auml;hrleistung der Sicherheit Ihrer pers&ouml;nlichen Informationen einsetzen. Es kann jedoch nicht garantiert werden, dass die &Uuml;bermittlung Ihrer Daten an uns &uuml;ber das Internet oder den Mobilfunk vollkommen sicher ist; jede &Uuml;bermittlung Ihrer Daten an uns auf den oben genannten Wegen erfolgt auf Ihr eigenes Risiko.</p>
            <ol start={10}>
                <li><strong> Cookies</strong></li>
            </ol>
            <p>Wir verwenden Cookies (das sind kleine Informationsdateien, die an Ihren Computer oder ein anderes Ger&auml;t (z.&nbsp;B. ein Mobiltelefon) gesendet werden, wenn Sie unsere Website besuchen, und in Ihrem Browser gespeichert werden). Ein Cookie wird an Ihren Computer oder ein anderes Ger&auml;t gesendet, um Daten zu speichern, damit wir Sie als Nutzer der Dienste erkennen k&ouml;nnen, wenn Sie unsere Website besuchen. Wir k&ouml;nnen auch Cookies verwenden, um Ihre Kaufhistorie und andere Daten, die w&auml;hrend Ihrer Nutzung der Dienste gesammelt werden, mit Ihrem Surfverhalten im Internet zu verkn&uuml;pfen. Die Informationen, die wir mit Hilfe von Cookies sammeln, erm&ouml;glichen es uns, Ihnen ein angenehmeres Surferlebnis, attraktive Angebote und mehr Informationen &uuml;ber das Verhalten unserer Website-Nutzer zu bieten, Trends zu analysieren und sowohl die Website als auch unseren Service f&uuml;r Sie zu verbessern.</p>
            <p>Unsere Website verwendet auch Cookies von Drittanbietern. Sie werden verwendet, um einen Browserverlauf jedes Besuchers zu erstellen, um Ihnen gezielte Werbung zu zeigen und Ihnen das beste Erlebnis beim Besuch unserer Website zu bieten. Wenn Ihr Browser die Speicherung von Cookies von Drittanbietern zul&auml;sst, hat unser ausgew&auml;hlter Partner die M&ouml;glichkeit, seine eigenen Cookies in Ihrem Browser zu speichern.</p>
            <p>Weitere Informationen &uuml;ber Cookies, ihre Verwendung und die M&ouml;glichkeit, sie zu deaktivieren, finden Sie in der Cookie-Richtlinie Ihres Browsers.</p>
            <ol start={11}>
                <li><strong> G&uuml;ltigkeit und &Auml;nderungen der Richtlinie</strong></li>
            </ol>
            <p>Die Datenschutzerkl&auml;rung der Website von AuraTrust O&Uuml; www.quicktouchmenu.eu kann ge&auml;ndert oder aktualisiert werden. Auf der Website www.quicktouchmenu.eu finden Sie immer die aktuellste Version der Datenschutzerkl&auml;rung.</p>
            <ol start={12}>
                <li><strong> Anwendung der Richtlinie</strong></li>
            </ol>
            <p>Die Richtlinie gilt nicht f&uuml;r andere Dienstleistungen, die von uns oder unseren Konzerngesellschaften erbracht werden.</p>
        </>
    );
};

export default PolicyDe;