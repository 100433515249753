import React from 'react';

const PolicyLv = () => {
    return (
        <>
            <p><strong>Privātuma politika</strong></p>
            <p>Reģistrējoties interneta vietnē <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> vai mobilajā aplikācijā, Jūs uzticat AuraTrust O&Uuml; (reģistrācijas numurs Igaunijas Republikā 16562585, juridiskā adrese reģistrēta Ravi tn 2, 10134, Tallina, Igaunija, kā personas datu apstrādes Pārzinim (turpmāk &ndash; Sabiedrība vai mēs, vai Pārzinis) savus personas datus un sniedzat mums tiesības tos apstrādāt atbilsto&scaron;i &scaron;īs Politikas (turpmāk &ndash; Politika) un mobilās aplikācijas lejupielādes un lieto&scaron;anas ietvaros un saistībā ar reģistrāciju un pievieno&scaron;anos, un pakalpojumu snieg&scaron;anu, saskaņā ar attiecīgajiem lieto&scaron;anas noteikumiem (turpmāk &ndash; Noteikumi). Informējam, ka mēs veicam Jūsu personas datu apstrādi, tikai tad, ja Jūs izvēlaties savus personas datus mums sniegt.</p>
            <p>Īstenojot Eiropas Parlamenta un Padomes Regulu (ES) 2016/679 (2016. gada 27. aprīlis) par fizisko personu aizsardzību attiecībā uz personas datu apstrādi un &scaron;ādu datu brīvu apriti, ar ko tiek atcelta Direktīva 95/46/EK (Vispārīgā datu aizsardzības regula), mēs nodro&scaron;inām Jums īpa&scaron;i caurskatāmu un godīgu Jūsu personas datu apstrādi, kā arī mēs rūpējamies par Jūsu privātumu un personas datu dro&scaron;ību.</p>
            <p>&Scaron;ajā Politikā Jūs atradīsiet visu informāciju par to, kādus Jūsu datus mēs apkopojam un apstrādājam, kam tos izmantojam, cik ilgu laiku uzglabājam u.c. &Scaron;ī informācija ir nozīmīga, tādēļ aicinām Jūs uzmanīgi iepzīties ar &scaron;iem Politikas noteikumiem pirms Jūs sniedzat mums informāciju par Jūsu personas datiem.</p>
            <p>Pievēr&scaron;am Jūsu uzmanību arī tam, ka gan Politika, gan arī Noteikumi var tikt mainīti, papildināti, atjaunoti.</p>
            <p>Ja Jūs neakceptējat Noteikumus, &scaron;o Politiku vai atsevi&scaron;ķus tās nosacījumus, mēs diemžēl nevarēsim sniegt Jums iespēju izmantot visas vai kādu no mūsu sniegtajiem pakalpojumiem (turpmāk &ndash; Pakalpojumi).</p>
            <ol>
                <li><strong> Kas ir personas dati un kādus Jūsu personas datus mēs apstrādājam un kādēļ?</strong></li>
            </ol>
            <p><strong>Reģistrācija</strong></p>
            <p><strong>Personas dati ir jebkura informācija, kas attiecas uz identificētu vai identificējamu fizisku personu (&ldquo;datu subjekts&rdquo;); identificējama fiziska persona ir tāda, kuru var tie&scaron;i vai netie&scaron;i identificēt, jo īpa&scaron;i atsaucoties uz identifikatoru, piemēram, minētās personas vārdu, uzvārdu, identifikācijas numuru, atra&scaron;anās vietas datiem, tie&scaron;saistes identifikatoru vai vienu vai vairākiem minētajai fiziskajai personai raksturīgiem fiziskās, fizioloģiskās, ģenētiskās, garīgās, ekonomiskās, kultūras vai sociālās identitātes faktoriem.</strong></p>
            <p>Reģistrējoties interneta vietnē <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> vai mobilajā aplikācijā, Jums ir jāakceptē interneta vietnes vai mobilās aplikācijas lieto&scaron;anas Noteikumi (jāpiekrīt Līgumam). Jūsu personas datu iesnieg&scaron;ana ir obligāta, jo, tos neiesniedzot, Jūs nevarēsiet noslēgt Līgumu un izmantot mūsu sniegtos Pakalpojumus. Reģistrējoties, Jūs noslēdzat Līgumu un apstiprināt, ka Jūsu norādītie personas dati ir precīzi un pareizi un, ka Jūs neesat jaunāks par 18 gadiem. Mēs neuzņemamies atbildību par neprecīziem, nepilnīgiem vai kļūdainiem Jūsu iesniegtajiem personas datiem. Gadījumā, ja Jūs būsiet norādīju&scaron;i citas personas datus, tad pretenziju gadījumā AuraTrust O&Uuml; saglabā tiesības vērsties pie Jums ar regresa prasību</p>
            <p><strong>Līgumam nepiecie&scaron;amie dati:</strong></p>
            <ul>
                <li>Datu kategorijas - Jūsu vārds, uzvārds, adrese, e-pasta adrese un tālruņa numurs.</li>
                <li>Datu apstrādes juridiskais pamats - Līguma, kura līgumslēdzēja puse esat Jūs, noslēg&scaron;anai un izpildei, kā arī mūsu leģitīmo intere&scaron;u aizsardzībai.</li>
                <li>Datu apstrādes termiņ&scaron; - visu periodu, kamēr ir spēkā Līgums un Jūs izmantojat mūsu Pakalpojumus. Ar Jums noslēgto Līgumu un pierādījumus par to noslēg&scaron;anu mēs varam uzglābāt arī ilgāku laika periodu, kamēr noilgst saistību prasījumi, kas var izrietēt no līgumsaistībām, kā arī, ja tas ir nepiecie&scaron;ams, lai mēs varētu aizstāvēt savas leģitīmās intereses pret mums izteiktajiem prasījumiem, pretenzijām vai prasībām.</li>
            </ul>
            <p>Pēc Jūsu norādītajiem personas datiem mēs Jūs atpazīstam, kad, piemēram, Jūs vēlaties atjaunot vai mainīt savus datus, vēr&scaron;aties pie mums saistībā ar attiecīgās personīgās informācijas iesnieg&scaron;anu, ar personas datu apstrādi saistīto tiesību īsteno&scaron;anu u.c. Atbilsto&scaron;i Jūsu iesniegtajiem personas datiem, reģistrējoties interneta vietnē <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> un /vai mobilajā aplikācijā, pēc Līguma noslēg&scaron;anas tiek izveidots unikāls Jūsu lietotāja profils (konts), pēc kura mēs Jūs identificēsim kā reģistrētu interneta vietnes <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> un/vai mobilās aplikācijas lietotāju, kas sniegs Jums iespēju izmantot reģistrētiem lietotājiem piemērotos Pakalpojumus saskaņā ar Līguma noteikumiem un &scaron;īs Politikas nosacījumiem.</p>
            <p>Jūsu norādītos kontaktinformācijas datus (elektronisko pastu, tālruņa numuru) varam izmantot saziņai ar Jums Līguma saistību izpildē, t.sk., atbildot uz Jūsu pieprasījumiem, pretenzijām, sniedzot svarīgu informāciju par Pakalpojumiem, to snieg&scaron;anu un (vai) &scaron;īs Politikas izmaiņām, sazinoties ar Jums, ja esat aizmirsis savus pasūtītos pirkumus, vai konstatējam sarežģījumus Līguma saistību izpildē, kas saistīti ar veiktajiem pirkumu darījumiem, u.tml.</p>
            <p>Ļoti svarīgi ir tas, lai Jūsu norādītie personas dati būtu precīzi un pareizi. Ja Jūs norādīsiet nepareizus (neīstus) personas datus, tos aizmirsīsiet vai neatjaunosiet, Jūsu personas datiem mainoties, mums var rasties grūtības nodro&scaron;ināt Līguma saistību izpildi un Pakalpojumu snieg&scaron;anu Jums, kā arī var rasties citi sarežģījumi, īstenojot Jūsu tiesības. Mēs nekādā gadījumā nebūsim atbildīgi par kaitējumu, kas Jums radīsies tā iemesla dēļ, ja būsiet norādījis nepareizus vai neprecīzus personas datus.</p>
            <p>Ja mainās Jūsu iesniegtie dati, Jums nekavējoties mūs par to ir jāinformē, izmainot attiecīgos personas datus Jūsu reģistrētā lietotāja profilā interneta vietnē <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> vai mobilajā aplikācijā. Par to, kā atjaunot savus personas datus, kas ir mainīju&scaron;ies, lūdzam Jūs iepazīties &scaron;īs Politikas 6.2.punktā.</p>
            <p>Sabiedrībai nav iespēju pārbaudīt Jūsu nodoto personas datu pareizību un atbilstību īstenībai, ja tos Jūs mums nesniedzat. Pieņemot Jūsu reģistrāciju un noslēdzot Līgumu ar mums, mēs uzskatām, ka Jūsu iesniegtie personas dati ir precīzi un pareizi.</p>
            <p><strong>1.2. Jūsu konta administrē&scaron;ana</strong></p>
            <p>Mēs apstrādājam Jūsu personas datus, lai varētu uzraudzīt un administrēt Jūsu kontu.</p>
            <p><strong>Konta dati:</strong></p>
            <p>Datu kategorijas - reģistrē&scaron;anās (interneta vietnē <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a>) laikā norādītie dati, pievieno&scaron;anās kontam dati, darbības kontā, tai skaitā tehniskie pārlūko&scaron;anas dati (IP adrese, pievieno&scaron;anās un pārlūko&scaron;anas tehniskā informācija).</p>
            <p>Datu apstrādes juridiskais pamats - Jūsu noslēgtais Līgums un izveidotais konts, lai izpildītu Līguma saistības, kā arī mūsu leģitīmo intere&scaron;u aizsardzībai.</p>
            <p>Datu apstrādes termiņ&scaron; - visu periodu, kamēr Jūs esat konta lietotājs. Pierādījumus par līguma noslēg&scaron;anu varam glabāt arī ilgāku periodu, ja tas ir nepiecie&scaron;ams, lai varētu aizstāvēties pret mums izteiktiem prasījumiem, pretenzijām vai prasībām.</p>
            <p>Jūsu reģistrāciju, izmantot kontu interneta vietnē <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a>, mēs uzskatām par saņemtu, kad Jūs veicat Noteikumos noteiktās konta izveido&scaron;anai nepiecie&scaron;amās darbības un apstiprināt reģistrāciju.</p>
            <p><strong>1.3. Jūsu pirkuma datu apstrāde</strong></p>
            <p><strong>Pirkuma datu apstrāde</strong></p>
            <p>Sniedzot Jums Pakalpojumus un dažādas priek&scaron;rocības mēs paralēli Jūsu reģistrācijas datiem apstrādājam datus par Jūsu pirkuma operācijām (turpmāk &ndash; pirkuma dati).</p>
            <p><strong>Pakalpojumu administrē&scaron;anas mērķim apstrādājamie Jūsu pirkuma dati:</strong></p>
            <p>Datu kategorijas - Jūsu vārds, uzvārds, e-pasta adrese, tālruņa numurs, adrese, preču nosaukumi, daudzumi, pirkumu cenas un pie&scaron;ķirtās atlaides, pirkumu apmaksas metode un maksājuma informācija.</p>
            <p>Datu apstrādes juridiskais pamats - Jūsu noslēgtais Līgums un izveidotais konts, lai izpildītu Līguma saistības, kā arī mūsu leģitīmo intere&scaron;u aizsardzībai un juridisko pienākumu pildī&scaron;anai, kas rodas un ir piemērojamas Sabiedrībai</p>
            <p>Datu apstrādes termiņ&scaron; - 5 gadi pēc pirkuma operācijas veik&scaron;anas. Dokumentus mēs varam uzglābāt arī ilgāku laika periodu, kamēr noilgst saistību prasījumi, kas var izrietēt no līgumsaistībām, kā arī, ja tas ir nepiecie&scaron;ams, lai mēs varētu aizstāvēt savas leģitīmās intereses pret mums izteiktajiem prasījumiem, pretenzijām vai prasībām.</p>
            <p><strong>1.4. Datu profilē&scaron;ana, administrējot Pakalpojumu snieg&scaron;anu</strong></p>
            <p>Administrējot Pakalpojumu snieg&scaron;anu un tiecoties sasniegt Noteikumos minētos mērķus, mēs varam izmantot automatizētu personas datu (ieskaitot Jūsu pirkuma datus) analīzi un automatizētu lēmumu pieņem&scaron;anu, ieskaitot Jūsu profilē&scaron;anu. Mēs varam grupēt un analizēt Jūsu datus pēc Jūsu iegādātajām precēm un (vai) ņemot vērā citas Jums raksturīgas pazīmes (piem., vārdu, adresi, u.c.).</p>
            <p>Piemēram, Jūsu pirkuma datu analīzi un profilē&scaron;anu mēs varam veikt Jūsu intere&scaron;u labā, izlabojot savā darbībā pieļautās kļūdas. Pamanot, ka kļūdī&scaron;anās dēļ mūsu pircējiem tika piemērota nepareiza preces cena vai nepiemērota atlaide, pēc pirkuma datiem mēs varam identificēt pircējus, kas iegādājās &scaron;īs preces, un izlabot savu kļūdu (kompensēt cenas at&scaron;ķirību u.tml.).</p>
            <p>Svarīgi uzsvērt, ka mūsu veiktās Jūsu datu analīzes un profilē&scaron;anas darbības Jūs neietekmē juridiski vai citā veidā.</p>
            <p>Tomēr, ja Jūs iebilstu pret automatizētu Jūsu datu analīzi un profilē&scaron;anu &scaron;ajā Politikas sadaļā minētajiem mērķiem, mēs pieņemtu un ņemtu vērā Jūsu iebildumus. Taču, ņemot vērā Pakalpojumu snieg&scaron;anas prasības, Jūsu iebildums var nozīmēt to, ka mēs nevarēsim sniegt Jums iespēju izmantot mūsu Pakalpojumus.</p>
            <p><strong>1.5. Piedāvājumu un informācijas snieg&scaron;ana</strong></p>
            <p>Ja, reģistrējoties interneta vietnē&nbsp;<a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> vai mobilajā aplikācijā, Jūs piekritāt saņemt piedāvājumus un/vai aktuālos piedāvājumus savā e-pastā vai mobilajā aplikācijā vai SMS jaunumus savā telefonā no mums, tad mēs apstrādājam Jūsu personas datus, sniedzot Jums &scaron;ādus piedāvājumus un informāciju, piemēram, vispārīgas jaunumu vēstules, informāciju par mūsu un mūsu sadarbības partneru sniegtajiem piedāvājumiem, atlaidēm, akcijām, izpārdo&scaron;anām, aicinām Jūs piedalīties akcijās, loterijās un iegūt balvas, jautājam Jūsu viedokli par sniegtajiem pakalpojumiem un produktiem u.c. Piedāvājumus un informāciju mēs sniedzam Jūsu kontā un mobilajā aplikācijā, kā arī citā Jūsu izvēlētā veidā, piemēram, e-pastā, ar SMS īsziņu uz Jūsu norādīto telefona Nr.</p>
            <p><strong>Piedāvājumu un informācijas snieg&scaron;anas mērķim apstrādājamie personas dati:</strong></p>
            <p>Datu kategorijas:&nbsp;Jūsu vārds, e-pasta adrese un (vai) tālruņa numurs.</p>
            <p>Datu apstrādes juridiskais pamats:&nbsp;Jūsu piekri&scaron;ana saņemt piedāvājumus un informāciju par aktuālajiem jaunumiem, kā arī mūsu leģitīmo intere&scaron;u aizsardzībai.</p>
            <p>Datu apstrādes termiņ&scaron;:&nbsp;kamēr ir spēkā Jūsu dotā piekri&scaron;ana saņemt piedāvājumus un jaunumus. Jūsu doto piekri&scaron;anu un pierādījumu par to mēs varam glabāt arī ilgāku laika periodu, ja tas ir nepiecie&scaron;ams, lai mēs varētu aizstāvēties pret mums izteiktiem prasījumiem, pretenzijām vai prasībām.</p>
            <p>Jūs varat izvēlēties vēlamos komunikācijas kanālus privātuma uzstādījumos interneta vietnes&nbsp;<a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> Jūsu lietotāja kontā vai mobilajā aplikācijā. &Scaron;os uzstādījumus varat bez ierobežojuma mainīt jebkurā laikā.</p>
            <p>Ja nevēlaties, lai mēs Jums sūtītu piedāvājumus un/vai jaunumus, Jūs varat nedot mums savu piekri&scaron;anu, vai ja Jūs esat devis savu piekri&scaron;anu saņemt piedāvājumus un/vai jaunumus, tad jebkurā brīdī Jūs varat no tiem atteikties vai mainīt Jūsu izvēlētos paziņojumu saņem&scaron;anas veidus (kanālus). Jūsu atteikums saņemt piedāvājumus un jaunumus netraucēs izmantot Pakalpojumus un noslēgtā Līguma saistību izpildi.</p>
            <p><strong>1.6. Datu profilē&scaron;ana, sniedzot individuālos piedāvājumus</strong></p>
            <p>Ja, reģistrējoties interneta vietnē&nbsp;<a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> vai mobilajā aplikācijā, Jūs izvēlējāties saņemt arī individuālos (personalizētos) piedāvājumus, atbilsto&scaron;i Jūsu pasūtījumu vēsturei, un devāt piekri&scaron;anu profilē&scaron;anai &scaron;im mērķim, lai pēc iespējas labāk Jūs iepazītu, &scaron;im nolūkam apstrādāsim ne tikai Jūsu sniegtos datus, bet arī papildus analizēsim un izmantosim Jūsu pirkumu datu vēsturi.</p>
            <p><strong>Individuālo piedāvājumu snieg&scaron;anas mērķim apstrādājamie personas dati:</strong></p>
            <p>Datu kategorijas:&nbsp;Jūsu vārds, uzvārds, adrese, e-pasta adrese un tālruņa numurs, pirkumu dati, Jums piedāvāto individuālo piedāvājumu vēsture un informācija par to izmanto&scaron;anu.</p>
            <p>Datu apstrādes juridiskais pamats:&nbsp;Jūsu piekri&scaron;ana, lai Jūsu dati tiktu profilēti, sniedzot Jums individuālos piedāvājumus, kā arī mūsu leģitīmo intere&scaron;u aizsardzībai.</p>
            <p>Datu apstrādes termiņ&scaron;:&nbsp;kamēr ir spēkā Jūsu dotā piekri&scaron;ana par datu profilē&scaron;anu, lai piedāvātu Jums individuālos (personalizētos) piedāvājumus. Jūsu doto piekri&scaron;anu un pierādījumu par to mēs varam glabāt arī ilgāku periodu, ja tas ir nepiecie&scaron;ams, lai mēs varētu aizstāvēties pret mums izteiktiem prasījumiem, pretenzijām vai prasībām.</p>
            <p>Ja Jūs piekrītat tam, ka Jūsu dati tiek profilēti, sniedzot Jums individuālos (personalizētos) piedāvājumus, Jūs iegūstat iespēju saņemt personalizētus piedāvājumus, ņemot vērā Jūsu vajadzības, un saņemt citas speciāli Jums paredzētas priek&scaron;rocības. Lai sasniegtu iepriek&scaron;minēto mērķi mēs izmantojam automatizētu datu analīzi un lēmumu pieņem&scaron;anu, tai skaitā profilē&scaron;anu. Mēs grupējam un analizējam Jūsu un (vai) pirkumu datus un, ņemot vērā Jūsu iepirk&scaron;anās paradumus un (vai) citas pazīmes (piemēram, adresi, konkrētus pirkumus u.c.), sniedzam Jums aktuālus, interesantus un lietderīgus piedāvājumus, kā arī citu informāciju par individuālajiem piedāvājumiem.</p>
            <p>Piemēram, ņemot vērā Jūsu pirkumu vēsturi un iepirk&scaron;anās paradumus, mēs varam pie&scaron;ķirt atlaides Jūsu iecienītāko kategoriju precēm, informēt Jūs par individuālā sortimenta jaunumiem u.c.</p>
            <p>Ja Jūs nevēlaties, lai Jūsu dati tiktu profilēti, sniedzot Jums individuālos piedāvājumus,&nbsp;<b>Jūs varat nedot mums savu piekri&scaron;anu vai, ja Jūs esat devis savu piekri&scaron;anu individuālo piedāvājumu saņem&scaron;anai, tad jebkurā laikā Jūs varat no tiem atteikties</b>. Tādā gadījumā Jūsu atteikums saņemt individuālos piedāvājumus netraucēs turpmāk Jums izmantot Pakalpojumus un Jūsu noslēgtā Līguma saistību izpildi, taču Jūs turpmāk nevarēsiet saņemt individuālos piedāvājumus un Jums aktuālo individuālo informāciju.</p>
            <p><strong>1.7. Statistika, tirgus un mūsu pircēju rīcības izpēte</strong></p>
            <p>Mēs pastāvīgi strādājam, lai mūsu preču sortiments, piemērotās akcijas un atlaides pēc iespējas labāk atbilstu mūsu pircēju, t.sk. Jūsu vajadzībām.</p>
            <p><strong>Statistikas, tirgus un mūsu pircēju rīcības izpētes mērķim apstrādājamie dati:</strong></p>
            <p>Datu kategorijas:&nbsp;adrese, pirkuma dati.</p>
            <p>Datu apstrādes juridiskais pamats:&nbsp;Mūsu leģitīmās intereses ir analizēt pirkumu datus konkrētā reģionā pēc klientu adresēm un sagatavot mūsu komercdarbībai nepiecie&scaron;amās atskaites, lai vērtētu mūsu komercdarbību un radītu ieguvumus gan Jums kā klientam, gan Sabiedrības komercdarbībai.</p>
            <p>Datu apstrādes termiņ&scaron;:&nbsp;5 kalendārie gadi no pasūtījuma izdarī&scaron;anas brīža.</p>
            <p>Veicot statistikas datu, tirgus un pircēju rīcības izpēti, kā arī gatavojot mūsu komercdarbībai nepiecie&scaron;amos pārskatus, mēs izmantojam&nbsp;<b>automatizētu datu analīzi</b>. Analīzes laikā mēs izmantojam&nbsp;<b>nepersonalizētus datus</b>&nbsp;un neapstrādājam Jūsu vārdu, kontaktinformāciju un citu Jūs individualizējo&scaron;u informāciju. Datu analīze statistikas, tirgus un mūsu pircēju rīcības izpētes mērķim apstrādājamie dati (adrese un pirkuma dati) palīdz mums pieņemt svarīgus komercdarbības lēmumus par mūsu pircēju vajadzībām, atbilsto&scaron;a preču sortimenta veido&scaron;anu, cenu veido&scaron;anu, u.tml.</p>
            <p>&Scaron;o datu analīzes darbības neietekmē Jūsu likumīgās vai līdzīga rakstura intereses, kā arī tas neietekmē turpmāko Pakalpojumu un Jūsu noslēgtā Līguma saistību izpildi.</p>
            <p><strong>1.8. Klientu pieprasījumu, sūdzību, lūgumu un atsauksmju apkalpo&scaron;ana</strong></p>
            <p>Mēs izmantosim Jūsu personas datus, atbildot uz Jūsu pieprasījumiem, sūdzībām, lūgumiem un administrējot Jūsu atsauksmes (turpmāk &ndash;&nbsp;<b>Pieprasījums</b>).</p>
            <p><strong>Klientu pieprasījumu, sūdzību, lūgumu un atsauksmju apkalpo&scaron;anas mērķim apstrādājamie dati:</strong></p>
            <p>Datu kategorijas:&nbsp;</p>
            <ul>
                <li>Jūsu nosauktie identifikācijas un kontaktinformācijas dati: vārds, uzvārds, tālruņa numurs, elektroniskā pasta adrese, adrese, u.tml.;</li>
                <li>Jūsu Pieprasījuma saturs: gadījums, kura sakarā vēr&scaron;aties, tā apstākļi, datums, vieta, Jūsu lūgums, prasība vai atsauksme, prece, cita Pieprasījumā sniegtā informācija;</li>
                <li>Citi ar Pieprasījumu iesniegtie dokumenti un (vai) dati: piemēram, preces pirk&scaron;anas - faktūrrēķina/rēķina/preču pavadzīmes dati, preces fotogrāfijas;</li>
                <li>Telefonsarunas ieraksts, ja vēr&scaron;aties uz mūsu klientu apkalpo&scaron;anai paredzēto&nbsp;<em>Palīdzības līniju</em>.</li>
            </ul>
            <p>Datu apstrādes juridiskais pamats:&nbsp;Mūsu juridisko pienākumu izpilde uz Jūsu pieprasījumu izskatī&scaron;anu un atbildē&scaron;anu, kā arī mūsu leģitīmās intereses vērtēt Jūsu kā klienta atsauksmes un veikt telefonsarunu ierakstu, lai uzlabotu mūsu darbības un sniegto pakalpojumu kvalitāti Jums, un mūsu leģitīmo intere&scaron;u aizsardzībai.</p>
            <p>Datu apstrādes termiņ&scaron;:&nbsp;Līdz 12 mēne&scaron;iem. Jūsu prasījumus par precēm mēs varam uzglābāt arī ilgāku laika periodu, kamēr noilgts saistību prasījumi, kas var izrietēt no līgumsaistībām par preču pirkumiem, kā arī, ja tas ir nepiecie&scaron;ams, lai mēs varētu aizstāvēt savas leģitīmās intereses pret mums izteiktajiem prasījumiem, pretenzijām vai prasībām.</p>
            <p>Jūsu datus izmantojam tikai tam nolūkam, lai varētu pienācīgi un objektīvi izskatīt Jūsu Pieprasījumu, sniegt Jums nepiecie&scaron;amo informāciju, atbildēt uz Jūsu jautājumiem, atrisināt Jūsu izteiktos lūgumus vai prasījumus (sūdzības). Jūsu sniegto atsauksmju datus mēs varam analizēt, lai uzlabotu mūsu darbības un Jums sniegto pakalpojumu kvalitāti, ņemot vērā Jūsu sniegto viedokli un piedāvājumus.</p>
            <p><strong>1.9. Cik ilgi mēs apstrādājam un uzglabājam Jūsu datus?</strong></p>
            <p>Jūsu Pieprasījumu un ar to saistītos Jūsu datus mēs apstrādājam un uzglabājam tik ilgi, kamēr izskatām Pieprasījumu, sniedzam Jums atbildi un izpildām pieņemtos lēmumus, kā arī vēl līdz 6 (se&scaron;iem) mēne&scaron;iem pēc Pieprasījuma atrisinā&scaron;anas, bet ne īsāku laiku kā:</p>
            <ul>
                <li>pieprasījumi elektroniskā veidā e-pastā &ndash; ne ilgāk kā 6 mēne&scaron;us;</li>
                <li>sūdzības &ndash; 12 mēne&scaron;us;</li>
                <li>Zvanu centrā&nbsp;saņemto telefonsarunu ierakstus &ndash; 14 dienas;</li>
                <li>Atsauksmju novērtējumus (zvaigznī&scaron;u glabā&scaron;ana) &ndash; ne ilgāk kā 12 mēne&scaron;us.</li>
            </ul>
            <p>Ja saistībā ar Pieprasījumu tiek iniciēts juridisks strīds vai pastāv &scaron;āda strīda iespējamība, Jūsu datus varam uzglabāt ilgāk, līdz beigsies tiesību aktos noteiktie sūdzības iesnieg&scaron;anas vai prasības noilguma termiņi un (vai) stāsies spēkā gala nolēmums.</p>
            <p>Beidzoties &scaron;ajā Politikā noteiktajam Jūsu datu apstrādes un uzglabā&scaron;anas termiņam, mēs dokumentus, kas satur Jūsu personas datus, iznīcināsim patstāvīgi, vai elektroniskā veidā eso&scaron;os Jūsu personas datus uzticami un neatjaunojami izdzēsīsim vai anonimizēsim pēc iespējas ātrāk saprātīgā un pamatotā &scaron;ādas darbības veik&scaron;anai nepiecie&scaron;amā laikā.</p>
            <ol start={2}>
                <li><strong> No kādiem avotiem mēs iegūstam un apkopojam Jūsu personas datus?</strong></li>
            </ol>
            <p>Mēs iegūstam un apstrādājam Jūsu personas datus atbilsto&scaron;i &scaron;ādiem juridiskiem pamatiem:</p>
            <ul>
                <li>Jūsu piekri&scaron;ana;</li>
                <li>mūsu leģitīmās intereses;</li>
                <li>juridisko pienākumu pildī&scaron;ana, kas rodas un ir piemērojamas Sabiedrībai.</li>
            </ul>
            <p>Jūsu datu apstrādei var tikt piemērots viens vai vairāki augstāk norādītie juridiskie pamati.</p>
            <p>Gandrīz visus Jūsu personas datus mēs iegūstam tikai no Jums. Reģistrējoties interneta vietnē&nbsp;www.quicktouchmenu.eu vai mobilajā aplikācijā Jūs datus iesniedzat tie&scaron;ā veidā mums, bet pirkuma datus &ndash; izmantojot Pakalpojumus.</p>
            <p>Jūsu datus iegūstam tie&scaron;ā veidā no Jums arī tad, kad iesniedzat Pieprasījumu jebkādā izvēlētajā veidā: elektroniskā veidā uzrakstot mums elektronisku vēstuli un nosūtot to mums uz elektronisko pasta adresi, iesniedzot mums papīra formā rakstisku pieprasījumu, vai mutiskā veidā zvanot pa telefonu uz mūsu klientu apkalpo&scaron;anas&nbsp;Zvanu centru&nbsp;u.c.</p>
            <p>Tad, kad kvalitatīvai un objektīvai Jūsu Pieprasījuma izskatī&scaron;anai mums jāiegūst papildu informācija vai jāveic nozīmīgu apstākļu izpēte, Jūsu Pieprasījuma datus varam sasaistīt ar mūsu eso&scaron;ajiem un (vai) Jūsu Pieprasījuma izskatī&scaron;anas laikā savāktajiem datiem, piemēram, mēs varam izskatīt pirkuma operāciju datus, Jūsu konta lieto&scaron;anas vēsturi, aptaujāt mūsu darbiniekus u.tml.</p>
            <ol start={3}>
                <li><strong> Kā mēs datus glabājam un iznīcinām?</strong></li>
            </ol>
            <p>Noslēdzoties Jūsu datu apstrādes un uzglabā&scaron;anas periodam, kas noteikts &scaron;ajā politikā (izņemot, zemāk minētajos gadījumos), mēs iznīcināsim Jūsu datus dro&scaron;ā veidā, bet Politikā norādītajos gadījumos &ndash; uzticami un neatjaunojami anonimizēsim pēc iespējas ātrāk, saprātīgā un pamatotā &scaron;ādu darbību veik&scaron;anai nepiecie&scaron;amā laika periodā.</p>
            <p>Ilgāk, nekā noteikts &scaron;ajā politikā, Jūsu personas dati var tikt uzglabāti tikai tad, ja pastāvēs kāds no &scaron;ādiem apstākļiem:</p>
            <ul>
                <li>tas ir nepiecie&scaron;ams, lai mēs varētu aizsargāties pret prasījumiem, pretenzijām vai prasībām un varētu īstenot savas tiesības;</li>
                <li>personas datu glabā&scaron;anās termiņ&scaron; ir noteikts vai izriet no Latvijas Republikas un Eiropas Savienības normatīvajiem aktiem</li>
                <li>ir pamatotas aizdomas par nelikumīgu rīcību, kā sakarā tiek veikta izmeklē&scaron;ana;</li>
                <li>Jūsu dati ir nepiecie&scaron;ami pienācīgai strīda, sūdzības izskatī&scaron;anai;</li>
                <li>nepiecie&scaron;ams nodro&scaron;ināt rezerves kopiju un citus līdzīgus mērķus;</li>
                <li>pastāvot citiem tiesību aktos noteiktajiem pamatojumiem.</li>
            </ul>
            <p>Gadījumā, ja būs iestāju&scaron;ies &scaron;ādi apstākļi, tad informācija, kas satur personas datus tiks glabāta līdz attiecīgā procesa (kriminālprocesa, administratīvo pārkāpumu procesa, administratīvā procesa pabeig&scaron;anas) nodo&scaron;anai tiesībsargājo&scaron;ajai institūcijai vai tās galīgajam nolēmumam.</p>
            <ol start={4}>
                <li><strong> Kādos gadījumos un kādiem personas datu saņēmējiem mēs atklājam Jūsu datus?</strong></li>
            </ol>
            <p>Jūsu datus varam nodot apstrādei personas datu saņēmējiem, t.i. personām, kas palīdz mums izpildīt un administrēt Pakalpojumu snieg&scaron;anu, sniedz mums ar klientu Pieprasījumu administrē&scaron;anu saistītos pakalpojumus. Tādas personas var būt IT datubāzu programmatūras piegādātāji, datubāzu administrē&scaron;anas pakalpojumu sniedzēji, datu centru, uzturē&scaron;anas un mākoņpakalpojumu sniedzēji, tie&scaron;ie tirgdarbības pakalpojumu sniedzēji, tirgus izpētes vai uzņēmējdarbības analītikas pakalpojumu sniedzēji u.tml. Ikvienā gadījumā datu apstrādātājiem iesniedzam tikai tik daudz datu, cik tas ir nepiecie&scaron;ams konkrēta uzdevuma izpildei vai konkrēta pakalpojuma snieg&scaron;anai. Mūsu piesaistītie datu apstrādātāji var apstrādāt Jūsu personas datus tikai pēc mūsu norādījumiem un nevar tos izmantot citiem mērķiem vai nodot citām personām bez mūsu piekri&scaron;anas. Turklāt tiem ir jānodro&scaron;ina Jūsu datu aizsardzība saskaņā ar spēkā eso&scaron;iem tiesību aktiem un saskaņā ar mums noslēgto rakstisko vieno&scaron;anos.</p>
            <p>Ja, sniedzot Pakalpojumus, mēs pie&scaron;ķiram tiesības izmantot mūsu partneru pakalpojumus (piem., partneru īstenoto lojalitātes programmu sniegtās priek&scaron;rocības), daži Jūsu dati būs pieejami arī mūsu sadarbības partneriem, taču tikai &scaron;ādas vispārīgas pakalpojumu snieg&scaron;anas vai citu sadarbības nosacījumu mērķiem.</p>
            <p>Ja Pieprasījumā aprakstītais gadījums tiks atzīts par apdro&scaron;inā&scaron;anas gadījumu, Jūsu Pieprasījumu un datus nodosim apdro&scaron;inā&scaron;anas sabiedrībai (-ām), kurā (-ās) esam apdro&scaron;ināju&scaron;i savu civiltiesisko atbildību, mantu vai kura (-as) sniedz mums citu ar Jūsu Pieprasījumā minēto gadījumu saistītu apdro&scaron;inā&scaron;anas segumu. Apdro&scaron;inā&scaron;anas sabiedrības darbojas kā neatkarīgi/atsevi&scaron;ķi datu pārziņi un Jūsu datus apstrādā saskaņā ar apdro&scaron;inā&scaron;anas sabiedrības nosacījumiem un noteikumiem.</p>
            <p>Dati var tikt nodoti arī kompetentām/uzraugo&scaron;ām Valsts varas, pa&scaron;valdības vai tiesībsargājo&scaron;ām iestādēm, piemēram, policijai, prokuratūrai, tiesai vai kontrolējo&scaron;ām/uzraudzības institūcijām, taču tikai tām pamatoti pieprasot vai tikai tad, ja tas ir nepiecie&scaron;ams saskaņā ar spēkā eso&scaron;iem tiesību aktiem vai tiesību aktu noteiktajos gadījumos un kārtībā, lai nodro&scaron;inātu valsts, pa&scaron;valdību vai tiesībsargājo&scaron;o iestāžu un/vai mūsu tiesības, mūsu pircēju, darbinieku un IT resursu dro&scaron;ību, izvirzītu, iesniegtu un aizstāvētu juridiskās prasības, u.tml.</p>
            <p>Ja mēs izmantojam mājaslapas analīzes pakalpojumu (piem., Google Analytics), ko lieto, lai noteiktu, kā Jūs pārlūkojat interneta vietnē&nbsp;www.quicktouchmenu.eu vai mobilajā aplikācijā sniegto informāciju, ar Jūsu anonimizētajiem datiem varam apmainīties ar personām, kas pamatojas uz &scaron;o informāciju un vērtē, kā tiek pārlūkota interneta vietne vai mobilā aplikācija, gatavojot vietnes apstrādātājiem paredzētās atskaites par interneta vietnes vai mobilās aplikācijas darbību un sniedzot citus ar interneta vietnes, interneta un mobilās aplikācijas lieto&scaron;anu saistītus pakalpojumus. Pla&scaron;āku informāciju par to varat izlasīt pārlūkprogrammas Sīkfailu politikā.</p>
            <ol start={5}>
                <li><strong> Kādā teritorijā mēs apstrādājam Jūsu personas datus?</strong></li>
            </ol>
            <p>Jūsu personas datus apstrādājam tikai Eiropas Savienības teritorijā. Mums &scaron;obrīd nav nodoma nodot un mēs nenododam Jūsu personas datus tre&scaron;ajām valstīm. Gadījumā, ja Pakalpojumu snieg&scaron;anai vai komunikācijai izmantojam&nbsp;<a href="http://www.facebook.com/">www.facebook.com</a>, vai Google analytics pakalpojumus, tad attiecībā uz datu nodo&scaron;anu uz tre&scaron;ajām valstīm attiecīgie pakalpojuma sniedzēji ir uzskatāmi par tre&scaron;o personu un aicinām iepazīties ar &scaron;o pakalpojumu sniedzēju privātuma politikām.</p>
            <ol start={6}>
                <li><strong> Kādas ir Jūsu tiesības un kā Jūs tās varat izmantot?</strong></li>
            </ol>
            <p>Datu aizsardzības tiesību akti sniedz Jums daudz tiesību, ko varat brīvi izmantot, savukārt mēs nodro&scaron;inām iespēju Jums tās izmantot. Informāciju par konkrētām Jūsu tiesībām un to īsteno&scaron;anas veidiem sniedzam &scaron;ajā Politikā, lūdzam to uzmanīgi izlasīt:</p>
            <p><strong>6.1. Tiesības piekļūt saviem personīgajiem datiem, kurus mēs pārvaldām</strong></p>
            <p>Jums ir tiesības saņemt mūsu apstiprinājumu par to, vai mēs apstrādājam Jūsu personas datus, kā arī tiesības iepazīties ar mūsu apstrādājamajiem Jūsu personas datiem un informāciju par datu apstrādes mērķiem, apstrādājamo datu kategorijām, datu saņēmēju kategorijām, datu apstrādes periodu, datu iegū&scaron;anas avotiem, automatizētu lēmumu pieņem&scaron;anu, tai skaitā profilē&scaron;anu, kā arī to nozīmi un sekām attiecībā uz Jums.</p>
            <p>Lielāko daļu &scaron;īs informācijas sniedzam &scaron;ajā Politikā un ticam, ka tā Jums būs noderīga.</p>
            <p>Ja esat interneta vietnes&nbsp;www.quicktouchmenu.eu reģistrētais konta lietotājs, Jūs varat jebkurā brīdī savā lietotāja kontā iepazīties ar mūsu apstrādājamajiem Jūsu personas datiem (piem., pārbaudīt Jūsu iesniegto personas datu informāciju, aktualizēt informāciju, Jūsu dotās piekri&scaron;anas, u.tml.), saņemt Jums izrakstītos faktūrrēķinus par pēdējiem 12 mēne&scaron;iem. Ja esat mobilās aplikācijas lietotājs, ar visu minēto informāciju varat iepazīties mobilajā aplikācijā.</p>
            <p>Ja &scaron;ajā Politikā, interneta vietnes&nbsp;www.quicktouchmenu.eu&nbsp;Jūsu lietotāja kontā vai mobilajā aplikācijā sniegtā informācija Jums nav pietiekama vai vēlaties saņemt pirkumu operāciju vēsturi par ilgāku nekā 12 mēne&scaron;u periodu, Jūs vienmēr varat vērsties pie mums &scaron;īs Politikas 8.punktā norādītajos veidos.</p>
            <p><strong>6.2. Tiesības labot personas datus</strong></p>
            <p>Ja ir mainīju&scaron;ies Jūsu reģistrācijas brīdī norādītie dati vai redzat, ka mūsu apstrādājamā informācija par Jums ir neprecīza vai nepareiza, Jums ir tiesības pieprasīt &scaron;o informāciju mainīt, precizēt vai izlabot, vēr&scaron;oties pie mums &scaron;īs Politikas 8.punktā norādītajos veidos vai</p>
            <p>Jūs varat patstāvīgi veikt interneta vietnes&nbsp;www.quicktouchmenu.eu kontā vai mobilajā aplikācijā savu datu labo&scaron;anu (aktualizāciju).</p>
            <p><strong>6.3. Tiesības atcelt piekri&scaron;anu</strong></p>
            <p>Tajos gadījumos, kad Jūsu datus apstrādājam saskaņā ar Jūsu piekri&scaron;anu, Jums ir tiesības jebkurā laikā atsaukt savu piekri&scaron;anu, kas būs pamats datu apstrādes izbeig&scaron;anai. Piekri&scaron;anas atsaukums neietekmē apstrādes likumību, kas pamatojas uz piekri&scaron;anu pirms atsaukuma.</p>
            <p>Piemēram, Jūs varat jebkurā brīdī atsaukt savu piekri&scaron;anu saņemt piedāvājumus un jaunumus, kā arī piekri&scaron;anu par datu profilē&scaron;anu, sniedzot Jums individuālos piedāvājumus. &Scaron;o piekri&scaron;anu atsauk&scaron;ana neierobežos Jums turpināt izmantot Pakalpojumus un noslēgtā Līguma saistību izpildi, bet tas nozīmēs, ka mēs nevarēsim sniegt un Jūs nevarēsiet saņemt Jums aktuālus piedāvājumus un jaunumus.</p>
            <p>Savas piekri&scaron;anas Jūs varat koriģēt (tās atsaukt vai no jauna dot), iesniedzot Jūsu atbilsto&scaron;i atjauninātu reģistrācijas informāciju par dotajām piekri&scaron;anām, mainot piekri&scaron;anu nosacījumus savā reģistrētā lietotāja kontā vai mobilajā aplikācijā vai sazinoties ar mums &scaron;īs Politikas 8.punktā norādītajos veidos.</p>
            <p>Kad Jūsu piekri&scaron;ana tiks atsaukta, mēs pārtrauksim uz piekri&scaron;anas pamata apstrādāt datus un Politikā norādītajos gadījumos &ndash; datus uzticami un neatjaunojami dzēsīsim vai anonimizēsim.</p>
            <p>Jebkurā gadījumā Jūsu doto piekri&scaron;anu un pierādījumu par Jūsu doto piekri&scaron;anu mēs varam uzglabāt arī ilgāku laika periodu, ja tas ir nepiecie&scaron;ams, lai mēs varētuaizstāvēt savas leģitīmās intereses pret mums izteiktajiem prasījumiem, pretenzijām vai prasībām.</p>
            <p><strong>6.4. Tiesības iesniegt sūdzību</strong></p>
            <p>Ja Jūs uzskatāt, ka Jūsu datus mēs apstrādājam, pārkāpjot datu apstrādes/aizsardzības tiesību aktu prasības, mēs vispirms lūdzam vērsties tie&scaron;i pie mums. Mēs ticam, ka mums izdosies kliedēt visas Jūsu &scaron;aubas, apmierināt lūgumus un novērst mūsu pieļautās kļūdas, ja tādas būs.</p>
            <p>Ja Jūs nebūsiet apmierināts ar mūsu piedāvātajiem problēmas risinā&scaron;anas veidiem vai, pēc Jūsu domām, mēs nepildīsim saskaņā ar Jūsu lūgumu nepiecie&scaron;amās darbības, Jums ir tiesības iesniegt sūdzību uzraugo&scaron;ajai institūcijai, kas Latvijas Republikā ir Datu Valsts inspekcija.</p>
            <p><strong>6.5. Tiesības nepiekrist datu apstrādei, kad apstrāde ir pamatota ar leģitīmām interesēm</strong></p>
            <p>Jums ir tiesības iebilst personas datu apstrādei, ja personas dati tiek apstrādāti, pamatojoties uz mūsu leģitīmām interesēm. Tomēr, ņemot vērā Pakalpojumu snieg&scaron;anas mērķus un abu pu&scaron;u (gan Jūsu kā datu subjekta, gan mūsu kā personas datu apstrādes pārziņa) likumīgo intere&scaron;u līdzsvaru, Jūsu iebildumi var nozīmēt, ka, pārtraucot Jūsu datu apstrādi mūsu leģitīmo intere&scaron;u nodro&scaron;inā&scaron;anai, mēs nevarēsim sniegt iespēju Jums turpināt izmantot Pakalpojumus un noslēgtā Līguma darbība tiks pārtraukta un izbeigta.</p>
            <p>Ja vēlaties izmantot &scaron;ajā sadaļā norādītās tiesības, lūdzam Jūs iesniegt rakstisku lūgumu mūsu Datu aizsardzības speciālistam, kura kontaktinformācija ir norādīta &scaron;īs Politikas 8.punktā.</p>
            <p><strong>6.6. Tiesības izdzēst datus (tiesības būt aizmirstam)</strong></p>
            <p>Pastāvot noteiktiem datu apstrādes tiesību aktos minētajiem apstākļiem (piemēram, ja personas dati tiek apstrādāti nelikumīgi, zūd datu apstrādes tiesiskais pamats, personas dati vairs nav nepiecie&scaron;ami saistībā ar nolūkiem, kādos tie tika vākti vai citādi apstrādāti, u.c.), Jums ir tiesības lūgt, lai mēs izdzēstu Jūsu personas datus. Ja vēlaties izmantot &scaron;īs tiesības, lūdzam iesniegt rakstisku lūgumu mūsu Datu aizsardzības speciālistam, kura kontaktinformācija ir norādīta &scaron;īs Politikas 8.punktā.</p>
            <p>Svarīgi atzīmēt, ka Jūsu interneta vietnes www.quicktouchmenu.eu kontā reģistrētā lietotāja dati bez atsevi&scaron;ķa Jūsu lūguma tiks izdzēsti, bet pārējie dati &ndash; izdzēsti vai dro&scaron;i anonimizēti, ja Jūs pārtrauksiet izmantot interneta vietnē&nbsp;www.quicktouchmenu.eu eso&scaron;os Pakalpojumus un ir pagāju&scaron;i 2 (divi) gadi no Jūsu kā reģistrētā lietotāja pēdējās aktivitātes interneta vietnē&nbsp;www.quicktouchmenu.eu.</p>
            <p><strong>6.7. Tiesības ierobežot datu apstrādi</strong></p>
            <p>Pastāvot noteiktiem datu apstrādes tiesību aktos minētajiem apstākļiem (ja personas dati tiek apstrādāti nelikumīgi, Jūs apstrīdat datu precizitāti, Jūs iesniedzat iebildumus par datu apstrādi mūsu leģitīmo intere&scaron;u nodro&scaron;inā&scaron;anai, u.c.), Jums tāpat ir tiesības ierobežot Jūsu datu apstrādi. Tomēr mums ir jāatzīmē, ka datu apstrādes ierobežo&scaron;anas dēļ un uz &scaron;o ierobežojumu periodu mums var nebūt iespēju nodro&scaron;ināt Jums Pakalpojumu snieg&scaron;anu un Līguma saistību izpildi.</p>
            <p>Ja vēlaties izmantot &scaron;ajā sadaļā norādītās tiesības, lūdzam Jūs iesniegt rakstisku lūgumu mūsu Datu aizsardzības speciālistam, kura kontaktinformācija ir norādīta &scaron;īs Politikas 8.punktā.</p>
            <p><strong>6.8. Tiesības uz datu pārnesamību</strong></p>
            <p>Datus, ko apstrādājam saskaņā ar Jūsu piekri&scaron;anu un kuru apstrāde veicama, piemērojot automatizētus līdzekļus, Jums ir tiesības pārcelt uz citu datu pārzini. Datus, ko vēlaties pārcelt, mēs Jums iesniegsim vienkār&scaron;i mūsu sistēmās izmantojamā un datorlasāmā formātā, bet, ja Jūs to vēlēsieties un būs tehniskas iespējas, pārsūtīsim datus tie&scaron;i Jūsu norādītajam citam datu pārzinim.</p>
            <p>Ja vēlaties izmantot tiesības uz datu pārnesamību, lūdzam Jūs iesniegt rakstisku lūgumu mūsu Datu aizsardzības speciālistam, kura kontaktinformācija ir norādīta &scaron;īs Politikas 8.punktā.</p>
            <ol start={7}>
                <li><strong> Kā mēs izskatām un apstrādājam Jūsu pieprasījumus?</strong></li>
            </ol>
            <p>Lai aizsargātu mūsu pircēju datus no nelikumīgas atklā&scaron;anas, mums, saņemot Jūsu lūgumu par Jūsu datu snieg&scaron;anu vai citu Jūsu tiesību īsteno&scaron;anu, būs jāpārliecinās par Jūsu identitāti. &Scaron;im nolūkam mēs varam lūgt Jūs norādīt Jūsu interneta vietnes&nbsp;www.quicktouchmenu.eu lietotāja kontā vai mobilajā aplikācijā lietotāja profilā reģistrētos aktuālos datus (piemēram, vārdu, uzvārdu, adresi, e-pasta adresi vai tālruņa numuru), pēc tam salīdzināsim, vai Jūsu norādītie dati sakrīt ar attiecīgajiem reģistrētā lietotāja datiem. Veicot &scaron;o pārbaudi, mēs tāpat varam izsūtīt kontroles paziņojumu uz reģistrācijas anketā norādīto kontaktinformāciju saziņai (īsziņas vai e-pasta veidā), lūdzot veikt autorizāciju. Ja pārbaudes procedūra būs neveiksmīga (piemēram, Jūsu norādītie dati nesakritīs ar interneta vietnē&nbsp;www.quicktouchmenu.eu vai mobilajā aplikācijā reģistrētā lietotāja profilā norādītajiem datiem vai Jūs neveiksiet autorizāciju pēc nosūtītās īsziņas vai e-pasta paziņojuma saņem&scaron;anas), mēs būsim spiesti konstatēt, ka Jūs neesat lūgto datu subjekts, un būsim spiesti noraidīt Jūsu iesniegto lūgumu.</p>
            <p>Saņemot Jūsu lūgumu par jebkuru Jūsu tiesību īsteno&scaron;anu un veiksmīgi veicot iepriek&scaron; norādīto pārbaudes procedūru, mēs apņemamies bez pamata nekavējoties, taču jebkurā gadījumā ne vēlāk kā viena mēne&scaron;a laikā no Jūsu lūguma saņem&scaron;anas un pārbaudes procedūras beigām sniegt Jums informāciju saskaņā ar Jūsu iesniegto lūgumu. Ņemot vērā lūgumu sarežģītību un skaitu, mums ir tiesības viena mēne&scaron;a periodu pagarināt vēl uz diviem mēne&scaron;iem, par to Jūs informējot līdz pirmā mēne&scaron;a beigām un norādot &scaron;āda pagarinājuma iemeslus.</p>
            <p>Ja Jūsu lūgums ir iesniegts ar elektroniskajiem saziņas līdzekļiem, atbildi sniegsim arī ar elektroniskajiem saziņas līdzekļiem, izņemot gadījumus, kad tas nebūs iespējams (piemēram, lielā informācijas apjoma dēļ) vai tad, ja Jūs lūgsiet atbildēt citādā veidā.</p>
            <p>Mēs atteiksimies apmierināt Jūsu lūgumu ar motivētu atbildi, ja tiks konstatēti tiesību aktos norādītie apstākļi, gadījumos, kad informāciju nevaram Jums izsniegt, par to rakstiski Jūs informējot.</p>
            <ol start={8}>
                <li><strong> Kādā veidā jūs varat sazināties ar mums?</strong></li>
            </ol>
            <ul>
                <li>ar elektroniskā pasta starpniecību, rakstot uz e-pasta adresi: rolands@elaw.ee  </li>
                <li>zvanot uz mūsu Zvanu centra tālruni: +37126488822</li>
            </ul>
            <p>Datu aizsardzības speciālista kontaktdati:</p>
            <ul>
                <li>e-pasta adrese: rolands@elaw.ee ,</li>
                <li>pasta korespondences adrese: Laki tn 30 Mustamae linnaosa, Tallinn Harju maakind 12915, Estonia, Vēstuli adresējiet: AuraTrust O&Uuml; Datu aizsardzības speciālistam</li>
            </ul>
            <ol start={9}>
                <li><strong> Cik dro&scaron;i ir Jūsu dati?</strong></li>
            </ol>
            <p>Mēs cienām Jūsu privātumu, tādēļ Jūsu personas datu dro&scaron;ība ir mūsu prioritāte. Mēs izmantojam atbilsto&scaron;us organizatoriskus un tehniskus līdzekļus, lai nodro&scaron;inātu pastāvīgu Jūsu personas datu dro&scaron;ību un datu apstrādes atbilstību personas datu aizsardzības tiesību aktu un mūsu iek&scaron;ējās politikas prasībām.</p>
            <p>Mēs konsekventi ievērojam personas datu minimizē&scaron;anas principu un neapkopojam datus, kas mums nav nepiecie&scaron;ami &scaron;ajā Politikā norādīto mērķu sasnieg&scaron;anai.</p>
            <p>Mēs izmantojam dažādas personas datu aizsardzības un dro&scaron;ību nodro&scaron;ino&scaron;as tehnoloģijas un procedūras, lai aizsargātu Jūsu personīgo informāciju no nelikumīgas piekļuves, izmanto&scaron;anas vai atklā&scaron;anas. Mūsu sadarbības partneri tiek rūpīgi atlasīti un viņiem pieprasām, lai tiktu izmantotu atbilsto&scaron;i līdzekļi, kas aizsargātu personas datu konfidencialitāti un nodro&scaron;inātu Jūsu personīgās informācijas dro&scaron;ību. Tomēr, Jūsu informācijas nodo&scaron;anā mums, izmantojot internetu vai mobilos sakarus, pilnīgu dro&scaron;ību nevar garantēt; jebkāda Jūsu informācijas nodo&scaron;ana mums iepriek&scaron; norādītos veidos tiek veikta uz Jūsu pa&scaron;u riska.</p>
            <ol start={10}>
                <li><strong> Sīkfaili</strong></li>
            </ol>
            <p>Mēs izmantojam sīkfailus (tas ir neliels informācijas fails, kas tiek nosūtīts uz Jūsu datoru vai citu iekārtu (piemēram, mobilo telefonu), kad apmeklējat mūsu mājaslapu, un tiek saglabāts Jūsu pārlūkprogrammā). Sīkfails tiek nosūtīts uz Jūsu datoru vai citu iekārtu ar nolūku saglabāt datus, lai mēs varētu Jūs kā Pakalpojumu lietotāju atpazīt brīdī, kad apmeklējat mūsu mājaslapu. Ar sīkfailu palīdzību mēs tāpat varam sasaistīt Jūsu pirkuma vēsturi, citus datus, ko savācāt Pakalpojumu izmanto&scaron;anas laikā, ar Jūsu pārlūko&scaron;anu internetā. Ar sīkfailu palīdzību iegūtā informācija ļauj Jums nodro&scaron;ināt ērtāku pārlūko&scaron;anu, sniegt pievilcīgus piedāvājumus un vairāk uzzināt par mūsu mājaslapas lietotāju rīcību, analizēt tendences un pilnveidot gan mājaslapu, gan Jūsu apkalpo&scaron;anu.</p>
            <p>Mūsu mājaslapā tāpat tiek izmantoti tre&scaron;ās puses sīkfaili. Tie tiek lietoti, lai veidotu ikviena apmeklētāja pārlūko&scaron;anas vēsturi ar nolūku parādīt tie&scaron;i Jums paredzēto reklāmu un nodro&scaron;ināt labāko pieredzi, apmeklējot mūsu mājaslapu. Ja Jūsu pārlūkprogramma ļauj ierakstīt tre&scaron;o pu&scaron;u sīkfailus, mūsu izvēlētajam partnerim būs iespēja ierakstīt savus sīkfailus Jūsu pārlūkprogrammā.</p>
            <p>Pla&scaron;āku informāciju par sīkfailiem, to izmanto&scaron;anu un atteik&scaron;anos skatiet pārlūkprogrammas Sīkfailu politikā.</p>
            <ol start={11}>
                <li><strong> Politikas spēkā esamība un izmaiņas</strong></li>
            </ol>
            <p>AuraTrust O&Uuml; interneta vietnes www.quicktouchmenu.eu privātuma politika var tikt mainīta vai aktualizēta. Interneta vietnē www.quicktouchmenu.eu Jūs vienmēr atradīsiet jaunāko privātuma politikas versiju.</p>
            <ol start={12}>
                <li><strong> Politikas piemēro&scaron;ana</strong></li>
            </ol>
            <p>Politika nav piemērojama citiem mūsu vai mūsu grupas uzņēmumu sniegtajiem pakalpojumiem.</p>
        </>
    );
};

export default PolicyLv;