import React from 'react';

const TelegramIcon = () => {
    return (
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 0.292969C8.96 0.292969 0 9.25297 0 20.293C0 31.333 8.96 40.293 20 40.293C31.04 40.293 40 31.333 40 20.293C40 9.25297 31.04 0.292969 20 0.292969ZM29.28 13.893C28.98 17.053 27.68 24.733 27.02 28.273C26.74 29.773 26.18 30.273 25.66 30.333C24.5 30.433 23.62 29.573 22.5 28.833C20.74 27.673 19.74 26.953 18.04 25.833C16.06 24.533 17.34 23.813 18.48 22.653C18.78 22.353 23.9 17.693 24 17.273C24.0139 17.2094 24.012 17.1433 23.9946 17.0806C23.9772 17.0178 23.9447 16.9603 23.9 16.913C23.78 16.813 23.62 16.853 23.48 16.873C23.3 16.913 20.5 18.773 15.04 22.453C14.24 22.993 13.52 23.273 12.88 23.253C12.16 23.233 10.8 22.853 9.78 22.513C8.52 22.113 7.54 21.893 7.62 21.193C7.66 20.833 8.16 20.473 9.1 20.093C14.94 17.553 18.82 15.873 20.76 15.073C26.32 12.753 27.46 12.353 28.22 12.353C28.38 12.353 28.76 12.393 29 12.593C29.2 12.753 29.26 12.973 29.28 13.133C29.26 13.253 29.3 13.613 29.28 13.893Z" fill="#FD8D14"/>
        </svg>
    );
};

export default TelegramIcon;