import {FC, ReactNode, useEffect, useRef, useState} from 'react';
import Header from "../organisms/Header/Header";


import Footer from "../organisms/Footer/Footer";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";


import AnimatedCursor from "react-animated-cursor"


interface ILayout {
    headerType?: "relative" | "absolute"
    children?: ReactNode;
}

const LayoutTemplate: FC<ILayout> = ({headerType, children}) => {
    const containerRef = useRef(null)
    const [header, setHeader] = useState(false);
    const [outHeader, setOutHeader] = useState(false);

    useEffect(()=>{
        let prevScroll = 0;
        window.addEventListener("scroll", ()=>{
            if(window.scrollY > 152 && window.scrollY > prevScroll ){
                setOutHeader(true);
            }
            else{
                setOutHeader(false);
            }

            setHeader(window.scrollY > 15);
            prevScroll = window.scrollY;
        });

    },[]);
    useEffect(()=>{

        if(window.location.hash) {
            setTimeout(()=> {
                document.getElementById(window.location.hash.split('#')[1])?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            },1000);
        }
        else{
            window.scrollTo({top:0, behavior: 'smooth' });
        }
    },[]);

    const dispatch = useDispatchEx();
    return (
        <>
            <AnimatedCursor
                innerSize={8}
                outerSize={40}
                color='255, 113, 22'
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={3}

                clickables={[
                    'a',
                    'input[type="text"]',
                    'input[type="email"]',
                    'input[type="number"]',
                    'input[type="submit"]',
                    'input[type="image"]',
                    'label[for]',
                    'select',
                    'textarea',
                    'button'
                ]}
            />
            <Header className={`${headerType === 'relative' ? "header-" + headerType : ""}`} attach={header} out={outHeader}/>


            <main ref={containerRef}>
                {children}
            </main>
            <Footer/>

        </>
    );
};

export default LayoutTemplate;