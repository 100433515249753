import { FC, useState } from "react";
import Logo from "../../icons/Logo";
import "./Header.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SwitchLanguage from "../../atoms/SwitchLanguage/SwitchLanguage";
import LogoTextIcon from "../../icons/LogoTextIcon";
import ArrowIcon from "../../icons/ArrowIcon";

interface IHeader {
  styleInner?: any;
  className?: string;
  attach?: boolean;
  out?: boolean;
}

const Header: FC<IHeader> = ({ styleInner, className = "", attach, out }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const onClickDownload = (e: any) => {
    e.preventDefault();
    document.getElementById("download")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <header
      style={styleInner}
      className={`header ${attach ? "active" : ""} ${out ? "out" : ""} ${
        open ? "active-menu" : ""
      } ${className}`}
    >
      <div className="header__wrapper">
        <div className="container">
          <div className="wrapper">
            <div className="row">
              <div className="header__inner">
                <div className="col-lg-6">
                  <Link className={"header__logo"} to={"/"}>
                    <Logo />
                  </Link>
                </div>
                {/* <div className="header__info">
              <div className="header__menu">
                <a onClick={onClickDownload}>
                  Download
                  <div className="header__circle">
                    <ArrowIcon />
                  </div>
                </a>
              </div>
              <div className={`header__lang ${open ? "active" : ""}`}>
                <SwitchLanguage />
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
