import { FC } from "react";

const StaffIcon: FC = () => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_1089)">
        <path
          d="M16.5658 16.6521C19.91 17.6464 23.5364 14.9957 23.4839 11.4119C23.1857 4.1958 12.8897 4.19623 12.5918 11.412C12.5918 13.9018 14.2759 16.0099 16.5658 16.6521Z"
          fill="#f8a101"
        />
        <path
          d="M26.8158 25.3755C26.101 21.1834 22.4359 17.9849 18.0379 17.9849C13.0705 17.9483 8.9121 22.2268 9.16301 27.0838C9.2418 27.3261 9.47201 27.4897 9.73249 27.4897H26.3433C27.3426 27.4055 26.8484 26.011 26.8158 25.3755Z"
          fill="#f8a101"
        />
        <path
          d="M28.609 18.4573C30.8019 18.4573 32.589 16.6702 32.589 14.4773C32.3892 9.21258 24.8279 9.214 24.6289 14.4773C24.6289 16.6702 26.416 18.4573 28.609 18.4573Z"
          fill="#f8a101"
        />
        <path
          d="M28.6089 18.8813C27.4821 18.8813 26.3674 19.1782 25.3982 19.7416C25.6829 20.0384 25.9434 20.3595 26.1857 20.6927C27.2785 22.1673 27.961 23.9999 28.0939 25.8722H34.3942C34.7274 25.8722 35 25.5996 35 25.2664C35 21.7467 32.1346 18.8813 28.6089 18.8813Z"
          fill="#f8a101"
        />
        <path
          d="M7.46687 18.4573C9.65983 18.4573 11.4469 16.6702 11.4469 14.4773C11.2471 9.21258 3.68582 9.214 3.48682 14.4773C3.48682 16.6702 5.2739 18.4573 7.46687 18.4573Z"
          fill="#f8a101"
        />
        <path
          d="M10.6775 19.7415C6.54684 17.2511 0.989604 20.4352 1.07582 25.2664C1.07575 25.5995 1.34836 25.8721 1.68154 25.8721H7.98177C8.14554 23.5731 9.12065 21.3913 10.6775 19.7415Z"
          fill="#f8a101"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_1089">
          <rect
            width="34"
            height="34"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StaffIcon;
