import React, {FC, ReactNode} from 'react';
import MailIcon from "../../icons/MailIcon";
import PhoneIcon from "../../icons/PhoneIcon";

interface IContactBlock{
    title?:ReactNode;
    email?:string;
    phone?:string;

}

const ContactBlock:FC<IContactBlock> = ({title,email,phone}) => {
    return (
        <div className="contacts-section__item">
            <div className="contacts-section__header">
                <h2 ><span>{title}</span></h2>
            </div>
            <div className="contacts-section__description">
                {email ? <div className={'contacts-section__description-item'}><a className={'link-line--reverse'} href={`mailto:${email}`}><MailIcon/>{email}</a></div> : ""}
                {phone ? <div className={'contacts-section__description-item'}><a className={'link-line'} href={`tel:${phone}`}><PhoneIcon/>{phone}</a></div> : "" }
            </div>
        </div>
    );
};

export default ContactBlock;