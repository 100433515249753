import {createSlice} from "@reduxjs/toolkit";


interface appState {
    modalFeedback: boolean;
    modalLogin: boolean;
    modalRegistration: boolean;
    modalContact: boolean;
    modalSuccess: boolean;
    modalErrorMessage: boolean;
    timeline: any;
    isLoading: boolean;
    error: string
}

const initialState: appState = {
    modalFeedback: false,
    modalLogin: false,
    modalRegistration: false,
    modalContact:  false,
    modalSuccess: false,
    modalErrorMessage: false,
    isLoading: false,
    timeline:0,
    error: ''
};


const appSlice = createSlice({
    name:'appSlice',
    initialState,
    reducers:{
        changeTimeLine(state,action){
            state.timeline = action.payload;
        },
        changeModalFeedback(state){
            state.modalFeedback = !state.modalFeedback;
        },
        changeModalLogin(state){
            state.modalLogin = !state.modalLogin;
        },
        changeModalRegistration(state){
            state.modalRegistration = !state.modalRegistration;
        },
        changeModalContact(state){
            state.modalContact = !state.modalContact;
        },
        changeModalSuccess(state){
            state.modalSuccess = !state.modalSuccess;
        },
        changeModalErrorMessage(state){
            state.modalErrorMessage = !state.modalErrorMessage;
        },

    }
});

export const {changeTimeLine,changeModalFeedback,changeModalContact,changeModalLogin,changeModalRegistration,changeModalSuccess,changeModalErrorMessage} = appSlice.actions;
export default appSlice.reducer;