import {FC, useEffect, useRef} from 'react';
import './ContactsSection.scss'
import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "../../../hooks/redux";
import {anime1Finish, anime1Start} from "../../../helpers/animations";
import MailIcon from "../../icons/MailIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import ContactBlock from "./ContactBlock";
const ContactsSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);

    const targetSection = useRef(null);
    useEffect(()=>{

        let timeline = gsap.timeline({

        });
        timeline.fromTo(".contacts-section__item", anime1Start, anime1Finish, 0.1);

    },[targetSection]);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();
    return (
        <section className={'contacts-section'} ref={targetSection}>
            <div className="container">
                <div className="wrapper">
                    <div className="contacts-section__inner">
                        <div className="contacts-section__items">
                            <ContactBlock title={'Rolands Millers'} phone={'+37258515899'} email={'rolands@quicktouchwaiter.com'}/>

                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default ContactsSection;