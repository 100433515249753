import { FC } from "react";

const SupportIcon: FC = () => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_1069)">
        <path
          d="M21.8873 15.9213C25.5352 15.9545 28.5939 12.8024 28.55 9.15395C28.3085 0.858389 16.8657 -0.0548449 15.3227 8.04904C14.6167 12.093 17.8266 15.9655 21.8873 15.9213ZM21.2335 4.43972C21.2496 3.58164 22.5289 3.57943 22.5412 4.43975C22.5412 4.43972 22.5412 8.50013 22.5412 8.50013H24.7446C25.1108 8.50013 25.3985 8.79437 25.3985 9.15397C25.3985 9.52012 25.1108 9.80781 24.7446 9.80781H21.8873C21.5277 9.80781 21.2335 9.52012 21.2335 9.15397V4.43972Z"
          fill="#f8a101"
        />
        <path
          d="M32.9047 13.9597H28.282C27.2751 15.3394 25.8366 16.3855 24.1758 16.889V20.9101C24.1758 22.5317 22.8681 23.8459 21.2728 23.8459H12.132V26.2978C12.132 27.2001 12.8512 27.939 13.7273 27.939H27.2358C27.4123 27.939 27.5823 28.0044 27.7 28.1286L30.5443 31.012V28.5928C30.5443 28.2267 30.8385 27.939 31.1981 27.939H32.9047C33.7873 27.939 34.5 27.2001 34.5 26.2978V15.5943C34.5 14.692 33.7873 13.9597 32.9047 13.9597Z"
          fill="#f8a101"
        />
        <path
          d="M22.8681 20.9101V17.1701C18.1603 17.7822 13.894 13.96 13.9235 9.15393C13.9235 8.95781 13.93 8.76166 13.9496 8.57202H2.09538C1.21271 8.57202 0.5 9.30433 0.5 10.2001V20.9101C0.5 21.8124 1.21271 22.5382 2.09538 22.5382H3.80191C4.16151 22.5382 4.45575 22.8324 4.45575 23.192V25.6178L7.30001 22.7344C7.4177 22.6101 7.5877 22.5382 7.76421 22.5382H21.2727C22.1488 22.5382 22.8681 21.8124 22.8681 20.9101Z"
          fill="#f8a101"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_1069">
          <rect
            width="34"
            height="34"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SupportIcon;
