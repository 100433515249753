import React from 'react';

const PolicyEt = () => {
    return (
        <>
            <p><strong>Privaatsuspoliitika</strong></p>
            <p>Registreerudes aadressil <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> v&otilde;i mobiilirakenduses, usaldate oma isikuandmed AuraTrust O&Uuml;-le, registrikood Eesti Vabariigis 16562585, juriidiline aadress Ravi tn 2, 10134, Tallinn, Eesti, kui isikuandmete vastutavale t&ouml;&ouml;tlejale (edaspidi: osa&uuml;hing v&otilde;i meie v&otilde;i vastutav t&ouml;&ouml;tleja) ja annate meile &otilde;iguse neid t&ouml;&ouml;delda vastavalt k&auml;esolevale privaatsuspoliitikale (edaspidi: poliitika) ja mobiilirakenduse allalaadimise ja kasutamise raames ning seoses registreerumise ja sisselogimise ning teenuste osutamisega vastavalt asjakohastele kasutustingimustele (edaspidi: tingimused). Teavitame Teid, et me t&ouml;&ouml;tleme Teie isikuandmeid ainult siis, kui te otsustate meile oma isikuandmeid edastada.</p>
            <p>Rakendades Euroopa Parlamendi ja n&otilde;ukogu m&auml;&auml;rust (EL) 2016/679, 27. aprill 2016, f&uuml;&uuml;siliste isikute kaitse kohta isikuandmete t&ouml;&ouml;tlemisel ja selliste andmete vaba liikumise ning direktiivi 95/46/E&Uuml; kehtetuks tunnistamise kohta (isikuandmete kaitse &uuml;ldm&auml;&auml;rus), tagame Teile eriti l&auml;bipaistva ja ausa Teie isikuandmete t&ouml;&ouml;tlemise ning hoolitseme Teie privaatsuse ja teie isikuandmete turvalisuse eest.</p>
            <p>Sellest poliitikast leiate kogu teabe selle kohta, milliseid Teie isikuandmeid me kogume ja t&ouml;&ouml;tleme, milleks neid kasutame, kui kaua neid s&auml;ilitame jne. See teave on oluline, seega soovitame Teil enne oma isikuandmete edastamist hoolikalt tutvuda k&auml;esoleva poliitika tingimustega.</p>
            <p>Samuti p&ouml;&ouml;rame Teie t&auml;helepanu sellele, et me v&otilde;ime nii poliitikat kui ka tingimusi muuta, t&auml;iendada ja uuendada.</p>
            <p>Kui te ei n&otilde;ustu tingimustega, k&auml;esoleva poliitikaga v&otilde;i selle teatud tingimustega, ei saa me kahjuks pakkuda Teile v&otilde;imalust kasutada k&otilde;iki v&otilde;i m&otilde;nda meie teenust (edaspidi: teenused).</p>
            <ol>
                <li><strong> Mis on isikuandmed ja milliseid Teie isikuandmeid me t&ouml;&ouml;tleme ja miks?</strong></li>
            </ol>
            <p><strong>Registreerumine</strong></p>
            <p><strong>Isikuandmed on igasugune teave tuvastatud v&otilde;i tuvastatava f&uuml;&uuml;silise isiku ("andmesubjekti") kohta; tuvastatav f&uuml;&uuml;siline isik on isik, keda saab otseselt v&otilde;i kaudselt tuvastada, eelk&otilde;ige sellise identifitseerimistunnuse p&otilde;hjal, nagu nimi, isikukood, asukohateave, v&otilde;rguidentifikaator v&otilde;i selle f&uuml;&uuml;silise isiku &uuml;he v&otilde;i mitme f&uuml;&uuml;silise, f&uuml;sioloogilise, geneetilise, vaimse, majandusliku, kultuurilise v&otilde;i sotsiaalse tunnuse p&otilde;hjal.</strong></p>
            <p>Registreerudes veebisaidil <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> v&otilde;i mobiilirakenduses, peate Te n&otilde;ustuma veebisaidi v&otilde;i mobiilirakenduse kasutustingimustega (n&otilde;ustuma lepinguga). Teie isikuandmete esitamine on kohustuslik, kuna nende esitamata j&auml;tmine ei v&otilde;imalda Teil s&otilde;lmida lepingut ega kasutada meie teenuseid. Registreerudes s&otilde;lmite Te lepingu ja kinnitate, et Teie esitatud isikuandmed on t&auml;psed ja &otilde;iged ning et olete v&auml;hemalt 18-aastane. Me ei v&otilde;ta vastutust Teie poolt esitatud ebat&auml;psete, ebat&auml;ielike v&otilde;i vigaste isikuandmete eest. Juhul, kui Te olete esitanud teise isiku isikuandmeid, j&auml;tab kaebuse korral AuraTrust O&Uuml; endale &otilde;iguse p&ouml;&ouml;rduda Teie vastu regressin&otilde;udega.</p>
            <p><strong>Lepingu jaoks vajalikud isikuandmed:</strong></p>
            <ul>
                <li>Andmeliigid: Teie eesnimi, perekonnanimi, aadress, e-posti aadress ja telefoninumber.</li>
                <li>Isikuandmete t&ouml;&ouml;tlemise &otilde;iguslik alus on Teie kui lepingupoolega lepingu s&otilde;lmimine ja t&auml;itmine ning meie &otilde;igustatud huvide kaitsmine.</li>
                <li>Isikuandmete t&ouml;&ouml;tlemise t&auml;htaeg on kogu ajavahemik, mille jooksul leping kehtib ja Te kasutate meie teenuseid. Teiega s&otilde;lmitud lepingut ja t&otilde;endeid selle s&otilde;lmimise kohta v&otilde;ime me s&auml;ilitada ka pikema aja jooksul, kuni aegub n&otilde;ude&otilde;igus, mis tuleneb lepingulistest kohustustest ning kui see on vajalik meie &otilde;igustatud huvide kaitseks meie vastu esitatud n&otilde;uete, pretensioonide ja hagide korral.</li>
            </ul>
            <p>Teie esitatud isikuandmete j&auml;rgi saame me aru, kui soovite n&auml;iteks oma andmeid uuendada v&otilde;i muuta, v&otilde;tate meiega &uuml;hendust seoses asjakohase isikliku teabe esitamisega, isikuandmete t&ouml;&ouml;tlemisega seotud &otilde;iguste teostamisega jne. Vastavalt Teie poolt veebisaidil <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> ja/v&otilde;i mobiilirakenduses registreerumisel esitatud isikuandmetele luuakse p&auml;rast lepingu s&otilde;lmimist Teie ainulaadne kasutajaprofiil (konto), mille alusel tuvastame Teid veebisaidi <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> ja/v&otilde;i mobiilirakenduse registreeritud kasutajana, mis v&otilde;imaldab Teil kasutada registreeritud kasutajatele kohaldatavaid teenuseid vastavalt lepingu tingimustele ja k&auml;esoleva poliitika s&auml;tetele.</p>
            <p>Me v&otilde;ime kasutada Teie poolt esitatud kontaktandmeid (e-posti aadress, telefoninumber) teiega suhtlemiseks, et t&auml;ita lepingust tulenevaid kohustusi, sh vastata Teie taotlustele, kaebustele, edastada olulist teavet teenuste, nende osutamise ja/v&otilde;i k&auml;esoleva poliitika muudatuste kohta, v&otilde;tta teiega &uuml;hendust, kui olete unustanud oma tellitud kauba v&otilde;i kui meil tekib raskusi teie tehtud ostutehingutega seotud lepingust tulenevate kohustuste t&auml;itmisel jne.</p>
            <p>On v&auml;ga oluline, et Teie esitatud isikuandmed oleksid t&auml;psed ja &otilde;iged. Kui Te esitate eba&otilde;igeid (valesid) isikuandmeid, unustate oma isikuandmed, ei uuenda neid v&otilde;i kui Teie isikuandmed muutuvad, v&otilde;ib meil tekkida raskusi lepingust tulenevate kohustuste t&auml;itmise tagamisel ja Teile teenuste osutamisel ning muid raskusi Teie &otilde;iguste teostamisel. Me ei vastuta mingil juhul kahju eest, mis Teile tekib eba&otilde;igete v&otilde;i ebat&auml;psete isikuandmete esitamise t&otilde;ttu.</p>
            <p>Kui Teie esitatud isikuandmed muutuvad, peate meid sellest viivitamatult teavitama, muutes asjaomaseid isikuandmeid oma registreeritud kasutaja profiilil veebisaidil <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> v&otilde;i mobiilirakenduses. Teavet selle kohta, kuidas oma muutunud isikuandmeid uuendada, leiate k&auml;esoleva poliitika punktist 6.2.</p>
            <p>Osa&uuml;hingul ei ole v&otilde;imalik kontrollida Teie esitatud isikuandmete &otilde;igsust ja tegelikkusele vastavust, kui Te neid meile ei esita. Kui me oleme Teid registreerinud ja Te s&otilde;lmite meiega lepingu, leiame, et Teie esitatud isikuandmed on t&auml;psed ja &otilde;iged.</p>
            <p><strong>1.2. Teie konto haldamine</strong></p>
            <p>Me t&ouml;&ouml;tleme Teie isikuandmeid Teie konto j&auml;lgimiseks ja haldamiseks.</p>
            <p><strong>Konto andmed:</strong></p>
            <p>Andmeliigid &ndash; registreerumise k&auml;igus (veebisaidil <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a>) esitatud isikuandmed, konto sisselogimise andmed, toimingud kontol, sealhulgas tehnilised sirvimisandmed (IP-aadress, sisselogimise ja sirvimise tehnilised andmed).</p>
            <p>Isikuandmete t&ouml;&ouml;tlemise &otilde;iguslik alus on Teie poolt s&otilde;lmitud leping ja loodud konto lepingust tulenevate kohustuste t&auml;itmiseks ning meie &otilde;igustatud huvide kaitsmiseks.</p>
            <p>Andmete t&ouml;&ouml;tlemise t&auml;htaeg on kogu ajavahemik, mil olete konto kasutaja. Me v&otilde;ime s&auml;ilitada t&otilde;endeid lepingu s&otilde;lmimise kohta ka pikema aja jooksul, kui seda on vaja, et saaksime kaitsta end meie vastu esitatud n&otilde;uete, kaebuste v&otilde;i hagide korral.</p>
            <p>Me loeme Teie registreerumise konto kasutamiseks veebisaidil <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> vastuv&otilde;etuks, kui te teete konto loomiseks tingimustes m&auml;&auml;ratud vajalikud sammud ja kinnitate registreerumise.</p>
            <p><strong>1.3. Teie ostuandmete t&ouml;&ouml;tlemine</strong></p>
            <p><strong>Ostuandmete t&ouml;&ouml;tlemine</strong></p>
            <p>Osutades Teile teenuseid ja andes einevaid eeliseid, t&ouml;&ouml;tleme me lisaks Teie registreerimisandmetele ka andmeid Teie ostutehingute kohta (edaspidi: ostuandmed).</p>
            <p><strong>Teie ostuandmed, mida t&ouml;&ouml;deldakse teenuste haldamise eesm&auml;rgil:</strong></p>
            <p>Andmeliigid: Teie eesnimi, perekonnanimi, e-posti aadress, telefoninumber, aadress, toodete nimetused, kogused, hinnad ja antud soodustused, makseviis ja makseteave.</p>
            <p>Andmete t&ouml;&ouml;tlemise &otilde;iguslik alus on Teie poolt s&otilde;lmitud leping ja loodud konto lepingust tulenevate kohustuste t&auml;itmiseks ning meie &otilde;igustatud huvide kaitsmiseks ja juriidiliste kohustuste t&auml;itmiseks, mis tekivad ja kohalduvad osa&uuml;hingule.</p>
            <p>Andmete t&ouml;&ouml;tlemise t&auml;htaeg on 5 aastat p&auml;rast ostutehingut. Me v&otilde;ime dokumente s&auml;ilitada ka pikema aja jooksul, kuni aegub n&otilde;ude&otilde;igus, mis tuleneb lepingulistest kohustustest ning kui see on vajalik meie &otilde;igustatud huvide kaitseks meie vastu esitatud n&otilde;uete, pretensioonide ja hagide korral.</p>
            <p><strong>1.4. Andmete profileerimine teenuste osutamise haldamisel</strong></p>
            <p>Teenuste haldamisel ja tingimustes s&auml;testatud eesm&auml;rkide saavutamiseks v&otilde;ime kasutada isikuandmete (sealhulgas teie ostuandmete) automatiseeritud anal&uuml;&uuml;si ja automatiseeritud otsuste tegemist, sealhulgas profiilianal&uuml;&uuml;si. Me v&otilde;ime r&uuml;hmitada ja anal&uuml;&uuml;sida Teie andmeid Teie ostetud toodete ja/v&otilde;i muude Teile iseloomulike tunnuste (nt nimi, aadress jne) alusel.</p>
            <p>N&auml;iteks v&otilde;ime anal&uuml;&uuml;sida ja profileerida Teie ostuandmeid Teie huvides, parandades meie tehtud vigu. Kui me m&auml;rkame, et vea t&otilde;ttu on meie ostjatele kohaldatud valet hinda v&otilde;i pole kohaldatud allahindlust, saame ostuandmete abil tuvastada kauba ostnud kliendid ja parandada oma vea (kompenseerida hinnavahe jne).</p>
            <p>Oluline on r&otilde;hutada, et Teie andmete anal&uuml;&uuml;s ja profiilianal&uuml;&uuml;si tegevused ei m&otilde;juta Teid &otilde;iguslikult ega muul viisil.</p>
            <p>Kui Te aga ei n&otilde;ustu oma andmete automatiseeritud anal&uuml;&uuml;si ja profiilianal&uuml;&uuml;siga poliitika k&auml;esoleva jaotises nimetatud eesm&auml;rkidel, aktsepteerime Teie vastuv&auml;iteid ja v&otilde;tame neid arvesse. V&otilde;ttes arvesse teenuste osutamise n&otilde;udeid, v&otilde;ib Teie vastuv&auml;ide siiski t&auml;hendada, et me ei saa teile pakkuda v&otilde;imalust meie teenuseid kasutada.</p>
            <p><strong>1.5. Pakkumiste ja teabe edastamine</strong></p>
            <p>Kui olete veebilehel <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> v&otilde;i mobiilirakenduses registreerudes n&otilde;ustunud saama meilt pakkumisi ja/v&otilde;i p&auml;evakohaseid pakkumisi oma e-posti aadressile v&otilde;i mobiilirakenduses v&otilde;i SMS-uudiskirju oma telefonile, t&ouml;&ouml;tleme Teie isikuandmeid, edastades Teile selliseid pakkumisi ja teavet, n&auml;iteks &uuml;ldisi uudiskirju, teavet meie ja meie &auml;ripartnerite pakkumiste, allahindluste, kampaaniate ja soodusm&uuml;&uuml;gi kohta, kutsudes Teid osalema kampaaniates, loosimistes ja auhindadele kandideerimises, k&uuml;sides Teie arvamust pakutavate teenuste ja toodete kohta jne. Edastame pakkumisi ja teavet Teie kontol ja mobiilirakenduses, samuti muul Teie valitud viisil, n&auml;iteks e-posti teel, SMS-ga Teie poolt antud telefoninumbrile.</p>
            <p><strong>Isikuandmed, mida t&ouml;&ouml;deldakse pakkumiste ja teabe edastamiseks:</strong></p>
            <p>Andmeliigid:&nbsp;Teie nimi, e-posti aadress ja/v&otilde;i telefoninumber.</p>
            <p>Isikuandmete t&ouml;&ouml;tlemise &otilde;iguslik alus:&nbsp;Teie n&otilde;usolek saada pakkumisi ja teavet p&auml;evakohaste uudiste kohta ning meie &otilde;igustatud huvide kaitsmiseks.</p>
            <p>Isikuandmete t&ouml;&ouml;tlemise t&auml;htaeg: seni, kuni kehtib Teie n&otilde;usolek pakkumiste ja uudiskirjade saamiseks. Teie n&otilde;usolekut ja t&otilde;endit selle kohta v&otilde;ime me s&auml;ilitada ka pikema aja jooksul, kui see on vajalik selleks, et me saaksime end kaitsta meie vastu esitatud n&otilde;uete, pretensioonide v&otilde;i hagide eest.</p>
            <p>Oma eelistatud suhtluskanalid saate valida oma kasutajakonto v&otilde;i mobiilirakenduse privaatsusseadetes veebisaidil <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a>. Neid seadeid saate igal ajal piiranguteta muuta.</p>
            <p>Kui te ei soovi, et me teile pakkumisi ja/v&otilde;i uudiskirju saadame, v&otilde;ite mitte anda meile oma n&otilde;usolekut, v&otilde;i kui te olete andnud oma n&otilde;usoleku pakkumiste ja/v&otilde;i uudiskirjade saamiseks, v&otilde;ite igal ajal loobuda sellest v&otilde;i oma valitud k&auml;ttesaamisviisi (sidevahendeid) muuta. Teie keeldumine pakkumiste ja uudiste saamisest ei sega teenuste kasutamist ega lepinguliste kohustuste t&auml;itmist.</p>
            <p><strong>1.6. Profiilianal&uuml;&uuml;s personaalsete pakkumiste tegemiseks</strong></p>
            <p>Kui registreerumisel veebisaidil <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> v&otilde;i mobiilirakenduses n&otilde;ustusite ka personaalsete (isikustatud) pakkumistega vastavalt Teie tellimuste ajaloole ning andsite n&otilde;usoleku profiilianal&uuml;&uuml;siks sellel eesm&auml;rgil, et teid v&otilde;imalikult h&auml;sti tundma &otilde;ppida, siis me ei t&ouml;&ouml;tle mitte ainult Teie poolt esitatud andmeid, vaid anal&uuml;&uuml;sime ja kasutame lisaks ka Teie ostuajalugu.</p>
            <p><strong>Personaalsete pakkumiste edastamise eesm&auml;rgil t&ouml;&ouml;deldavad isikuandmed on:</strong></p>
            <p>Andmeliigid:&nbsp;Teie nimi, perekonnanimi, aadress, e-posti aadress ja telefoninumber, ostuandmed, Teile pakutud personaalsete pakkumiste ajalugu ja teave nende kasutamise kohta.</p>
            <p>Isikuandmete t&ouml;&ouml;tlemise &otilde;iguslik alus:&nbsp;Teie n&otilde;usolek Teie andmete profileerimiseks, et pakkuda Teile personaalseid pakkumisi ja kaitsta meie &otilde;igustatud huve.</p>
            <p>Andmete t&ouml;&ouml;tlemise t&auml;htaeg: seni, kuni kehtib Teie n&otilde;usolek profiilianal&uuml;&uuml;siks, et pakkuda teile individuaalseid (personaalseid) pakkumisi.&nbsp; Teie n&otilde;usolekut ja t&otilde;endit selle kohta v&otilde;ime me s&auml;ilitada ka pikema aja jooksul, kui see on vajalik selleks, et me saaksime end kaitsta meie vastu esitatud n&otilde;uete, pretensioonide v&otilde;i hagide eest.</p>
            <p>Kui te n&otilde;ustute Teie andmete profileerimisega, et pakkuda teile personaalseid (isikustatud) pakkumisi, saate oma vajadustele vastavaid personaalseid pakkumisi ja muid spetsiaalselt Teile m&otilde;eldud eeliseid. Eespool nimetatud eesm&auml;rgi saavutamiseks kasutame automatiseeritud andmeanal&uuml;&uuml;si ja otsuste tegemist, sealhulgas profiilianal&uuml;&uuml;si. Me r&uuml;hmitame ja anal&uuml;&uuml;sime Teie ja/v&otilde;i ostude andmeid ning pakume teile p&auml;evakohaseid, huvitavaid ja kasulikke pakkumisi ning muud teavet personaalsete pakkumiste kohta, mis p&otilde;hinevad teie ostuharjumustel ja/v&otilde;i muudel tunnustel (nt aadress, konkreetsed ostud jne).</p>
            <p>N&auml;iteks saame Teie ostuajaloo ja ostuharjumuste p&otilde;hjal anda soodustusi Teie lemmiktootekategooria kaupadele, teavitada Teid personaalse sortimendi uutest toodetest jne.</p>
            <p>Kui Te ei soovi, et teie andmeid profileeritaks, kui me teeme Teile personaalseid pakkumisi, <b>v&otilde;ite mitte anda meile oma n&otilde;usolekut v&otilde;i kui olete andnud n&otilde;usoleku personaalsete pakkumiste saamiseks, v&otilde;ite igal ajal sellest loobuda.</b> Sellisel juhul ei sega Teie keeldumine personaalsete pakkumiste saamisest Teid teenuste kasutamist j&auml;tkamast ja lepinguliste kohustuste t&auml;itmist, kuid Te ei saa enam personaalseid pakkumisi ja p&auml;evakohast personaalset teavet.</p>
            <p><strong>1.7. Statistika, turu- ja meie ostjate k&auml;itumise uuringud </strong></p>
            <p>T&ouml;&ouml;tame pidevalt selle nimel, et meie tootevalik, kampaaniad ja allahindlused vastaksid v&otilde;imalikult h&auml;sti meie ostjate, sh Teie vajadustele.</p>
            <p><strong>Statistika, turu- ja meie ostjate k&auml;itumise uuringute eesm&auml;rgil t&ouml;&ouml;deldavad andmed:</strong></p>
            <p>Andmeliigid: aadress, ostuandmed.</p>
            <p>Isikuandmete t&ouml;&ouml;tlemise &otilde;iguslik alus:&nbsp;meie &otilde;igustatud huvi on anal&uuml;&uuml;sida ostuandmeid konkreetses piirkonnas klientide aadresside j&auml;rgi ja koostada meie &auml;ritegevuseks vajalikke aruandeid, et hinnata meie &auml;ritegevust ning luua kasu nii Teile kui kliendile kui ka osa&uuml;hingu &auml;ritegevusele.</p>
            <p>Andmete t&ouml;&ouml;tlemise t&auml;htaeg:&nbsp;5 kalendriaastat alates tellimuse esitamise hetkest.</p>
            <p>Statistikaandmete, turu ja kliendik&auml;itumise uurimiseks ning &auml;ritegevuseks vajalike aruannete koostamiseks kasutame <b>automatiseeritud andmeanal&uuml;&uuml;si.</b> Anal&uuml;&uuml;si k&auml;igus kasutame <b>isikustamata andmeid</b> ega t&ouml;&ouml;tle Teie nime, kontaktandmeid ja muid isikuandmeid. Statistika, turu ja ostjate k&auml;itumise anal&uuml;&uuml;simise eesm&auml;rgil t&ouml;&ouml;deldavad andmed (aadress ja ostuandmed), aitavad meil teha olulisi &auml;riotsuseid meie klientide vajaduste, tootevaliku, hinnakujunduse ja muu kohta.</p>
            <p>Need andmeanal&uuml;&uuml;si tegevused ei m&otilde;juta Teie &otilde;igustatud v&otilde;i sarnase iseloomuga huve ega teenuste edaspidist osutamist ja s&otilde;lmitud lepingust tulenevate kohustuste t&auml;itmist.</p>
            <p><strong>1.8. Klientide p&auml;ringute, kaebuste, taotluste ja tagasiside k&auml;sitlemine</strong></p>
            <p>Me kasutame Teie isikuandmeid selleks, et vastata Teie p&auml;ringutele, kaebustele, taotlustele ja hallata Teie tagasisidet (edaspidi: p&auml;ring).</p>
            <p><strong>Andmed, mida t&ouml;&ouml;deldakse klientide p&auml;ringute, kaebuste, taotluste ja tagasiside menetlemise eesm&auml;rgil:</strong></p>
            <p>Andmeliigid:&nbsp;</p>
            <ul>
                <li>Teie esitatud isiku- ja kontaktandmed: nimi, perekonnanimi, telefoninumber, e-posti aadress, aadress jne;</li>
                <li>teie p&auml;ringu sisu: juhtum, millega seoses Te meie poole p&ouml;&ouml;rdute, selle asjaolud, kuup&auml;ev, koht, Teie taotlus, n&otilde;ue v&otilde;i tagasiside, kaup, muu p&auml;ringus esitatud teave;</li>
                <li>muud dokumendid ja/v&otilde;i andmed, mis on esitatud koos p&auml;ringuga: n&auml;iteks kauba ostuarvete/saatekirja andmed, fotod kaubast;</li>
                <li>telefonik&otilde;ne salvestus, kui p&ouml;&ouml;rdute meie poole klienditeeninduseks m&otilde;eldud Abiliini kaudu.</li>
            </ul>
            <p>Isikuandmete t&ouml;&ouml;tlemise &otilde;iguslik alus:&nbsp;meie juriidiliste kohustuste t&auml;itmine Teie p&auml;ringute l&auml;bivaatamise ja neile vastamise osas, samuti meie &otilde;igustatud huvi hinnata Teie kui kliendi tagasisidet ja salvestada telefonivestlusi, et parandada meie tegevuse ja osutatavate teenuste kvaliteeti ning kaitsta meie &otilde;igustatud huve.</p>
            <p>Andmete t&ouml;&ouml;tlemise t&auml;htaeg:&nbsp;kuni 12 kuud. Teie p&auml;ringuid kaupade kohta v&otilde;ime s&auml;ilitada ka pikema aja jooksul, kuni aegub n&otilde;ude&otilde;igus, mis v&otilde;ib tuleneda kauba ostmise lepingulistest kohustustest ning kui see on vajalik meie &otilde;igustatud huvide kaitsmiseks meie vastu esitatud n&otilde;uete, pretensioonide ja hagide korral.</p>
            <p>Me kasutame teie andmeid ainult selleks, et n&otilde;uetekohaselt ja objektiivselt vaadata l&auml;bi Teie p&auml;ringut, anda Teile vajalikku teavet, vastata Teie k&uuml;simustele ja lahendada Teie taotlusi v&otilde;i n&otilde;udeid (kaebusi). Me v&otilde;ime anal&uuml;&uuml;sida teie tagasisidet, et parandada meie tegevust ja Teile pakutavate teenuste kvaliteeti, v&otilde;ttes arvesse Teie arvamust ja ettepanekuid.</p>
            <p><strong>1.9. Kui kaua me Teie andmeid t&ouml;&ouml;tleme ja s&auml;ilitame?</strong></p>
            <p>Teie p&auml;ringut ja sellega seotud Teie andmeid t&ouml;&ouml;tleme ja s&auml;ilitame, kuni p&auml;ringut menetleme, anname Teile vastuse ja t&auml;idame vastuv&otilde;etud otsuseid, ning kuni 6 (kuus) kuud p&auml;rast p&auml;ringu lahendamist, kuid mitte l&uuml;hemat aega kui:</p>
            <ul>
                <li>elektroonilised p&auml;ringud e-posti teel &ndash; mitte kauem kui 6 kuud;</li>
                <li>kaebused &ndash; 12 kuud;</li>
                <li>k&otilde;nekeskusesse tulnud telefonik&otilde;nede salvestised &ndash; 14 p&auml;eva;</li>
                <li>tagasiside hinnangud (t&auml;rnide s&auml;ilitamine) &ndash; mitte kauem kui 12 kuud.</li>
            </ul>
            <p>Kui seoses p&auml;ringuga on algatatud v&otilde;i t&otilde;en&auml;oliselt algatatakse &otilde;iguslik vaidlus, v&otilde;ime Teie andmeid s&auml;ilitada kauem, kuni l&otilde;pevad &otilde;igusaktides s&auml;testatud kaebuse v&otilde;i n&otilde;ude esitamise aegumist&auml;htajad ja/v&otilde;i j&otilde;ustub l&otilde;plik otsus.</p>
            <p>K&auml;esolevas poliitikas s&auml;testatud Teie isikuandmete t&ouml;&ouml;tlemise ja s&auml;ilitamise t&auml;htaja m&ouml;&ouml;dumisel h&auml;vitame iseseisvalt Teie isikuandmeid sisaldavad dokumendid v&otilde;i elektroonilisel kujul olemasolevad isikuandmed kustutame usaldusv&auml;&auml;rselt ja j&auml;&auml;davalt v&otilde;i muudame anon&uuml;&uuml;mseks v&otilde;imalikult kiiresti m&otilde;istlikul ja p&otilde;hjendatud sellise toimingu tegemiseks vajaliku aja jooksul.</p>
            <ol start={2}>
                <li><strong> Millistest allikatest me Teie isikuandmeid saame ja kogume?</strong></li>
            </ol>
            <p>Me kogume ja t&ouml;&ouml;tleme Teie isikuandmeid vastavalt j&auml;rgmistele &otilde;iguslikele alustele:</p>
            <ul>
                <li>teie n&otilde;usolek;</li>
                <li>meie &otilde;igustatud huvid;</li>
                <li>osa&uuml;hingule seadusest tulenevate ja kohalduvate juriidiliste kohustuste t&auml;itmine.</li>
            </ul>
            <p>Teie isikuandmete t&ouml;&ouml;tlemisel kohaldatakse &uuml;hte v&otilde;i mitut eespool nimetatud &otilde;iguslikku alust.</p>
            <p>Peaaegu k&otilde;iki Teie isikuandmeid saame ainult Teilt. Kui registreerute veebilehel www.quicktouchmenu.eu v&otilde;i mobiilirakenduses, esitate isikuandmed otse meile ning ostuandmeid esitate teenuseid kasutades.</p>
            <p>Samuti saame Teie andmed otse Teilt, kui esitate p&auml;ringu mis tahes valitud viisil: elektrooniliselt, kirjutades meile e-kirja ja saates selle meie e-posti aadressile, esitades meile kirjaliku taotluse paberkandjal v&otilde;i suuliselt, helistades meie klienditeeninduskeskusesse jne.&nbsp;</p>
            <p>Kui meil on vaja hankida lisateavet v&otilde;i uurida olulisi asjaolusid, et Teie p&auml;ringut kvalitatiivselt ja objektiivselt menetleda, v&otilde;ime siduda Teie p&auml;ringu andmed meie olemasolevate andmetega ja/v&otilde;i Teie p&auml;ringu menetlemise k&auml;igus kogutud andmetega, n&auml;iteks v&otilde;ime vaadata l&auml;bi ostutehingute andmeid, Teie konto kasutamise ajalugu, k&uuml;sitleda meie t&ouml;&ouml;tajaid jne.</p>
            <ol start={3}>
                <li><strong> Kuidas me andmeid s&auml;ilitame ja h&auml;vitame?</strong></li>
            </ol>
            <p>K&auml;esolevas poliitikas s&auml;testatud Teie isikuandmete t&ouml;&ouml;tlemise ja s&auml;ilitamise ajavahemiku l&otilde;ppedes (v&auml;lja arvatud allpool kirjeldatud juhtudel) h&auml;vitame Teie andmed turvaliselt, aga poliitikas nimetatud juhtudel muudame need usaldusv&auml;&auml;rselt ja p&ouml;&ouml;rdumatult anon&uuml;&uuml;mseks niipea kui v&otilde;imalik m&otilde;istliku ja p&otilde;hjendatud selliste toimingute tegemiseks vajaliku ajavahemiku jooksul.</p>
            <p>Teie isikuandmeid v&otilde;ib s&auml;ilitada kauem kui on s&auml;testatud k&auml;esolevas poliitikas ainult sel juhul, kui kehtib &uuml;ks j&auml;rgmistest asjaoludest:</p>
            <ul>
                <li>see on vajalik selleks, et me saaksime end kaitsta n&otilde;uete, kaebuste v&otilde;i hagide vastu ja teostada oma &otilde;igusi;</li>
                <li>isikuandmete s&auml;ilitamise t&auml;htaeg on kehtestatud L&auml;ti Vabariigi ja Euroopa Liidu seaduste ja m&auml;&auml;rustega v&otilde;i tuleneb nendest;</li>
                <li>on p&otilde;hjendatud alus kahtlustada ebaseaduslikku tegevust, mille suhtes toimub uurimine;</li>
                <li>Teie andmed on vajalikud vaidluse v&otilde;i kaebuse n&otilde;uetekohaseks menetlemiseks;</li>
                <li>vajadus tagada varukoopia ja muudel sarnastel eesm&auml;rkidel;</li>
                <li>muudel &otilde;igusaktides s&auml;testatud alustel.</li>
            </ul>
            <p>Selliste asjaolude korral s&auml;ilitatakse isikuandmeid sisaldavat teavet seni, kuni vastav menetlus (kriminaalmenetlus, haldus&otilde;igusrikkumiste menetlus, haldusmenetluse l&otilde;petamine) on edastatud &otilde;iguskaitseorganile v&otilde;i kuni selle l&otilde;plik otsus on tehtud.</p>
            <ol start={4}>
                <li><strong> Millistel juhtudel ja millistele isikuandmete saajatele me Teie andmeid avaldame?</strong></li>
            </ol>
            <p>Me v&otilde;ime edastada Teie isikuandmeid t&ouml;&ouml;tlemiseks isikuandmete saajatele, st isikutele, kes aitavad meid teenuste osutamisel ja haldamisel ning osutavad meile kliendip&auml;ringute haldamisega seotud teenuseid.&nbsp; Sellisteks isikuteks v&otilde;ivad olla IT-andmebaasi tarkvara tarnijad, andmebaaside haldusteenuste pakkujad, andmekeskuste, hooldus- ja pilveteenuste pakkujad, turundusteenuste pakkujad, turu-uuringute v&otilde;i &auml;rianal&uuml;&uuml;tika teenuste pakkujad jne. Igal juhul edastame andmet&ouml;&ouml;tlejatele ainult nii palju andmeid, kui on vaja konkreetse &uuml;lesande t&auml;itmiseks v&otilde;i konkreetse teenuse osutamiseks. Meie kaasatud andmet&ouml;&ouml;tlejad v&otilde;ivad Teie isikuandmeid t&ouml;&ouml;delda ainult meie juhiste kohaselt ning ei saa neid kasutada muul eesm&auml;rgil ega edastada neid teistele isikutele ilma meie n&otilde;usolekuta. Lisaks peavad nad tagama Teie andmete kaitse vastavalt kehtivatele &otilde;igusaktidele ja meiega s&otilde;lmitud kirjalikule kokkuleppele.</p>
            <p>Kui teenuseid osutades anname Teile &otilde;iguse kasutada meie partnerite teenuseid (nt partnerite p&uuml;sikliendiprogrammide eeliseid), on m&otilde;ned Teie andmetest k&auml;ttesaadavad ka meie koost&ouml;&ouml;partneritele, kuid ainult selliste &uuml;ldiste teenuste osutamise v&otilde;i muude koost&ouml;&ouml;tingimuste eesm&auml;rgil.</p>
            <p>Kui p&auml;ringus kirjeldatud s&uuml;ndmus loetakse kindlustusjuhtumiks, edastame Teie p&auml;ringu ja andmed kindlustusseltsi(de)le, kelle juures oleme teinud vastutus- ja varakindlustuse v&otilde;i mis pakub meile muud kindlustuskaitset seoses Teie p&auml;ringus kirjeldatud s&uuml;ndmusega. Kindlustusseltsid tegutsevad s&otilde;ltumatute v&otilde;i eraldi vastutavate t&ouml;&ouml;tlejatena ja t&ouml;&ouml;tlevad Teie andmeid vastavalt kindlustusseltsi tingimustele ja reeglitele.</p>
            <p>Andmeid v&otilde;ib avaldada ka p&auml;devatele v&otilde;i j&auml;relevalvet teostatavatele riigi, omavalitsuse v&otilde;i &otilde;iguskaitseasutustele, n&auml;iteks politsei, prokuratuur, kohus v&otilde;i kontrolli-/j&auml;relevalveorganitele, kuid ainult nende p&otilde;hjendatud n&otilde;udmisel v&otilde;i ainult siis, kui see on vajalik kehtivate &otilde;igusaktide v&otilde;i &otilde;igusaktides s&auml;testatud juhtude ja korra kohaselt, et tagada riigi, omavalitsuste v&otilde;i &otilde;iguskaitseasutuste ja/v&otilde;i meie &otilde;igusi, meie ostjate, t&ouml;&ouml;tajate ja IT-ressursside turvalisust, esitada ja kaitsta &otilde;iguslikke n&otilde;udeid jne.</p>
            <p>Kui kasutame veebilehe anal&uuml;&uuml;siteenust (nt Google Analytics), mida kasutatakse selleks, et m&otilde;&otilde;ta, kuidas Te veebilehe www.quicktouchmenu.eu v&otilde;i mobiilirakendust sirvite, v&otilde;ime jagada Teie anon&uuml;&uuml;mseid andmeid isikutega, kes sellele teabele tuginevad ja hindavad kuidas veebilehte v&otilde;i mobiilirakendust sirvitakse, et koostada veebilehe v&otilde;i mobiilirakenduse toimivuse kohta aruandeid veebilehe operaatoritele ja pakkuda muid veebilehe, interneti ja mobiilirakenduse kasutamisega seotud teenuseid. Lisateavet selle kohta saate lugeda oma brauseri k&uuml;psiste poliitikast.</p>
            <ol start={5}>
                <li><strong> Millisel territooriumil me Teie isikuandmeid t&ouml;&ouml;tleme?</strong></li>
            </ol>
            <p>Me t&ouml;&ouml;tleme Teie isikuandmeid ainult Euroopa Liidu territooriumil. Me ei kavatse praegu edastada ega edasta Teie isikuandmeid kolmandatesse riikidesse. Juhul, kui kasutame teenuste osutamiseks v&otilde;i suhtlemiseks <a href="http://www.facebook.com/">www.facebook.com</a> v&otilde;i Google Analyticsi teenuseid, peetakse vastavaid teenusepakkujaid kolmandateks isikuteks seoses andmete edastamisega kolmandatele riikidele ning palume Teil tutvuda nende teenusepakkujate privaatsuspoliitikaga.</p>
            <ol start={6}>
                <li><strong> Millised on Teie &otilde;igused ja kuidas saate neid kasutada?</strong></li>
            </ol>
            <p>Andmekaitse &otilde;igusaktid annavad Teile palju &otilde;igusi, mida saate vabalt kasutada ja meie tagame Teile v&otilde;imaluse oma &otilde;iguste kasutamiseks. Me anname teavet Teie konkreetsete &otilde;iguste ja nende kasutamise kohta k&auml;esolevas poliitikas. Palun lugege see t&auml;helepanelikult l&auml;bi:</p>
            <p><strong>6.1. &Otilde;igus p&auml;&auml;seda ligi oma isikuandmetele, mida me haldame</strong></p>
            <p>Teil on &otilde;igus saada meie kinnitust selle kohta, kas me t&ouml;&ouml;tleme Teie isikuandmeid, samuti on Teil &otilde;igus tutvuda meie t&ouml;&ouml;deldavate isikuandmete ning teabega t&ouml;&ouml;tlemise eesm&auml;rkide, t&ouml;&ouml;deldavate isikuandmete liikide, vastuv&otilde;tjate liikide, isikuandmete t&ouml;&ouml;tlemise ajavahemiku, isikuandmete kogumise allikate, automatiseeritud otsuste, sealhulgas profiilianal&uuml;&uuml;si tegemise kohta, samuti selle kohta, milline on sellise t&ouml;&ouml;tlemise t&auml;htsus ja prognoositavad tagaj&auml;rjed Teie jaoks.</p>
            <p>Enamiku sellest teabest anname k&auml;esolevas poliitikas ja loodame, et see on Teile kasulik.</p>
            <p>Kui olete veebisaidi www.quicktouchmenu.eu registreeritud kasutaja, saate igal ajal oma kasutajakontol tutvuda oma isikuandmetega, mida me t&ouml;&ouml;tleme (nt kontrollida Teie poolt esitatud isikuandmete teabega, ajakohastada teavet, Teie antud n&otilde;usolekuid jne) ning Teile v&auml;ljastatud viimase 12 kuu arvetega. Kui oled mobiilirakenduse kasutaja, leiad kogu nimetatud teabe mobiilirakendusest.</p>
            <p>Kui k&auml;esolevas poliitikas, Teie veebisaidi www.quicktouchmenu.eu kasutajakontol v&otilde;i mobiilirakenduses toodud teabest Teile ei piisa v&otilde;i kui soovite saada ostutehingute ajalugu pikema ajavahemiku kohta kui 12 kuud, v&otilde;ite alati meiega &uuml;hendust v&otilde;tta k&auml;esoleva poliitika punktis 8 s&auml;testatud viisidel.</p>
            <p><strong>6.2. &Otilde;igus parandada isikuandmeid</strong></p>
            <p>Kui Teie registreerumise ajal esitatud andmed on muutunud v&otilde;i arvate, et meie t&ouml;&ouml;deldav teave Teie kohta on ebat&auml;pne v&otilde;i vale, on Teil &otilde;igus n&otilde;uda selle teabe muutmist, t&auml;psustamist v&otilde;i parandamist, v&otilde;ttes meiega &uuml;hendust k&auml;esoleva poliitika punktis 8 s&auml;testatud viisidel v&otilde;i</p>
            <p>saate oma andmeid iseseisvalt parandada (uuendada) oma www.quicktouchmenu.eu kontol v&otilde;i mobiilirakenduses.</p>
            <p><strong>6.3. &Otilde;igus n&otilde;usolek tagasi v&otilde;tta</strong></p>
            <p>Kui me t&ouml;&ouml;tleme Teie andmeid Teie n&otilde;usoleku alusel, on teil &otilde;igus oma n&otilde;usolek igal ajal tagasi v&otilde;tta, mis on isikuandmete t&ouml;&ouml;tlemise l&otilde;petamise aluseks.&nbsp; N&otilde;usoleku tagasiv&otilde;tmine ei m&otilde;juta enne tagasiv&otilde;tmist n&otilde;usoleku alusel toimunud t&ouml;&ouml;tlemise seaduslikkust.</p>
            <p>N&auml;iteks v&otilde;ite oma n&otilde;usoleku pakkumiste ja uudiste saamiseks igal ajal tagasi v&otilde;tta, samuti oma n&otilde;usoleku andmete profileerimiseks, mille kohaselt pakume Teile personaalseid pakkumisi. Nende n&otilde;usolekute tagasiv&otilde;tmine ei takista teil teenuste kasutamise j&auml;tkamist ja lepingust tulenevate kohustuste t&auml;itmist, kuid t&auml;hendab, et me ei saa Teile pakkuda ja Te ei saa ajakohaseid pakkumisi ega uudiskirju.</p>
            <p>Te v&otilde;ite oma n&otilde;usolekuid parandada (tagasi v&otilde;tta v&otilde;i uuesti anda), esitades oma antud n&otilde;usolekute kohta uuendatud registreerimisandmed, muutes n&otilde;usolekute tingimusi oma registreeritud kasutajakontol v&otilde;i mobiilirakenduses v&otilde;i v&otilde;ttes meiega &uuml;hendust k&auml;esoleva poliitika punktis 8 s&auml;testatud viisidel.</p>
            <p>Teie n&otilde;usoleku tagasiv&otilde;tmisel l&otilde;petame Teie andmete n&otilde;usoleku alusel t&ouml;&ouml;tlemise ning poliitikas m&auml;rgitud juhtudel kustutame andmed usaldusv&auml;&auml;rselt ja j&auml;&auml;davalt v&otilde;i muudame need anon&uuml;&uuml;mseks.</p>
            <p>Igal juhul v&otilde;ime s&auml;ilitada Teie n&otilde;usolekut ja t&otilde;endeid Teie n&otilde;usoleku kohta pikema aja jooksul, kui see on vajalik selleks, et kaitsta meie &otilde;igustatud huve meie vastu esitatud n&otilde;udmiste, pretensioonide v&otilde;i hagide korral.</p>
            <p><strong>6.4. &Otilde;igus esitada kaebus</strong></p>
            <p>Kui arvate, et rikume Teie andmeid t&ouml;&ouml;deldes isikuandmete t&ouml;&ouml;tlemise/kaitse &otilde;igusaktide n&otilde;udeid, palume k&otilde;igepealt p&ouml;&ouml;rduda meie poole.&nbsp; Usume, et suudame hajutada k&otilde;ik Teie kahtlused, rahuldada teie taotlused ja parandada meie vigu, kui neid esineb.</p>
            <p>Kui Te ei ole rahul meie pakutud viisidega probleemi lahendamiseks v&otilde;i Teie arvates ei tee me Teie n&otilde;udmisel vajalikke toiminguid, on Teil &otilde;igus esitada kaebus j&auml;relevalveasutusele, milleks L&auml;ti Vabariigis on Andmekaitseinspektsioon.</p>
            <p><strong>6.5. &Otilde;igus mitte n&otilde;ustuda andmete t&ouml;&ouml;tlemisega, kui t&ouml;&ouml;tlemine p&otilde;hineb &otilde;igustatud huvidel</strong></p>
            <p>Teil on &otilde;igus esitada vastuv&auml;iteid isikuandmete t&ouml;&ouml;tlemisele, kui isikuandmeid t&ouml;&ouml;deldakse meie &otilde;igustatud huvile tuginedes. V&otilde;ttes siiski arvesse teenuste osutamise eesm&auml;rke ja m&otilde;lema poole (Teie kui andmesubjekti ja meie kui vastutava t&ouml;&ouml;tleja) &otilde;igustatud huvide tasakaalu, v&otilde;ivad Teie vastuv&auml;ited t&auml;hendada, et kui katkestate meie &otilde;igustatud huvil p&otilde;hineva andmete t&ouml;&ouml;tlemise, ei saa me v&otilde;imaldada Teil j&auml;tkata teenuste kasutamist ning s&otilde;lmitud leping peatatakse ja l&otilde;petatakse.</p>
            <p>Kui soovite kasutada k&auml;esolevas jaotises s&auml;testatud &otilde;igusi, esitage taotlus kirjalikult meie andmekaitseametnikule, kelle kontaktandmed on esitatud k&auml;esoleva poliitika punktis 8.</p>
            <p><strong>6.6. &Otilde;igus andmete kustutamisele (&otilde;igus olla unustatud)</strong></p>
            <p>Andmete t&ouml;&ouml;tlemist k&auml;sitlevates &otilde;igusaktides kirjeldatud teatud asjaoludel (n&auml;iteks kui isikuandmeid t&ouml;&ouml;deldakse ebaseaduslikult, puudub andmete t&ouml;&ouml;tlemise &otilde;iguslik alus, isikuandmed ei ole enam vajalikud eesm&auml;rkidel, milleks neid koguti v&otilde;i muul viisil t&ouml;&ouml;deldi jne) on Teil &otilde;igus n&otilde;uda, et me kustutaksime Teie isikuandmed. Kui soovite seda &otilde;igust kasutada, esitage palun kirjalik taotlus meie andmekaitseametnikule, kelle kontaktandmed on esitatud k&auml;esoleva poliitika punktis 8.</p>
            <p>Oluline on m&auml;rkida, et Teie www.quicktouchmenu.eu veebisaidi kontol registreeritud kasutaja andmed kustutatakse ilma Teie eraldi taotluseta ning muud andmed kustutatakse v&otilde;i muudetakse turvaliselt anon&uuml;&uuml;mseks, kui l&otilde;petate teenuste kasutamise www.quicktouchmenu.eu veebisaidil ja kui Teie viimasest tegevusest www.quicktouchmenu.eu registreeritud kasutajana on m&ouml;&ouml;dunud 2 (kaks) aastat.</p>
            <p><strong>6.7. &Otilde;igus piirata andmete t&ouml;&ouml;tlemist</strong></p>
            <p>Teatud andmet&ouml;&ouml;tlust k&auml;sitlevates &otilde;igusaktides s&auml;testatud asjaoludel (kui isikuandmeid t&ouml;&ouml;deldakse ebaseaduslikult, Te vaidlustate andmete t&auml;psuse, Te esitate vastuv&auml;iteid andmete t&ouml;&ouml;tlemisele meie &otilde;igustatud huvide tagamiseks jne) on Teil &otilde;igus piirata Teie isikuandmete t&ouml;&ouml;tlemist. Tuleb siiski meeles pidada, et andmete t&ouml;&ouml;tlemise piiramise t&otilde;ttu ja piiranguperioodi jooksul ei pruugi me olla v&otilde;imelised Teile teenuseid osutama ega lepingust tulenevaid kohustusi t&auml;itma.</p>
            <p>Kui soovite kasutada k&auml;esolevas jaotises s&auml;testatud &otilde;igusi, esitage taotlus kirjalikult meie andmekaitseametnikule, kelle kontaktandmed on esitatud k&auml;esoleva poliitika punktis 8.</p>
            <p><strong>6.8. &Otilde;igus andmete &uuml;lekandmisele</strong></p>
            <p>Teil on &otilde;igus edastada andmed, mida me t&ouml;&ouml;tleme Teie n&otilde;usoleku alusel ja mille t&ouml;&ouml;tlemine toimub automatiseeritult, teisele vastutavale t&ouml;&ouml;tlejale.&nbsp; Me edastame Teile isikuandmed, mida soovite &uuml;le kanda, meie s&uuml;steemis kasutatavas lihtsas arvutiga loetavas vormis, kuid kui soovite ja kui see on tehniliselt v&otilde;imalik, edastame andmed otse teisele Teie poolt m&auml;&auml;ratud vastutavale t&ouml;&ouml;tlejale.</p>
            <p>Kui soovite kasutada oma &otilde;igust andmete &uuml;lekandmisele, esitage palun kirjalik taotlus meie andmekaitseametnikule, kelle kontaktandmed on esitatud k&auml;esoleva poliitika punktis 8.</p>
            <ol start={7}>
                <li><strong> Kuidas me Teie taotlusi menetleme ja t&ouml;&ouml;tleme?</strong></li>
            </ol>
            <p>Selleks, et kaitsta meie ostjate andmeid ebaseadusliku avaldamise eest, peame veenduma Teie isikusamasuses, kui saame Teilt taotluse isikuandmete esitamise v&otilde;i muude Teie &otilde;iguste teostamise kohta. Selleks v&otilde;ime paluda Teil esitada meile oma praegused andmed (n&auml;iteks eesnimi, perekonnanimi, aadress, e-posti aadress v&otilde;i telefoninumber), mis on registreeritud teie www.quicktouchmenu.eu kasutajakontol v&otilde;i mobiilirakenduse kasutajaprofiilis, ning seej&auml;rel kontrollime, kas Teie esitatud andmed &uuml;htivad registreeritud kasutaja vastavate andmetega.&nbsp; Seda tehes v&otilde;ime saata ka kontrollteatise registreerimisvormis toodud sidevahenditele (tekstis&otilde;numina v&otilde;i e-posti teel), milles taotleme autoriseerimist. Kui kontrollimiprotseduur ei &otilde;nnestu (n&auml;iteks kui Teie esitatud andmed ei &uuml;hti veebisaidil www.quicktouchmenu.eu v&otilde;i mobiilirakenduses registreeritud kasutajaprofiilis esitatud andmetele v&otilde;i kui Te ei logi sisse p&auml;rast tekstis&otilde;numi v&otilde;i e-posti teate saamist), oleme sunnitud nentima, et Te ei ole taotletud andmete subjekt ja oleme sunnitud Teie esitatud taotluse tagasi l&uuml;kkama.</p>
            <p>P&auml;rast Teie taotluse saamist oma &otilde;iguste teostamiseks ja eelnimetatud kontrolliprotseduuri edukat l&otilde;petamist kohustume viivitamatult, kuid igal juhul mitte hiljem kui &uuml;he kuu jooksul alates Teie taotluse saamisest ja kontrolliprotseduuri l&otilde;petamisest, esitama Teile teabe vastavalt teie taotlusele. V&otilde;ttes arvesse taotluste keerulisust ja arvu, on meil &otilde;igus &uuml;he kuu jooksul pikendada seda aega veel kahe kuuni, teavitades Teid enne esimese kuu l&otilde;ppu ja nimetades sellise pikendamise p&otilde;hjused.</p>
            <p>Kui Teie taotlus esitatakse elektrooniliste vahendite kaudu, esitame vastuse Teile elektrooniliste vahendite kaudu, v&auml;lja arvatud juhul, kui see ei ole v&otilde;imalik (n&auml;iteks suure andmehulga t&otilde;ttu) v&otilde;i kui Te palute meil vastata muul viisil.</p>
            <p>Me keeldume vastamast Teie taotlusele motiveeritud vastusega, kui tuvastatakse &otilde;igusaktides nimetatud vastavad asjaolud, teavitades Teid sellest kirjalikult.</p>
            <ol start={8}>
                <li><strong> Kuidas saate meiega &uuml;hendust v&otilde;tta?</strong></li>
            </ol>
            <ul>
                <li>e-posti vahendusel, kirjutades aadressil: rolands@elaw.ee</li>
                <li>helistades meie k&otilde;nekeskusesse numbril: +37126488822</li>
            </ul>
            <p>Andmekaitseametniku kontaktandmed:</p>
            <ul>
                <li>e-posti aadress: rolands@elaw.ee,</li>
                <li>postiaadress: Laki tn 30, Mustam&auml;e linnaosa, Tallinn Harju maakond 12915, Eesti, Kirja saaja: AuraTrust O&Uuml; andmekaitseametnik</li>
            </ul>
            <ol start={9}>
                <li><strong> Kui turvalised on Teie andmed?</strong></li>
            </ol>
            <p>Me austame Teie privaatsust, seega on Teie isikuandmete turvalisus meie prioriteet. Kasutame asjakohaseid organisatsioonilisi ja tehnilisi meetmeid, et tagada Teie isikuandmete pidev turvalisus ning andmet&ouml;&ouml;tluse vastavus isikuandmete kaitset k&auml;sitlevate &otilde;igusaktide ja meie sisepoliitika n&otilde;uetele.</p>
            <p>Me j&auml;rgime j&auml;rjekindlalt isikuandmete minimeerimise p&otilde;him&otilde;tet ega kogu andmeid, mida me ei vaja k&auml;esolevas poliitikas s&auml;testatud eesm&auml;rkide saavutamiseks.</p>
            <p>Kasutame erinevaid isikuandmete kaitse- ja turvatehnoloogiaid ning -menetlusi, et kaitsta Teie isikuandmeid ebaseadusliku juurdep&auml;&auml;su, kasutamise v&otilde;i avalikustamise eest. Meie koost&ouml;&ouml;partnerid on hoolikalt valitud ja me n&otilde;uame neilt, et nad kasutaksid asjakohaseid vahendeid isikuandmete konfidentsiaalsuse kaitsmiseks ja Teie isikuandmete turvalisuse tagamiseks. Siiski ei ole v&otilde;imalik tagada, et Teie andmete edastamine meile interneti v&otilde;i mobiilside kaudu oleks t&auml;iesti turvaline. Teie andmete edastamine meile eespool nimetatud viisidel toimub Teie enda vastutusel.</p>
            <ol start={10}>
                <li><strong> K&uuml;psised</strong></li>
            </ol>
            <p>Me kasutame k&uuml;psiseid (mis on v&auml;ikesed tekstifailid, mis saadetakse teie arvutisse v&otilde;i muusse seadmesse (nt mobiiltelefon), kui k&uuml;lastate meie veebilehte, ja mis salvestatakse teie brauserisse). K&uuml;psis saadetakse Teie arvutisse v&otilde;i muusse seadmesse eesm&auml;rgiga salvestada andmeid, et me saaksime Teid meie veebilehe k&uuml;lastamisel teenuste kasutajana &auml;ra tunda. K&uuml;psiste abil saame me siduda Teie ostuajalugu ja muid teenuste kasutamise k&auml;igus kogutud andmeid Teie veebisirvimisega. K&uuml;psiste abil kogutud teave v&otilde;imaldab meil pakkuda Teile mugavamat sirvimiskogemust, atraktiivseid pakkumisi ja saada rohkem teavet meie veebilehe kasutajate k&auml;itumise kohta, anal&uuml;&uuml;sida suundumusi ja t&auml;iustada nii veebilehte kui ka Teie teenindamist.</p>
            <p>Meie veebileht kasutab ka kolmandate osapoolte k&uuml;psiseid. Neid kasutatakse iga k&uuml;lastaja sirvimisajaloo loomiseks eesm&auml;rgiga n&auml;idata Teile suunatud reklaami ja tagada parim kogemus meie veebilehe k&uuml;lastamisel. Kui teie brauser lubab kolmandate osapoolte k&uuml;psiste salvestamist, on meie valitud partneril v&otilde;imalus salvestada Teie brauseris oma k&uuml;psised.</p>
            <p>Lisateavet k&uuml;psiste, nende kasutamise ja nendest loobumise kohta leiate brauseri k&uuml;psiste poliitikast.</p>
            <ol start={11}>
                <li><strong> Poliitika kehtivus ja muudatused</strong></li>
            </ol>
            <p>AuraTrust O&Uuml; veebisaidi www.quicktouchmenu.eu privaatsuspoliitikat v&otilde;idakse muuta v&otilde;i uuendada. K&auml;esoleva privaatsuspoliitika k&otilde;ige ajakohasem versioon on alati leitav aadressil www.quicktouchmenu.eu.</p>
            <ol start={12}>
                <li><strong> Poliitika kohaldamine</strong></li>
            </ol>
            <p>Poliitikat ei kohaldata meie v&otilde;i meie kontserni ettev&otilde;tete poolt pakutavatele muudele teenustele.</p>
        </>
    );
};

export default PolicyEt;