import {FC} from 'react';
import { useTranslation } from 'react-i18next';
import LayoutTemplate from "../../templates/LayoutTemplate";
import PolicyEn from "./lang/PolicyEn";
import PolicyDe from "./lang/PolicyDe";
import PolicyLv from "./lang/PolicyLv";
import PolicyEt from "./lang/PolicyEt";
import PolicyLt from "./lang/PolicyLt";

const Policy = () => {
    const { i18n } = useTranslation()
    const PolicyComponent:FC = () => {
        switch (i18n.language) {
            case 'en':
                return <PolicyEn />;
            case 'de':
                return <PolicyDe />;
            case 'lv':
                return <PolicyLv />;
            case 'et':
                return <PolicyEt />;
            case 'lt':
                return <PolicyLt />;
            default:
                return null;
        }
    };
    return (
        <LayoutTemplate>
            <section className="policy">
                <div className="container">
                    <div className="policy__wrapper">
                        <PolicyComponent/>
                    </div>
                </div>
            </section>
        </LayoutTemplate>
    );
};

export default Policy;