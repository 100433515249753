import {FC, ReactNode} from 'react';
import './List.scss'
interface IList {
    items:ReactNode[];
    className?: string;
}

const List:FC<IList> = ({items,className}) => {
    return (
        <ul className={`list ${className ? className : ''}`}>
            {items.length > 0 ? items.map((item,index)=><li className={'list__item'} key={index} >{item}</li>): ''}
        </ul>
    );
};

export default List;