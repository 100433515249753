import { FC, useEffect, useRef } from "react";
import "./CustomizeSection.scss";
import qtw_change_brand_colors from "../../../assets/images/qtw_change_brand_colors.svg";
import qtw_activate_functions from "../../../assets/images/qtw_activate_functions.svg";

import { useTranslation, Trans } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useDispatchEx } from "../../../hooks/redux";
import { anime1Finish, anime1Start } from "../../../helpers/animations";
const CustomizeSection: FC = () => {
  gsap.registerPlugin(ScrollTrigger);

  const targetSection = useRef(null);
  useEffect(() => {
    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: targetSection.current,
        start: "+=20% bottom",
      },
    });

    timeline.fromTo(
      ".customize-section__header",
      anime1Start,
      anime1Finish,
      0.1,
    );
    timeline.fromTo(
      ".customize-section__item",
      anime1Start,
      anime1Finish,
      0.4,
    );
  }, [targetSection]);
  const { t } = useTranslation();
  const dispatch = useDispatchEx();
  return (
    <section className={"customize-section"} ref={targetSection}>
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <div className="customize-section__header">
              <h2 className="h2">
                <span>CUSTOMIZE</span> your online restaurant as you need
              </h2>
            </div>
            <div className="col-lg-6">
              <div className="customize-section__item">
                <img src={qtw_change_brand_colors} alt="Change brand colors" />
                <span className="customize-section__text">
                  Change brand colors
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="customize-section__item">
                <span className="customize-section__text">
                  Activating & deactivating the required functions at the right
                  moment
                </span>
                <img
                  src={qtw_activate_functions}
                  alt="Activating & deactivating the required functions at the right
                  moment"
                  className="customize-section__second-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomizeSection;
