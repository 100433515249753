import './QuestionsSection.scss';
import {FC, useEffect, useRef, useState} from 'react';
import Accordion from '../../atoms/Accordion/Accordion';
import {Trans, useTranslation} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {anime1Finish, anime1Start} from "../../../helpers/animations";

const QuestionsSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);
    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: '+=20% bottom'
            }
        });
            const UpStart = {y:50,opacity:0,duration: 0.3, stagger: 0.2};
            const InFinish = {y:0,opacity:1,duration: 0.3, stagger: 0.2};
            timeline.fromTo(".questions-section__header", UpStart, InFinish, 0.1)
            timeline.fromTo(".questions-section__description", UpStart, InFinish, 0.4)
            timeline.fromTo(".questions-section__list .accordion", UpStart, InFinish, 0.7);
    },[refTarget]);


    const { t } = useTranslation();
    const list:string[] = t('questions.items',{returnObjects:true});
    return (
        <section className={'questions-section'} ref={refTarget}>
            <div className="container">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <div className="questions-section__inner">
                                <div className="questions-section__header">
                                    <h2 className="h2">
                                        <Trans i18nKey="questions.header"/>
                                    </h2>
                                </div>
                                <div className="questions-section__description"><Trans i18nKey="questions.description"/></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="questions-section__wrapper">
                                <div className="questions-section__list ">
                                    {list.length > 0 ? list.map((item:any,index)=>{
                                        return <Accordion key={index} header={<Trans i18nKey={item.header}/>}>
                                            <Trans i18nKey={item.content}/>
                                        </Accordion>
                                    }): ''}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
};

export default QuestionsSection;