import {FC, ReactNode, useState} from 'react';
import './Accordion.scss';

interface IAccordion {
    header:ReactNode;
    children?:ReactNode;
    className?: string
}

const Accordion:FC<IAccordion> = ({header,children, className}) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={`accordion ${open ? 'active' : ''} ${className ? className : ''}`}>
            <div className="accordion__name" onClick={()=>setOpen(!open)}>
                {header}
            </div>

            <div className="accordion__answer">
                <div className="accordion__answer-wrapper">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Accordion;