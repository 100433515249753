import { FC, useEffect, useRef } from "react";
import "./MenuSection.scss";
import qtw_online_menu_power_showcase from "../../../assets/images/qtw_online_menu_power_showcase.svg";
import qtw_online_menu_power_second_example from "../../../assets/images/qtw_online_menu_power_second_example.svg";

import { useTranslation, Trans } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useDispatchEx } from "../../../hooks/redux";
import { anime1Finish, anime1Start } from "../../../helpers/animations";

const MenuSection: FC = () => {
  gsap.registerPlugin(ScrollTrigger);

  const targetSection = useRef(null);
  useEffect(() => {
    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: targetSection.current,
        start: "+=20% bottom",
      },
    });
    timeline.fromTo(".online-section-title", anime1Start, anime1Finish, 0.1);
    timeline.fromTo(".online-section-item", anime1Start, anime1Finish, 0.4);
  }, [targetSection]);
  const { t } = useTranslation();
  const dispatch = useDispatchEx();
  return (
    <section className={"online-section"} ref={targetSection}>
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <h2 className="online-section-title">
              The Power of <span>Online Menu</span>
            </h2>

            <span className="online-section-description">
              Comprehensive online menu and webpage builder, tailored to your
              needs
            </span>

            <div className="online-section-items">
              <div className="online-section-item">
                <img src={qtw_online_menu_power_showcase} alt="" />
                <span className="online-section-item__text">
                  Showcase your restaurant digitally, making It easier for
                  potential customers to discover your preferences, at no extra
                  costs
                </span>
              </div>
              <div className="online-section-item">
                <img src={qtw_online_menu_power_second_example} alt="" />
                <span className="online-section-item__text">
                  Instant editing, stop lists, allergens, recommendations, AI
                  language translations.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MenuSection;
