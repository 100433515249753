import React from 'react';
import {Route, Routes} from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import Policy from "./components/pages/Policy/Policy";
import ContactsPage from "./components/pages/ContactPage";

function App() {
  return (
      <Routes>
        <Route path="/" element={ <HomePage />}/>
        <Route path="/policy" element={ <Policy />}/>
          <Route path="/roland" element={ <ContactsPage />}/>
        <Route path="*" element={<HomePage/>} />
      </Routes>
  );
}

export default App;
