import {FC, useEffect, useState} from 'react';
import './Footer.scss';
import {Trans, useTranslation} from "react-i18next";
import {useDispatchEx} from "../../../hooks/redux";
import Social from "../../molecules/Social/Social";
import {changeModalFeedback} from "../../../redux/slices/appSlice";
import MailIcon from "../../icons/feedback/MailIcon";
import CallIcon from "../../icons/feedback/CallIcon";
import WhatsIcon from "../../icons/feedback/WhatsIcon";
import TelegramIcon from "../../icons/feedback/TelegramIcon";
import LogoTextIcon from "../../icons/LogoTextIcon";
import iosImg from "../../../assets/images/ios.png";
import googleImg from "../../../assets/images/android.png";

const Footer:FC = () => {

    const dispatch = useDispatchEx();
    const { t } = useTranslation();
    const listOne:string[] = t('footer.menu.0',{returnObjects:true});
    return (
        <footer className={'footer'} >
            <div id={'download'} className="footer__info">
                <div className="container">
                    <div className="footer__info-wrapper">
                        <div className="footer__info-feed">
                            <div className="footer__info-feed-wrapper">
                                <div className="footer__logo">
                                    <LogoTextIcon/>
                                </div>
                                <div className="footer__feedback ">
                                    <a className={'footer__feedback-item'} target={"_blank"} href={"mailto:support@quicktouchwaiter.com"}><MailIcon width={40} height={41}/></a>
                                    <a className={'footer__feedback-item'} target={"_blank"} href={'https://wa.me/message/LNVLQBAFJGZED1'}><WhatsIcon/></a>
                                    <a className={'footer__feedback-item'} target={"_blank"} href={'https://t.me/quicktouchmenu'}><TelegramIcon/></a>
                                    <a className={'footer__feedback-item'} target={"_blank"} href={'tel:+37128639929'}><CallIcon/></a>
                                </div>
                                <div className="footer__download">
                                    <a target={'_blank'} href="https://apps.apple.com/lv/app/qtm-waiter/id6449952843"><img src={iosImg} alt=""/></a>
                                    <a target={'_blank'} href="https://play.google.com/store/apps/details?id=com.qtmwaiter&pcampaignid=web_share&pli=1"><img src={googleImg} alt=""/></a>
                                </div>
                            </div>

                        </div>
                        <div className="footer__info-contacts">
                            <div className="footer__info-title">Contacts</div>
                            <div className="footer__info-list">
                                <ul>
                                    <li><a className={'link-line--reverse'} href="mailto:support@quicktouchwaiter.com">support@quicktouchwaiter.com</a></li>
                                    <li><a className={'link-line'} href="tel:+37128639929">+37128639929</a></li>
                                    <li>SIA “QTM SYSTEMS ”, reģ. Nr. 40203396038, Stabu iela 78A–3, Rīga, LV-1009</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="footer__info-copy">
                <div className="container">
                    <div className="footer__info-copy-wrapper">
                        <div className="footer__social">
                            <Social/>
                        </div>
                        <div className="footer__info-copy-logo">
                            Just cook <span>it</span>
                        </div>
                    </div>
                    <div className="footer__copy">
                        2021-2024 © Quick Touch Waiter
                    </div>
                </div>

            </div>
        </footer>
    );
};

export default Footer;