import React from 'react';

const PolicyEn = () => {
    return (
        <>
            <p><strong>Privacy Policy</strong></p>
            <p>By registering on the website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> or in the mobile application, you entrust your personal data to AuraTrust O&Uuml; (registration number in the Republic of Estonia 16562585, legal address registered at Ravi tn 2, 10134, Tallinn, Estonia, as the Controller of data processing (hereinafter referred to as&nbsp;&ndash; the Company or us, or the Controller) and grant us the right to process such data in accordance with this Policy (hereinafter referred to as&nbsp;&ndash; the Policy) and within the framework of downloading and using mobile applications and in connection with registration and joining, as well as provision of services, in accordance with the relevant terms of use (hereinafter referred to as&nbsp;&ndash; the Terms). We inform you that we are only implementing the processing of your personal data, if you choose to provide us with your personal data.</p>
            <p>By implementing Regulation (EU) 2016/679 of the European Parliament and of the Council of 27&nbsp;April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation), we provide you with a particularly transparent and fair processing of your personal data, and we take care of your privacy and the security of your personal data.</p>
            <p>In this Policy you will find all the information about what data types we collect and process, what we use them for, how long we store them, and other information. This information is important, therefore we encourage you to read this Policy carefully before providing us information about your personal data.</p>
            <p>We would also like to address your attention to the fact that both the Policy and the Terms and Conditions may be changed, supplemented, and updated.</p>
            <p>If you do not agree to the Terms and Conditions, this Policy or its separate regulations, unfortunately we will not be able to provide you the opportunity to use all or any of our services (hereinafter referred to as&nbsp;&ndash; the Services).</p>
            <ol>
                <li><strong> What is personal data and what types of personal data do we process and what for?</strong></li>
            </ol>
            <p><strong>Registration</strong></p>
            <p><strong>Personal data is any information relating to an identified or identifiable natural person (&ldquo;data subject&rdquo;); an identifiable natural person is a person, who can be directly or indirectly identified, in particular by reference to an identifier such as the person&rsquo;s name, identification number, location data, online identifier or one or more physical, physiological, genetic, spiritual, economic, cultural or social identity factors.</strong></p>
            <p>By registering at the website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> or in the mobile application, you have to agree to the Terms of Use of the website or mobile application (have to agree to the Agreement). The submission of your personal data is mandatory, because without submitting them, you will not be able to enter into the Agreement and to use the Services provided by us. By registering, you enter into the Agreement and confirm that the personal data provided by you is accurate and correct and that you are not under the age of 18 years. We bear no responsibility for the provision of inaccurate, incomplete or erroneous personal data submitted by you. In case you have provided data of another person, in case of claims AuraTrust O&Uuml; reserves the right to apply against you with a recourse claim.</p>
            <p><strong>Data required for the Agreement:</strong></p>
            <ul>
                <li>Data categories&nbsp;&ndash; Your name, surname, address, email address and phone number.</li>
                <li>Legal basis for data processing&nbsp;&ndash; for the signing and performance of the Agreement of which you are the contractual party, as well as for the protection of our legitimate interests.</li>
                <li>The period of data processing&nbsp;&ndash; the entire period, while the Agreement is in force and while you are using our Services. We may also store the Agreement signed with you and evidence of its signing for a longer period of time, as long as the claims that may arise from the contractual obligations expire, and, if required, to protect our legitimate interests in case of claims, complaints or requirements submitted against us.</li>
            </ul>
            <p>According to the personal data you provide, we recognize you when, for example, you want to update or change your data, contact us regarding the submission of the relevant personal information, the exercise of rights related to the processing of personal data, etc. According to the personal data submitted by you, by registering at the<a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> website and/or mobile application, after signing the Agreement, a unique user profile (account) is created, according to which we will identify you as registered at the website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> and/or as the user of mobile application, which will provide you the opportunity to use the Services available to the registered users in accordance with the terms and conditions of the Agreement and the provisions of this Policy.</p>
            <p>We may use the contact information provided by you (e-mail, phone number) to communicate with you to fulfil the obligations under the Agreement, including to respond to your requests, claims, to provide important information about the Services, their provision and/or changes to this Policy, to contact you, if you have forgotten your ordered purchases, or if we state problems in fulfilling the obligations of the Agreement related to the implemented purchase transactions, etc.</p>
            <p>It is very important that the personal data you provide is accurate and correct. If you provide incorrect (false) personal data, forget the data or do not update it, when your personal data changes, we may not ensure the fulfilment of the obligations under the Agreement and the provision of the Services to you, as well as other problems may arise in exercising your rights. We bear no responsibility for any damages you may suffer, if you have provided incorrect or imprecise personal data.</p>
            <p>If the data submitted by you changes, you must immediately inform us about it by changing the relevant personal data in the profile of your registered user at the website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> or in the mobile application. Information, on how to update your personal data in case it changes, is provided in Section&nbsp;6.2 of this Policy.</p>
            <p>The Company is not able to verify the accuracy and truthfulness of the personal data you provide, if you do not provide it to us. By accepting your registration and by signing an Agreement with us, we consider that the personal data submitted by you is accurate and correct.</p>
            <p><strong>1.2. Administration of your account</strong></p>
            <p>We process your personal data in order to monitor and to administer your account.</p>
            <p><strong>Account details:</strong></p>
            <p>Data categories&nbsp;&ndash; data provided during registration (on the website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a>), data of connecting to the account, activities in the account, including technical browsing data (IP address, connection and technical information of browsing).</p>
            <p>Legal basis for data processing&nbsp;&ndash; the Agreement you have signed and the created account, fulfilment of the obligations of the Agreement, as well as the protection of our legitimate interests.</p>
            <p>The period of data processing&nbsp;&ndash; for as long as you are the account user. We may also keep evidence of the signing of the agreement for a longer period of time, if this is required in order to be able to defend ourselves in case of claims, complaints or objections made against us.</p>
            <p>We consider your registration for using the account at the website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> as received, when you perform the actions required for the creation of the account specified in the Terms and Conditions and confirm the registration.</p>
            <p><strong>1.3. Processing of your purchase data</strong></p>
            <p><strong>Processing of purchase data</strong></p>
            <p>When providing you Services and various benefits, at the same time with your registration data, we process data of your purchase operations (hereinafter referred to as&nbsp;&ndash; the purchase data).</p>
            <p><strong>Your purchase data to be processed for the purpose of Service administration:</strong></p>
            <p>Data categories&nbsp;&ndash; your name, surname, e-mail address, phone number, address, product names, quantities, purchase prices and discounts granted, purchase payment method and payment information.</p>
            <p>Legal basis for data processing&nbsp;&ndash; the Agreement you have signed and the created account, fulfilment of the obligations of the Agreement, as well as the protection of our legitimate interests and the provision of our legal obligations that may arise and are applicable to the Company.</p>
            <p>The period of data processing&nbsp;&ndash; 5&nbsp;years after the date of implementing the purchase operation. We may store the documents for a longer period of time, as long as the claims that may arise from the contractual obligations expire, and, if required, to protect our legitimate interests in case of claims, complaints or requirements submitted against us.</p>
            <p><strong>1.4. Data profiling, when administering the provision of Services</strong></p>
            <p>When administering the provision of Services and seeking to achieve the objectives set forth in the Terms and Conditions, we may use automated analysis of personal data (including your purchase data) and automated decision making, including your profiling. We may group and analyse your data according to the goods you have purchased and (or) take into account other characteristics specific to you (e.g., name, address, etc.).</p>
            <p>For example, we can analyse and profile your purchase data for your benefit by correcting errors in our operations. If we notice that due to a mistake the wrong item price or discount has been applied to our customers, we can identify the buyers, who have bought such products according to the purchase data and correct our mistake (compensate for the price difference, etc.).</p>
            <p>It is important to emphasize that our analysis and profiling of your data does not affect you legally or in any other way.</p>
            <p>However, if you object to the automated analysis and profiling of your data for the purposes set out in this section of the Policy, we would accept and take your objections into account. However, due to the requirements for the provision of the Services, your objection may mean that we will not be able to provide you the opportunity to use our Services.</p>
            <p><strong>1.5. Provision of offers and information</strong></p>
            <p>If, when registering at the website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> or in the mobile application, you have agreed to receive offers and/or current offers in your e-mail or mobile application or in the form of SMS news to your phone number from us, we process your personal data, providing you with such offers and information, for example, general newsletters, information about offers, discounts, promotions, and sale campaigns provided by us and our partners, invite you to participate in promotions, lotteries and to win prizes, ask your opinion about the services and products provided, etc. We provide offers and information in your account and mobile application, as well as in another communication channels of your choice, for example, by e-mail, via SMS messages to the phone number provided by you.</p>
            <p><strong>Personal data to be processed for the purpose of providing offers and information:</strong></p>
            <p>Data categories:&nbsp;Your name, email address and (or) phone number.</p>
            <p>Legal basis for data processing:&nbsp;Your consent to receive offers and information about current news, as well as the protection of our legitimate interests.</p>
            <p>The period of data processing: as long as your consent to receive offers and news is in force. We may also keep your provided consent and evidence thereof for a longer period of time, if this is required in order to be able to defend ourselves in case of claims, complaints or objections made against us.</p>
            <p>You can select the desired communication channels in the privacy settings of the website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> in your user account or in the mobile application. You can change these settings at any time without restriction.</p>
            <p>If you do not want us to send you offers and/or newsletters, you may not give us your consent, or if you have given your consent to receive offers and/or newsletters, you may opt out at any time or change your notification methods (channels). Your refusal to receive offers and news will not affect the use of the Services and the fulfilment of the obligations of the concluded Agreement.</p>
            <p><strong>1.6. Data profiling, by providing individual offers</strong></p>
            <p>If, when registering at the website <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> or in the mobile application, you have also chosen to receive individual (personalized) offers, according to your order history, and have given your consent to profiling for such purpose, to get to know you as good as possible, we will process not only your provided data, but we will also additionally analyse and use your purchase data history.</p>
            <p><strong>Personal data to be processed for the purpose of providing individual offers:</strong></p>
            <p>Data categories:&nbsp;Your name, surname, address, e-mail address and phone number, purchase data, history of individual offers offered to you and information on their use.</p>
            <p>Legal basis for data processing:&nbsp;Your consent for your data to be profiled, providing you with individual offers, as well as for the protection of our legitimate interests.</p>
            <p>The period of data processing: as long as your consent to data profiling to send you individual (personalized) offers is in force. We may also keep your provided consent and evidence thereof for a longer period of time, if this is required in order to be able to defend ourselves in case of claims, complaints or objections made against us.</p>
            <p>If you agree that your data is profiled to send you individual (personalized) offers, you acquire the opportunity to receive personalized offers, taking into account your needs, and receive other benefits specifically designed for you. To achieve the above mentioned goal, we use automated data analysis and decision making, including profiling. We group and analyse your data and/or your purchase data and, taking into account your shopping habits and/or other features (e.g., address, specific purchases, etc.) provide you with up-to-date, interesting and useful offers, as well as other information about individual offers.</p>
            <p>For example, by taking into account your shopping history and shopping habits, we can grant discounts to your favourite categories of goods, inform you about the news of the individual assortment, etc.</p>
            <p>If you are not willing that your data is profiled, when sending you individual offers, <b>you may not give us your consent or, if you have given your consent to receive individual offers, you can opt out at any time.</b> In such case your refusal to receive individual offers will not prevent you from using the Services and fulfilling your obligations under the Agreement, but you will no longer be able to receive individual offers and individual information relevant to you.</p>
            <p><strong>1.7. Statistics, market and studying of our buyers&rsquo; behaviour</strong></p>
            <p>We are constantly working to make our product range, the applied promotions and discounts as suitable as possible to our customers, incl., to your needs.</p>
            <p><strong>Data to be processed for statistics, market analyses and studying of the activities of our customers:</strong></p>
            <p>Data categories: address, purchase details.</p>
            <p>Legal basis for data processing:&nbsp;Our legitimate interests are to analyse purchase data in a specific region based on customer addresses and to prepare the reports required for our commercial activities to evaluate our commercial activities and to generate benefits for you as a customer, as well as for the commercial activities of the Company.</p>
            <p>The period of data processing:&nbsp;5&nbsp;calendar years from the moment of placing the order.</p>
            <p>We use <b>automated data analysis</b> to study statistical data, market analyses and customer behaviour, as well as to prepare the reports required for our commercial activities. During the analysis, we use <b>non-personalized data</b> and do not process your name, contact information and other information that personalizes you. Data analysis of studying statistical data, market data and data to be processed for studying the behaviour of our customers (address and purchase data) helps us make important business decisions about the needs of our customers, about creating the right product range, pricing, etc.</p>
            <p>The analysis of this data does not affect your legitimate or similar interests, nor does it affect the further performance of the Services and obligations of the Agreement that you have signed.</p>
            <p><strong>1.8. Servicing of customer requests, complaints, applications and feedback</strong></p>
            <p>We will use your personal data to respond to your inquiries, complaints, requests and to administer your feedback (hereinafter referred to as&nbsp;&ndash; <b>the Request</b>).</p>
            <p><strong>Data to be processed for the purpose of servicing customer requests, complaints, applications and feedback:</strong></p>
            <p>Data categories:&nbsp;</p>
            <ul>
                <li>Identification and contact details provided by you: name, surname, phone number, e-mail address, address, etc.;</li>
                <li>The content of your Request: the case regarding which you are contacting us, its circumstances, date, place, your request, claim or reference, product, other information provided in the Request;</li>
                <li>Other documents and (or) data submitted along with the Request: for example, data on the item purchase&nbsp;&ndash; invoice/bill/bill of lading of the item, photo images of the product;</li>
                <li>Phone call record, if you contact our Customer Service <em>Helpline</em>.</li>
            </ul>
            <p>Legal basis for data processing:&nbsp;Fulfilment of our legal obligations to review and to reply to your requests, as well as our legitimate interests to evaluate your feedback as a customer and to record phone conversations to improve the quality of our operations and services provided to you and to protect our legitimate interests.</p>
            <p>The period of data processing:&nbsp;Up to 12&nbsp;months. We may also store your requests regarding the goods for a longer period of time, as long as the claims that may arise from the contractual obligations regarding the item purchases expire, and, if required, to protect our legitimate interests in case of claims, complaints or requirements submitted against us.</p>
            <p>We use your data only for the purpose of properly and objectively reviewing your Request, providing you with the required information, answering your questions, and for resolving your requests or claims (complaints). We may analyse your feedback to improve our operations and the quality of the services we provide to you, taking into account your opinion and suggestions.</p>
            <p><strong>1.9. How long do we process and store your data?</strong></p>
            <p>We process and store your Request and your data related to it for as long as we are reviewing the Request, provide you with a response and execute the decisions made, as well as for up to 6 (six) months after the Request is resolved, but no less than the following period:</p>
            <ul>
                <li>Regarding requests in electronic e-mail format&nbsp;&ndash; no longer than 6&nbsp;months;</li>
                <li>Complaints&nbsp;&ndash; 12&nbsp;months;</li>
                <li>Records of phone conversations received at the Call Centre&nbsp;&ndash; 14&nbsp;days;</li>
                <li>Feedback ratings (saving of stars)&nbsp;&ndash; no longer than 12&nbsp;months.</li>
            </ul>
            <p>If a legal dispute is initiated or there is a possibility of such a dispute regarding the Request, we may store your data for a longer period until the statutory limitation period for filing a complaint or claim expires and (or) the final decision enters into force.</p>
            <p>Upon expiration of the term of processing and storing your data specified in this Policy, we will destroy the documents containing your personal data independently, or will erase or anonymize your personal data in electronic form reliably and irrevocably, as soon as possible within a reasonable and substantiated time period required for implementing such actions.</p>
            <ol start={2}>
                <li><strong> What sources do we obtain and collect your personal data from?</strong></li>
            </ol>
            <p>We collect and process your personal data in accordance with the following legal substantiation:</p>
            <ul>
                <li>Your consent;</li>
                <li>Our legitimate interests;</li>
                <li>Fulfilment of legal obligations arising and applicable to the Company.</li>
            </ul>
            <p>One or more of the above legal substantiations may apply to the processing of your data.</p>
            <p>We collect almost all of your personal data only from you. By registering at the website www.quicktouchmenu.eu or in the mobile application, you submit data directly to us, but purchase data&nbsp;&ndash; by using the Services.</p>
            <p>We also collect your data directly from you, when you submit a Request in any way you choose: by emailing us an electronic letter and by sending it to us at the email address, by submitting a written request in paper format to us, by calling our Customer Service Call Centre orally, etc.</p>
            <p>When we have to obtain additional information or investigate significant circumstances in order to review your Request in a qualitative and objective manner, we may link your Request data to our existing data and/or data collected during the processing of your Request, such as, we may review purchase transaction data, your account usage history, interview our employees, etc.</p>
            <ol start={3}>
                <li><strong> How do we store and destroy data?</strong></li>
            </ol>
            <p>At the end of the period of processing and storing your data that is provided in this Policy (except as provided in case below), we will destroy your data in a secure manner and, in the cases provided in the Policy, reliably and irrevocably anonymise them as soon as possible within a reasonable and substantiated period of time that is required to implement such actions.</p>
            <p>Your personal data may only be stored for longer than specified in this Policy, if any of the following conditions exist:</p>
            <ul>
                <li>it is required for us to be able to defend ourselves against claims, objections or complaints and to be able to exercise our rights;</li>
                <li>the term of storing personal data is determined or results from the regulatory enactments of the Republic of Latvia and the European Union;</li>
                <li>there are reasonable grounds for suspecting unlawful actions, as a result of which investigation is being conducted;</li>
                <li>your data is required for adequate reviewing of a dispute or complaint;</li>
                <li>it is required to provide a backup copy and other similar purposes;</li>
                <li>in the presence of other grounds provided for by law.</li>
            </ul>
            <p>In case such circumstances have occurred, the information containing personal data will be stored until the transfer of the relevant proceedings (criminal proceedings, proceedings of administrative violations, completion of administrative proceedings) to the law enforcement authority or until its final decision is adopted.</p>
            <ol start={4}>
                <li><strong> In what cases do we disclose your data and to what recipients of personal data?</strong></li>
            </ol>
            <p>We may transfer your data to the recipients of personal data, i.e., to persons, who help us provide and administer the Service provision, provides us the services related to the administration of customer requests. Such persons may include suppliers of IT database software, providers of database administration services, data centres, providers of maintenance and cloud services, providers of direct marketing services, providers of market research or business analytics services, etc. In any case, we provide data processors with only as much data as is required to perform a specific task or to provide a specific service. Our data controllers may process your personal data only in accordance with our instructions and may not use such data for other purposes or transfer it to other persons without our consent. In addition they must ensure the protection of your data in accordance with the applicable law and in accordance with the written agreement signed with us.</p>
            <p>If, when providing the Services, we grant you the right to use the services of our partners (e.g., the benefits of partner loyalty programs), some of your data will also be available to our partners, but only for the purposes of such general service provision or for other purposes of providing cooperation.</p>
            <p>If the case described in the Request is recognized as an insured event, we will transfer your Request and data to the insurance company(ies), with which we have insured our civil liability, property or which provides us with other insurance services related to the insurance coverage of the case mentioned provided in your Request. Insurance companies act as independent/separate data controllers and process your data in accordance with the terms and conditions of the insurance company.</p>
            <p>The data may also be transferred to competent/supervising public authorities, municipalities or law enforcement authorities, such as the police, the prosecutor&rsquo;s office, the court or the supervisory/monitoring authorities, but only when such institutions reasonably request such information or only if required so in the cases and the order as provided by the applicable regulatory enactments, in order to ensure the rights of the state, municipalities or law enforcement institutions and/or our rights, the safety of our customers, employees and IT resources, to raise, to submit and to defend legal claims, etc.</p>
            <p>If we use the service of website analysis (e.g. Google Analytics) to determine, how you browse the information provided at the website www.quicktouchmenu.eu or in the mobile application, we may share your anonymised data with persons, who use this information and evaluate, how the website or mobile application is browsed, preparing reports for the website processors on the operation of the website or mobile application and providing other services related to the use of the website, internet and mobile application. More information about it is available in your browser&rsquo;s Cookie Policy.</p>
            <ol start={5}>
                <li><strong> In what territory do we process your personal data?</strong></li>
            </ol>
            <p>We only process your personal data in the territory of the European Union. We currently do not intend to transfer and we do not transfer your personal data to third countries. If we use <a href="http://www.facebook.com/">www.facebook.com</a> or Google Analytics services for the provision of the Services or for communication, with regard to the transfer of data to third countries, the respective service providers are considered third parties and we ask you to read the privacy policies of such service providers.</p>
            <ol start={6}>
                <li><strong> What are your rights and how can you exercise them?</strong></li>
            </ol>
            <p>Regulatory enactments of data protection provides you several rights that you can exercise freely, and we provide you the opportunity to exercise them. We provide information about your specific rights and ways of exercising them in this Policy, therefore please read it carefully:</p>
            <p><strong>6.1. The right to access your personal data that we manage</strong></p>
            <p>You have the right to receive our confirmation, whether we process your personal data, as well as you have the right to access your personal data we process and information about the purposes of data processing, categories of the processed data, categories of data recipients, data processing period, data sources, automated decision-making, including profiling, as well as the meaning of it and consequences it has on you.</p>
            <p>We provide most of this information in this Policy and we believe it will be useful to you.</p>
            <p>If you are a registered user of the website www.quicktouchmenu.eu, you can get acquainted with the personal data we process at any time in your user account (e.g., check the personal data submitted by you, update the information, your provided consents, etc.), receive invoices issued to you for the last 12&nbsp;months. If you are the user of the mobile application, you can get acquainted with all of the above provided information in the mobile application.</p>
            <p>If the information provided in this Policy, in your user account at the website www.quicktouchmenu.eu or in the mobile application is insufficient or you want to receive a history of purchase transactions for a period longer than 12&nbsp;months, you can always contact us using the methods specified in Clause&nbsp;8 of this Policy.</p>
            <p><strong>6.2. The right to correct your personal data</strong></p>
            <p>If the data provided at the time of your registration has changed or you note that the information we process about you is inaccurate or incorrect, you have the right to request that such information is changed, specified or corrected, by contacting us using the methods specified in Clause&nbsp;8 of this Policy.</p>
            <p>You can independently correct (update) your data in the account of the website www.quicktouchmenu.eu or in the mobile application.</p>
            <p><strong>6.3. The right to withdraw consent</strong></p>
            <p>In cases, when we process your data in accordance with your consent, you have the right to withdraw your consent at any time, which will be the basis for the termination of data processing. Withdrawal of consent shall not affect the lawfulness of the processing that has been implemented based on the provided consent prior to the withdrawal.</p>
            <p>For example, you can withdraw your consent to receive offers and newsletters at any time, as well as your consent to data profiling as a result of which individual offers are provided to you. Withdrawal of such consents will not restrict you from continuing to use the Services and fulfilling the obligations of the signed Agreement, but in such case we will not be able to provide and you will not be able to receive current offers and news.</p>
            <p>You may correct (withdraw or resubmit) your consents by submitting your registration information that is accordingly updated regarding the provided consents, changing the terms of the consent in your registered user account or at the mobile application, or by contacting us, using the methods specified in Clause 8 of this Policy.</p>
            <p>When your consent is revoked, we will stop processing the data on the basis of the consent and, in the cases specified in the Policy, will reliably and irrevocably delete or anonymize the data.</p>
            <p>In any case we may also keep your provided consent and evidence of your provided consent for a longer period of time, if this is required in order to be able to defend our legitimate interests in case of claims, complaints or objections made against us.</p>
            <p><strong>6.4. Right to submit complaints</strong></p>
            <p>If you believe that we are processing your data and violating the requirements of data processing/protection regulatory enactments, we ask you to contact us first directly. We believe that we will be able to clear all your doubts, satisfy your requests and correct any mistakes we have made.</p>
            <p>If you are not satisfied with the solutions we offer or if, in your opinion, we are not taking the required actions in accordance with your request, you have the right to submit a complaint to the supervisory authority, which in the Republic of Latvia is the Data State Inspectorate.</p>
            <p><strong>6.5. The right to object to the processing of data, if the processing is justified by legitimate interests</strong></p>
            <p>You have the right to object to the processing of personal data, if the personal data is processed on the basis of our legitimate interests. However, taking into account the purpose of the Service provision and the balance of the legitimate interests of both parties (both as the data subject and as the controller of personal data), your objections may mean that we will not be able to provide you the opportunity to continue to use the Services and the Agreement validity will be terminated and cancelled.</p>
            <p>If you wish to exercise the rights set out in this section, please submit a written request to our Data Protection Specialist, whose contact details are provided in Clause&nbsp;8 of this Policy.</p>
            <p><strong>6.6. Right to delete data (right to be forgotten)</strong></p>
            <p>In certain circumstances in accordance with the regulatory enactments on data processing (e.g., if personal data are processed unlawfully, the legal basis for the processing is lost, personal data are no longer required for the purposes, which they have been collected for or processed otherwise, etc.), you have the right to request us to delete your personal data. If you wish to exercise these rights, please submit a written request to our Data Protection Specialist, whose contact details are provided in Clause&nbsp;8 of this Policy.</p>
            <p>It is important to note that the data of the user registered in the account of the website www.quicktouchmenu.eu will be deleted without your separate request, and the rest of the data will be deleted or securely anonymized, if you stop using the Services on the website www.quicktouchmenu.eu and 2 (two) years have passed since your last activity as a registered user at the website www.quicktouchmenu.eu.</p>
            <p><strong>6.7. Right to restrict data processing</strong></p>
            <p>In certain circumstances referred to in the regulatory enactments on data processing (if personal data is processed unlawfully, you dispute the accuracy of the data, to protect our legitimate interests in case you file objections to the processing of data, etc.), you still have the right to restrict your data processing. However, we must inform you that due to restrictions on data processing and for the duration of such restrictions, we may not be able to provide you with the Services and fulfil our obligations under the Agreement.</p>
            <p>If you wish to exercise the rights set out in this section, please submit a written request to our Data Protection Specialist, whose contact details are provided in Clause&nbsp;8 of this Policy.</p>
            <p><strong>6.8. Right to data transferability</strong></p>
            <p>You have the right to transfer data that we process in accordance with your consent and that are processed by automated means to another data controller. We will provide you with the data you are willing to transfer in an easy-to-use and computer-readable format on our systems, but if you are willing and if such technical possibilities exist, we will transfer the data directly to another data controller specified by you.</p>
            <p>If you wish to exercise your right to data portability, please submit a written request to our Data Protection Specialist, whose contact details are provided in Clause&nbsp;8 of this Policy.</p>
            <ol start={7}>
                <li><strong> How do we review and process your requests?</strong></li>
            </ol>
            <p>To protect the data of our customers from unauthorized disclosure, we will need to verify your identity, when we receive your request to receive your data or to exercise your other rights. For this purpose, we may ask you to indicate the current data registered in your user account at the website www.quicktouchmenu.eu or in the mobile application (for example, name, surname, address, e-mail address or phone number), after which we will compare whether your specified data matches the relevant data of the registered user. During this verification, we may also send a control notice to the contact information provided in the registration form for communication (via a text message or email) requesting authorization. If the verification procedure fails (for example, the data you provide does not match the data provided in the profile of the user registered at the website www.quicktouchmenu.eu or in the mobile application, or you do not implement the authorization procedure after receiving the sent text message or e-mail), we will be forced to state that you are not the subject of the requested data and we will have to reject your request.</p>
            <p>Upon receipt of your request for the exercise of any of your rights and the successful completion of the above mentioned verification procedure, we undertake to provide you with information in accordance with your request without delay, but in any case no later than within one month after receiving your request and completing the verification procedure. Due to the complexity and number of requests, we have the right to extend the one-month period for another two months, informing you before the end of the first month and stating the reasons for such extension.</p>
            <p>If your request has been submitted by electronic means, we will also provide the answer by electronic means, unless it is not possible (for example, due to the large amount of information) or if you ask us to reply in another way.</p>
            <p>We will refuse to satisfy your request with a reasoned reply, if such circumstances specified in the regulatory enactments are stated, when we cannot provide the information to you, by informing you in writing thereof.</p>
            <ol start={8}>
                <li><strong> How can you contact us?</strong></li>
            </ol>
            <ul>
                <li>by e-mail, writing to the e-mail address: rolands@elaw.ee</li>
                <li>by calling our Call Centre phone: +37126488822</li>
            </ul>
            <p>Contact details of the Data Protection Specialist:</p>
            <ul>
                <li>e-mail address: rolands@elaw.ee,</li>
                <li>mail correspondence address: Laki tn 30 Mustamae linnaosa, Tallinn Harju maakind 12915, Estonia. Address your letter to: AuraTrust O&Uuml; Data Protection Specialist</li>
            </ul>
            <ol start={9}>
                <li><strong> How secure are your data?</strong></li>
            </ol>
            <p>We respect your privacy, therefore the security of your personal data is our priority. We use appropriate organizational and technical means to ensure the continued security of your personal data and the compliance of data processing with the requirements of personal data protection regulatory enactments and our internal policy.</p>
            <p>We consistently adhere to the principle of minimizing personal data and do not collect data that we do not require to achieve the objectives set out in this Policy.</p>
            <p>We use a variety of personal data protection and security technologies and procedures to protect your personal data from unauthorized access, use or disclosure. Our partners are carefully selected and we require them to use appropriate means to protect the confidentiality of personal data and to ensure the security of your personal information. However, complete security cannot be guaranteed in the transmission of your information to us via Internet or mobile communications; any case of transferring your information to us in the ways provided above is implemented at your own risk.</p>
            <ol start={10}>
                <li><strong> Cookies</strong></li>
            </ol>
            <p>We use cookies (which are small information files that are sent to your computer or other device (for example, smartphone), when you visit our website and which are stored in your browser). A cookie is sent to your computer or other device for the purpose of storing data, so that we can recognize you as the user of the Services, when you visit our website. With the help of the cookies, we may also link your purchase history, other data you have collect, while using the Services to your browsing activities on the Internet. The information obtained with the help of cookies allows us to provide you more convenient browsing experience, to provide attractive offers and to learn more about the actions of our website users, to analyse trends and to improve both the website and your service experience.</p>
            <p>Also third-party cookies are used at our website. They are used to create the browsing history of each visitor in order to display the ads specifically intended for you and to provide the best experience, when visiting our website. If your browser allows you to record third-party cookies, our selected partner will be able to record their own cookies in your browser.</p>
            <p>For more information about cookies, their use and opting-out, see your browser&rsquo;s Cookie Policy.</p>
            <ol start={11}>
                <li><strong> The validity of the Policy and its changes</strong></li>
            </ol>
            <p>The Privacy Policy of AuraTrust O&Uuml; web-site www.quicktouchmenu.eu can be changed or updated. You can always find the latest version of the Privacy Policy at the web-site www.quicktouchmenu.eu.</p>
            <ol start={12}>
                <li><strong> Application of the Policy</strong></li>
            </ol>
            <p>The Policy is not applicable to other services provided by us or by our group companies.</p>
        </>
    );
};

export default PolicyEn;