import './Social.scss';
import InstaIcon from "../../icons/social/InstaIcon";
import YoutubeIcon from "../../icons/social/YoutubeIcon";
import FacebookIcon from "../../icons/social/FacebookIcon";
import LinkedinIcon from "../../icons/social/LinkedinIcon";
import SocialItem from "../../atoms/SocialItem/SocialItem";
import {FC} from "react";


interface ISocial {
    className?: string;
}

const Social:FC<ISocial> = ({className=''}) => {
    return (
        <div className={`social ${className}`}>
            <SocialItem link={'https://www.instagram.com/quicktouchwaiter'} >
                <InstaIcon/>
            </SocialItem>
            <SocialItem link={'https://youtube.com/@quicktouchmanager7181'} >
                <YoutubeIcon/>
            </SocialItem>
            <SocialItem link={'https://www.facebook.com/quicktouchmenu'} >
                <FacebookIcon/>
            </SocialItem>
            <SocialItem link={'https://www.linkedin.com/company/qtm-systems/'} >
                <LinkedinIcon/>
            </SocialItem>
        </div>
    );
};

export default Social;