import { FC, useEffect, useRef, useMemo } from "react";

import "./GetAllSection.scss";
import qtw_managment_online_payment_notebook from "../../../assets/images/qtw_managment_online_payment_notebook.svg";
import qtw_managment_online_bookings_notebook from "../../../assets/images/qtw_managment_online_bookings_notebook.svg";
import qtw_managment_online_orders_notebook from "../../../assets/images/qtw_managment_online_orders_notebook.svg";
import qtw_managment_online_menu_notebook from "../../../assets/images/qtw_managment_online_menu_notebook.svg";

import qtw_managment_online_payment_phone from "../../../assets/images/qtw_managment_online_payment_phone.svg";
import qtw_managment_online_bookings_phone from "../../../assets/images/qtw_managment_online_bookings_phone.svg";
import qtw_managment_online_orders_phone from "../../../assets/images/qtw_managment_online_orders_phone.svg";
import qtw_managment_online_menu_phone from "../../../assets/images/qtw_managment_online_menu_phone.svg";

import { useTranslation, Trans } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useDispatchEx } from "../../../hooks/redux";
import List from "../../atoms/List/List";
import { anime1Finish, anime1Start } from "../../../helpers/animations";
const GetAllSection: FC = () => {
  gsap.registerPlugin(ScrollTrigger);

  const targetSection = useRef(null);
  useEffect(() => {
    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: targetSection.current,
        start: "+=20% bottom",
      },
    });
    timeline.fromTo(".get-all-section", anime1Start, anime1Finish, 0.1);
  }, [targetSection]);
  const { t } = useTranslation();
  const dispatch = useDispatchEx();

  const picturesArray = useMemo(() => {
    const arrayForNotebooks = [
      qtw_managment_online_menu_notebook,
      qtw_managment_online_orders_notebook,
      qtw_managment_online_bookings_notebook,
      qtw_managment_online_payment_notebook,
    ];

    if (window.innerWidth > 767) return arrayForNotebooks;

    return [
      qtw_managment_online_menu_phone,
      qtw_managment_online_orders_phone,
      qtw_managment_online_bookings_phone,
      qtw_managment_online_payment_phone,
    ];
  }, [window.innerWidth]);

  return (
    <section className={"get-all-section"} ref={targetSection}>
      <div className="container">
        <div className="wrapper">
          <div className="get-all-section__header">
            <h2 className="h2">
              <span>GET</span> all restaurant management for free and instantly
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-6 d-flex align-items-center get-all-section__item-exclude">
              <img src={picturesArray[0]} alt="Online menu" />
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <img src={picturesArray[1]} alt="Online orders" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center">
              <img src={picturesArray[2]} alt="Online bookings" />
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <img src={picturesArray[3]} alt="Online payment " />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetAllSection;
