import {FC, ReactNode} from 'react';
import './Plate.scss'
export interface IPlate{
    type?: "big" | "small"
    className?: string;
    title?: ReactNode;
    icon?: ReactNode;
    description?: ReactNode;
}

const Plate:FC<IPlate> = ({type='small',className='',title,icon}) => {
    return (
        <div className={`plate ${className} plate--${type}`}>
            <div className="plate__wrapper">
                <div className="plate__icon">{icon}</div>
                <div className="plate__title">{title}</div>
            </div>

        </div>
    );
};

export default Plate;