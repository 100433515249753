import { FC, useEffect, useRef } from "react";
import "./PaymentSection.scss";
import qtw_online_payment from "../../../assets/images/qtw_online_payment.svg";
import paymentsImg from "../../../assets/images/payments/payments.png";
import { useTranslation, Trans } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useDispatchEx } from "../../../hooks/redux";
import List from "../../atoms/List/List";
import { anime1Finish, anime1Start } from "../../../helpers/animations";
const PaymentSection: FC = () => {
  gsap.registerPlugin(ScrollTrigger);

  const targetSection = useRef(null);
  useEffect(() => {
    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: targetSection.current,
        start: "+=20% bottom",
      },
    });
    timeline.fromTo(".payment-section__inner", anime1Start, anime1Finish, 0.1);
    timeline.fromTo(
      ".payment-section__wrapper",
      anime1Start,
      anime1Finish,
      0.4,
    );
  }, [targetSection]);
  const { t } = useTranslation();
  const dispatch = useDispatchEx();
  return (
    <section className={"payment-section"} ref={targetSection}>
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-6 d-none d-lg-flex">
              <div className="payment-section__inner">
                <div className="payment-section__image">
                  <img
                    src={qtw_online_payment}
                    alt="Quick Touch waiter using example"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="payment-section__wrapper">
                <div className="payment-section__header">
                  <h2 className="h2">
                    <span>ONLINE PAYMENT</span> modern and convenient solutions
                  </h2>
                </div>
                <div className="payment-section__description">
                  Instant payments/tips/deposits
                </div>
                <div className="payment-section__inner d-flex d-lg-none">
                  <div className="payment-section__image">
                    <img
                      src={qtw_online_payment}
                      alt="Quick Touch waiter using example"
                    />
                  </div>
                </div>
                <div className="payment-section__payments">
                  <img src={paymentsImg} alt="" />
                </div>
                <div className="payment-section__list">
                  <List
                    className={"list--check"}
                    items={[
                      "Easy way to get online payment and tips",
                      "Ideal replacement for bank terminal",
                      "Online deposits for table reservations",
                      "Instant money transfer to the any account",
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSection;
