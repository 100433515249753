import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationLV from './locales/lv/translation.json';
import translationLT from './locales/lt/translation.json';
import translationET from './locales/et/translation.json';

i18n
    .use(Backend)
    .use (initReactI18next)
    .init({
        fallbackLng: localStorage.getItem('qtm-lang') ? localStorage.getItem('qtm-lang') as string: 'en',
        debug: false,
        detection: {
            order: ['queryString', 'cookie','localStorage'],
            cache: ['cookie']
        },
        resources:{
            en: {
                translation: translationEN
            },
            lv: {
                translation: translationLV
            },
            et: {
                translation: translationET
            },
            de: {
                translation: translationDE
            },
            lt: {
                translation: translationLT
            }
        },
        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p','span','b','ol','li','ul','a'],
            useSuspense: false
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;