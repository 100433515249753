import {FC} from 'react';

interface IIcon {
    width: number;
    height: number;
}

const MailIcon:FC<IIcon> = ({width,height}) => {
    return (
        <svg width={`${width}`} height={`${height}`} viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15.4703" cy="16.3585" r="15.4703" fill="black"/>
            <path d="M14.8534 16.5467C15.2413 16.9267 15.7717 16.9267 16.1597 16.5467L21.163 11.575L22.0892 10.6488C21.7409 10.4667 21.353 10.3638 20.9334 10.3638H10.0797C9.66008 10.3638 9.27216 10.4667 8.92383 10.6488L9.85008 11.575L14.8534 16.5467Z" fill="white"/>
            <path d="M13.0701 16.4512L8.90596 12.3029L8.03513 11.4399C7.75013 11.8437 7.5918 12.3266 7.5918 12.857V20.1404C7.5918 20.5837 7.71055 21.0033 7.90846 21.3595C7.91638 21.3516 7.91638 21.3516 7.9243 21.3437L8.8268 20.4808L13.0701 16.4512Z" fill="white"/>
            <path d="M17.1578 17.2285L16.9995 17.3868C16.572 17.8064 16.0416 18.0202 15.5032 18.0202C14.9728 18.0202 14.4424 17.8064 14.0149 17.3868L13.912 17.2839L9.63698 21.3689L8.75031 22.2081C8.7424 22.216 8.73448 22.2239 8.72656 22.2239C9.11448 22.4852 9.57365 22.6356 10.0803 22.6356H20.9341C21.4328 22.6356 21.8999 22.4852 22.2878 22.2239L21.4011 21.361L17.1578 17.2285Z" fill="white"/>
            <path d="M22.9855 11.4399L22.1147 12.3029L17.998 16.3879L22.1939 20.4808L23.1043 21.3674C23.3101 21.0112 23.4289 20.5837 23.4289 20.1404V12.857C23.4289 12.3266 23.2705 11.8437 22.9855 11.4399Z" fill="white"/>
        </svg>


    );
};

export default MailIcon;