import { FC, useEffect, useRef } from "react";
import "./MainSection.scss";
import qtw_phone_with_logo from "../../../assets/images/qtw_phone_with_logo.svg";
import { useTranslation, Trans } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useDispatchEx } from "../../../hooks/redux";
import ArrowIcon from "../../icons/ArrowIcon";
import {
  anime1Finish,
  anime1Start,
} from "../../../helpers/animations";
const MainSection: FC = () => {
  gsap.registerPlugin(ScrollTrigger);

  const targetSection = useRef(null);
  useEffect(() => {
    let timeline = gsap.timeline({});
    timeline.fromTo(".main__wrapper", anime1Start, anime1Finish, 0.1);
    timeline.fromTo(".main__image", anime1Start, anime1Finish, 0.4);
  }, [targetSection]);
  const { t } = useTranslation();
  const dispatch = useDispatchEx();

  const onClickDownload = (e: any) => {
    e.preventDefault();
    document.getElementById("download")?.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <section className={"main"} ref={targetSection}>
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="main__wrapper">
                <div className="main__header">
                  <h1 className="h1">
                    <span>Take your restaurant online</span> in a few minutes
                  </h1>
                </div>
                <div className="main__description">
                  A complete order management and payment system for guest
                  service and staff assistance
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="main__image">
                <div className="main__image-title">
                  We help to increase your income
                </div>
                <img src={qtw_phone_with_logo} alt="Quick Touch Waiter app" />
                <div className="main__button">
                  <div className={"main__btn"}>
                    <a
                      className={"link-line--reverse"}
                      onClick={onClickDownload}
                    >
                      Download{" "}
                      <div className="main__circle">
                        <ArrowIcon />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
