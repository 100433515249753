import React from 'react';

const PolicyLt = () => {
    return (
        <>
            <p><strong>Privatumo politika</strong></p>
            <p>Registruodamiesi interneto svetainėje <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> ar mobiliojoje programėlėje Jūs sutinkate, kad Jūsų asmens duomenis tvarkys Valdytojas (toliau &ndash; Bendrovė, arba mes, arba Valdytojas) &ndash; O&Uuml; &bdquo;AuraTrust&ldquo; (įmonės kodas Estijos Respublikoje 16562585, buveinės adresas Ravi tn 2, 10134, Talinas, Estija, ir suteikiate mums teisę juos tvarkyti &scaron;ios Politikos (toliau &ndash; Politika) nustatyta tvarka, atsisiųsdami ir naudodami mobiliąją programėlę bei registruodamiesi ir prisijungdami, taip pat sudarydami paslaugų teikimo sutartis pagal atitinkamas naudojimo sąlygas (toliau &ndash; Sąlygos). Informuojame, kad Jūsų asmens duomenis tvarkome tik tuo atveju, jei pasirenkate mus ir leidžiate mums tvarkyti savo asmens duomenis.</p>
            <p>Įgyvendinant 2016 m. balandžio 27 d. Europos Parlamento ir Tarybos Reglamento (ES) 2016/679 dėl fizinių asmenų apsaugos tvarkant asmens duomenis ir dėl laisvo tokių duomenų judėjimo ir kuriuo panaikinama Direktyva 95/46/EB (Bendrasis duomenų apsaugos reglamentas), užtikriname Jums ypač skaidrų ir sąžiningą Jūsų asmens duomenų tvarkymą, taip pat rūpinamės Jūsų privatumu ir asmens duomenų saugumu.</p>
            <p>&Scaron;ioje Politikoje rasite visą informaciją apie tai, kokius Jūsų duomenis renkame ir tvarkome, kam juos naudojame, kiek laiko saugome ir kt. &Scaron;i informacija yra svarbi, todėl prie&scaron; pateikiant mums informaciją apie savo asmens duomenis kviečiame susipažinti su &scaron;iomis Politikos sąlygomis.</p>
            <p>Atkreipiame Jūsų dėmesį į tai, kad tiek Politika, tiek sąlygos gali būti keičiamos, papildomos, atnaujinamos.</p>
            <p>Jei nesutiksite su sąlygomis, &scaron;ia Politika ar atskiromis jos taisyklėmis, deja, mes negalėsime suteikti Jums galimybės naudotis visomis ar bet kuria i&scaron; mūsų teikiamų paslaugų (toliau &ndash; Paslaugos).</p>
            <ol>
                <li><strong> Kas yra asmens duomenys ir kokius Jūsų asmens duomenis mes tvarkome ir kodėl?</strong></li>
            </ol>
            <p><strong>Registracija</strong></p>
            <p><strong>Asmens duomenys &ndash; bet kokia informacija, susijusi su fiziniu asmeniu, kurio tapatybė yra nustatyta arba gali būti nustatyta (&bdquo;duomenų subjektas&ldquo;); fizinis asmuo, kurio tapatybę galima nustatyti, yra asmuo, kurio tapatybę tiesiogiai arba netiesiogiai galima nustatyti, visų pirma, pagal identifikatorių, kaip antai vardą ir pavardę, asmens identifikavimo numerį, buvimo vietos duomenis ir interneto identifikatorių arba pagal vieną ar kelis to fizinio asmens fizinės, fiziologinės, genetinės, psichinės, ekonominės, kultūrinės ar socialinės tapatybės požymius.</strong></p>
            <p>Registruodamiesi interneto svetainėje <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> ar mobiliojoje programėlėje, turite sutikti su svetainės ar mobiliosios programėlės naudojimosi sąlygomis (turite pritarti Sutarčiai). Jūsų asmens duomenų pateikimas yra privalomas, nes jų nepateikę negalėsite sudaryti Sutarties ir naudotis mūsų teikiamomis paslaugomis. Registruodamiesi Jūs sudarote Sutartį ir patvirtinate, kad Jūsų pateikti asmens duomenys yra tikslūs ir teisingi ir kad esate ne jaunesnis nei 18 metų. Mes neprisiimame atsakomybės už Jūsų pateiktus netikslius, nei&scaron;samius ar klaidingus asmens duomenis. Tuo atveju, jei nurodėte kito asmens duomenis, atsiradus pretenzijoms, O&Uuml; &bdquo;AuraTrust&ldquo; pasilieka teisę pateikti Jums regresinį ie&scaron;kinį.</p>
            <p><strong>Sutarčiai reikalingi duomenys:</strong></p>
            <ul>
                <li>Duomenų kategorijos &ndash; Jūsų vardas, pavardė, adresas, el. pa&scaron;to adresas ir telefono numeris.</li>
                <li>Asmens duomenų tvarkymo teisinis pagrindas &ndash; Sutarčiai, kurios susitariančioji &scaron;alis esate Jūs, sudaryti ir vykdyti, taip pat mūsų teisėtiems interesams apsaugoti.</li>
                <li>Duomenų tvarkymo terminas &ndash; visą laikotarpį, kol galioja Sutartis, o Jūs naudojatės mūsų paslaugomis. Su Jumis sudarytą Sutartį ir jos sudarymo įrodymus galime i&scaron;saugoti ir ilgiau, kol baigiasi prievolinių reikalavimų, kurių gali atsirasti dėl sutartinių įsipareigojimų, terminas, taip pat, jei tai būtina, kad galėtume apginti savo teisėtus interesus, jei mums yra pareik&scaron;ti reikalavimai, pretenzijos ar ie&scaron;kiniai.</li>
            </ul>
            <p>Mes atpažįstame Jus i&scaron; Jūsų pateiktų asmens duomenų, kai, pavyzdžiui, norite atnaujinti arba pakeisti savo duomenis, kreipiatės į mus dėl atitinkamos asmens informacijos pateikimo, su asmens duomenų tvarkymu susijusių teisių įgyvendinimu ir kt. Pagal asmens duomenis, pateiktus registruojantis svetainėje <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> ir (arba) mobiliojoje programėlėje, sudarius Sutartį sukuriamas Jūsų unikalus vartotojo profilis (paskyra), pagal kurią mes Jus identifikuosime kaip registruotą svetainės<a href="http://www.quicktouchmenu.eu">www.qucktouchmenu.eu</a> ir (arba) mobiliosios programėlės vartotoją, leidžiantį Jums naudotis registruotiems vartotojams tinkamomis Paslaugomis, remiantis Sutarties nuostatomis ir &scaron;ios Politikos sąlygomis.</p>
            <p>Jūsų pateiktus kontaktinius duomenis (el. pa&scaron;tą, telefono numerį) mes galime naudoti bendravimui su Jumis, vykdydami Sutarties įsipareigojimus, taip pat atsakydami į Jūsų užklausas, pretenzijas, teikdami svarbią informaciją apie Paslaugas, jų teikimą ir (arba) &scaron;ios Politikos pakeitimus, susisiekdami su Jumis, jei pamir&scaron;ote savo užsakytus pirkinius, ar konstatavę Sutarties įsipareigojimų vykdymo sunkumus, susijusius su atliktais pirkimo sandoriais ir pan.</p>
            <p>Labai svarbu, kad Jūsų pateikti asmens duomenys būtų tikslūs ir teisingi. Jei pateiksite neteisingus (klaidingus) asmens duomenis, juos pamir&scaron;ite ar jų neatnaujinsite, pasikeitus Jūsų asmens duomenims, mums gali kilti sunkumų užtikrinant Sutarties įsipareigojimų vykdymą ir Paslaugų teikimą Jums, taip pat gali kilti kitų sunkumų įgyvendinant Jūsų teises. Mes jokiu būdu neatsakysime už žalą, kurią galite patirti dėl savo pateiktų neteisingų ar netikslių asmens duomenų.</p>
            <p>Jei keičiasi Jūsų pateikti duomenys, nedelsdami turite apie tai prane&scaron;ti mums, interneto svetainėje atitinkamus asmens duomenis pakeisdami savo registruoto vartotojo profilyje <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> arba mobiliojoje programėlėje. Apie tai, kaip atnaujinti savo pasikeitusius asmens duomenis, skaitykite &scaron;ios Politikos 6.2 punkte.</p>
            <p>Įmonė neturi galimybės patikrinti, ar Jūsų pateikti asmens duomenys yra teisingi ir atitinka tikrovę, jei Jūs jų mums nepateikiate. Priimdami Jūsų registraciją ir sudarydami su mumis Sutartį, mes laikome, kad Jūsų pateikti asmens duomenys yra tikslūs ir teisingi.</p>
            <p><strong>1.2. Jūsų paskyros administravimas</strong></p>
            <p>Mes tvarko,e Jūsų asmens duomenis, kad galėtume stebėti ir administruoti Jūsų paskyrą.</p>
            <p><strong>Paskyros duomenys:</strong></p>
            <p>Duomenų kategorijos &ndash; registruojantis (svetainėje <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a>) nurodyti duomenys, prisijungimo prie paskyros duomenys, paskyroje atliktų veiksmų duomenys, įskaitant techninius nar&scaron;ymo duomenis (IP adresas, techninė prisijungimo ir nar&scaron;ymo informacija).</p>
            <p>Teisinis duomenų tvarkymo pagrindas &ndash; Jūsų sudaryta Sutartis ir sukurta paskyra Sutarties įsipareigojimams vykdyti, taip pat siekiant apsaugoti mūsų teisėtus interesus.</p>
            <p>Duomenų tvarkymo laikotarpis &ndash; visą laiką, kol esate paskyros vartotojas. Sutarties sudarymo įrodymus mes galime saugoti ir ilgesnį laikotarpį, jei tai būtina, kad galėtume apsiginti nuo mums pareik&scaron;tų reikalavimų, pretenzijų ar ie&scaron;kinių.</p>
            <p>Jūsų registraciją, naudojant paskyrą svetainėje <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a>, mes laikome gauta, kai atliekate Sąlygose nurodytus veiksmus, reikalingus paskyrai sukurti ir patvirtinti registraciją.</p>
            <p><strong>1.3. Jūsų pirkimo duomenų tvarkymas</strong></p>
            <p><strong>Pirkimo duomenų tvarkymas</strong></p>
            <p>Teikdami Jums paslaugas ir įvairius privalumus, duomenis apie pirkimo operacijas (toliau &ndash; pirkimo duomenys) mes lygiagrečiai tvarkome su Jūsų registracijos duomenimis.</p>
            <p><strong>Jūsų pirkimo duomenys bus tvarkomi paslaugų administravimo tikslais:</strong></p>
            <p>Duomenų kategorijos &ndash; Jūsų vardas, pavardė, adresas, el. pa&scaron;to adresas, telefono numeris, adresas, prekių pavadinimai, kiekiai, pirkinių kainos ir suteiktos nuolaidos, pirkinių apmokėjimo būdas ir mokėjimo informacija.</p>
            <p>Teisinis duomenų tvarkymo pagrindas &ndash; Jūsų sudaryta Sutartis ir sukurta paskyra Sutarties įsipareigojimams vykdyti, taip pat siekiant apsaugoti mūsų teisėtus interesus ir vykdyti teisinius įsipareigojimus, kurių atsiranda ir yra taikomi Bendrovei.</p>
            <p>Duomenų tvarkymo terminas &ndash; 5 metai atlikus pirkimo operaciją. Dokumentus mes galime i&scaron;saugoti ir ilgiau, kol baigiasi prievolinių reikalavimų, kurių gali atsirasti dėl sutartinių įsipareigojimų, terminas, taip pat, jei tai būtina, kad galėtume apginti savo teisėtus interesus, jei mums yra pareik&scaron;ti reikalavimai, pretenzijos ar ie&scaron;kiniai.</p>
            <p><strong>1.4. Duomenų profiliavimas administruojant Paslaugų teikimą</strong></p>
            <p>Administruodami Paslaugų teikimą ir siekdami Sąlygose nurodytų tikslų, mes galime naudoti automatizuotą asmens duomenų (įskaitant Jūsų pirkimo duomenis) analizę ir automatizuotą sprendimų priėmimą, įskaitant Jūsų profiliavimą. Jūsų duomenis galime grupuoti ir analizuoti pagal Jūsų įsigytas prekes ir (arba) atsižvelgdami į kitas Jums būdingas savybes (pvz., vardą, adresą ir kt.).</p>
            <p>Pavyzdžiui, Jūsų pirkimo duomenų analizę ir profiliavimą galime atlikti pagal Jūsų interesus, i&scaron;taisydami savo veiklos klaidas. Pastebėję, kad dėl klaidos mūsų pirkėjams buvo pritaikyta neteisinga prekės kaina ar netinkama nuolaida, pagal pirkimo duomenis mes galime identifikuoti pirkėjus, kurie pirko &scaron;ias prekes, ir i&scaron;taisyti savo klaidą (kompensuoti kainos skirtumą ir pan.).</p>
            <p>Svarbu pabrėžti, kad mūsų atliekama Jūsų duomenų analizė ir profiliavimas Jums neturi jokio teisinio ar kitokio poveikio.</p>
            <p>Tačiau, jei prie&scaron;taraujate automatizuotai Jūsų duomenų analizei ir profiliavimui &scaron;ioje Politikos dalyje numatytais tikslais, mes priimsime ir atsižvelgsime į Jūsų prie&scaron;taravimus. Vis dėlto, atsižvelgiant į Paslaugų teikimo reikalavimus, Jūsų prie&scaron;taravimas gali reik&scaron;ti, kad mes negalėsime suteikti Jums galimybės naudotis mūsų Paslaugomis.</p>
            <p><strong>1.5. Pasiūlymų ir informacijos teikimas</strong></p>
            <p>Jei registruodamiesi svetainėje&nbsp;<a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> ar mobiliojoje programėlėje Jūs sutikote gauti mūsų pasiūlymus ir (arba) naujausius pasiūlymus savo el. pa&scaron;to adresu arba mobiliojoje programėlėje, arba SMS naujienas savo telefone, mes tvarkysime Jūsų asmens duomenis, teikdami Jums pasiūlymus ir informaciją, pavyzdžiui, naujienlai&scaron;kius, informaciją apie mūsų ir mūsų verslo partnerių teikiamus pasiūlymus, nuolaidas, akcijas, i&scaron;pardavimus, kviesime Jus dalyvauti akcijose, loterijose ir laimėti prizų, teirausimės Jūsų nuomonės apie teikiamas paslaugas ir produktus ir kt. Pasiūlymus ir informaciją teikiame Jūsų paskyroje ir mobiliojoje programėlėje, taip pat kitu Jūsų pasirinktu būdu, pavyzdžiui, el. pa&scaron;tu, SMS žinute Jūsų nurodytu telefono Nr.</p>
            <p><strong>Asmens duomenys, tvarkomi pasiūlymų ir informacijos teikimo tikslu:</strong></p>
            <p>Duomenų kategorijos:&nbsp;Jūsų vardas, el. pa&scaron;to adresas ir (arba) telefono numeris.</p>
            <p>Duomenų tvarkymo teisinis pagrindas:&nbsp;Jūsų sutikimas gauti pasiūlymus ir informaciją apie aktualias naujienas, taip pat mūsų teisėtiems interesams apsaugoti.</p>
            <p>Duomenų tvarkymo terminas: kol galioja Jūsų sutikimas gauti pasiūlymus ir naujienas. Jūsų duotą sutikimą mes galime saugoti ir ilgesnį laikotarpį, jei tai būtina, kad galėtume apsiginti nuo mums pareik&scaron;tų reikalavimų, pretenzijų ar ie&scaron;kinių.</p>
            <p>Privatumo nustatymuose Jūs galite pasirinkti norimus komunikacijos kanalus svetainėje&nbsp;<a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> esančioje savo vartotojo paskyroje arba mobiliojoje programėlėje. &Scaron;iuos nustatymus galite be apribojimų keisti bet kuriuo metu.</p>
            <p>Jei nepageidaujate, kad Jums būtų siunčiami mūsų pasiūlymai ir (arba) naujienos, Jūs galite nesuteikti mums savo sutikimo, arba, jei davėte savo sutikimą gauti pasiūlymus ir (arba) naujienas, galite bet kada jų atsisakyti arba keisti savo pasirinktus prane&scaron;imų gavimo būdus (kanalus). Jūsų atsisakymas gauti pasiūlymus ir naujienas netrukdys naudotis Paslaugomis ir vykdyti Sutartyje numatytus įsipareigojimus.</p>
            <p><strong>1.6. Duomenų profiliavimas teikiant individualius pasiūlymus</strong></p>
            <p>Jei registruodamiesi svetainėje <a href="http://www.quicktouchmenu.eu">www.quicktouchmenu.eu</a> ar mobiliojoje programėlėje Jūs sutikote gauti ir individualius (asmeninius) pasiūlymus atsižvelgdami į savo užsakymų istoriją, ir sutikote su profiliavimu tuo tikslu, kad Jus geriau pažintume, mes tvarkysime ne tik Jūsų pateiktus, bet ir papildomus duomenis, kuriuos analizuosime ir naudosimės Jūsu pirkimo duomenų istorija.</p>
            <p><strong>Asmens duomenys, tvarkomi individualių pasiūlymų teikimo tikslu:</strong></p>
            <p>Duomenų kategorijos:&nbsp;Jūsų vardas, pavardė, adresas, el. pa&scaron;to adresas ir telefono numeris, pirkimo duomenys, Jums teiktų individualių pasiūlymų istorija ir informacija apie jų naudojimą.</p>
            <p>Duomenų tvarkymo teisinis pagrindas:&nbsp;Jūsų sutikimas, kad Jūsų duomenys būtų profiliuojami Jums teikiant individualius pasiūlymus, taip pat mūsų teisėtiems interesams apsaugoti.</p>
            <p>Duomenų tvarkymo terminas: kol galioja Jūsų sutikimas dėl duomenų profiliavimo siekiant Jums pasiūlyti individualius (asmeninius) pasiūlymus. Jūsų duotą sutikimą ir įrodymus mes galime saugoti ir ilgesnį laikotarpį, jei tai būtina, kad galėtume apsiginti nuo mums pareik&scaron;tų reikalavimų, pretenzijų ar ie&scaron;kinių.</p>
            <p>Jei Jūs sutinkate, kad Jūsų duomenys būtų profiliuojami Jums teikiant individualius (asmeninius) pasiūlymus, Jūs turite galimybę gauti asmeninius pasiūlymus, atsižvelgiant į Jūsų poreikius, bei kitas, specialiai Jums numatytas lengvatas. Siekdami &scaron;ių tikslų mes naudojame automatizuotą duomenų analizę ir sprendimų priėmimą, įskaitant profiliavimą. Mes grupuojame ir analizuojame Jūsų ir (arba) pirkimo duomenis ir atsižvelgdami į Jūsų pirkimo įpročius ir (arba) kitus požymius (pavyzdžiui, adresą, konkrečius pirkinius ir kt.) Jums teikiame aktualius, įdomius ir naudingus pasiūlymus, taip pat kitą informaciją apie individualius pasiūlymus.</p>
            <p>Pavyzdžiui, remdamiesi Jūsų pirkimo istorija ir apsipirkimo įpročiais, mes galime suteikti nuolaidų Jūsų mėgstamiausių kategorijų prekėms, informuoti Jus apie individualaus asortimento naujienas ir kt.</p>
            <p>Jei nenorite, kad Jūsų duomenys būtų profiliuojami, Jums teikiant individualius pasiūlymus, <b>galite neduoti mums savo sutikimo arba, jei davėte savo sutikimą gauti individualius pasiūlymus, bet kada galite jų atsisakyti</b>. Tokiu atveju Jūsų atsisakymas gauti individualius pasiūlymus nesutrukdys Jums naudotis Paslaugomis ir pagal Sutartį vykdyti įsipareigojimus, tačiau vėliau nebegalėsite gauti individualių pasiūlymų ir aktualios asmeninės informacijos.</p>
            <p><strong>1.7. Statistika, rinkos ir mūsų pirkėjų elgsenos tyrimai</strong></p>
            <p>Nuolat stengiamės, kad mūsų prekių asortimentas, taikomos akcijos ir nuolaidos kuo geriau atitiktų mūsų pirkėjų, įskaitant Jus, poreikius.</p>
            <p><strong>Duomenys, tvarkomi statistikos, rinkos ir mūsų pirkėjų elgsenos tyrimo tikslais:</strong></p>
            <p>Duomenų kategorijos: adresas, pirkimo istorija.</p>
            <p>Duomenų tvarkymo teisinis pagrindas:&nbsp;Mūsų teisėti interesai &ndash; analizuoti pirkimo duomenis konkrečiame regione pagal klientų adresus ir parengti savo komercinei veiklai reikiamas ataskaitas, siekiant įvertinti savo verslą, ir teikti naudos tiek Jums kaip klientui, tiek Bendrovės komercinei veiklai.</p>
            <p>Duomenų tvarkymo terminas:&nbsp;5 kalendoriniai metai nuo užsakymo pateikimo.</p>
            <p><b>Automatizuotą duomenų analizę</b> naudojame atlikdami statistinių duomenų, rinkos ir pirkėjų elgsenos tyrimus, taip pat rengdami komercinei veiklai reikalingas ataskaitas. Analizuodami mes naudojame <b>nesuasmenintus duomenis</b> ir netvarkome informacijos, susijusios su Jūsų vardu, kontaktiniais duomenimis ir kita individualizuota informacija.&nbsp; Statistikos, rinkos ir mūsų pirkėjų elgsenos tyrimo tikslais tvarkomi duomenys (adresas ir pirkimo duomenys) padeda mums priimti svarbius komercinės veiklos sprendimus, susijusius su mūsų pirkėjų poreikiais, tinkamo prekių asortimento kūrimu, kainų formavimu ir pan.</p>
            <p>&Scaron;ių duomenų analizė neturi įtakos Jūsų teisėtiems ar pana&scaron;aus pobūdžio interesams, taip pat tolimesniam Paslaugų ir Jūsų sudarytos Sutarties įsipareigojimų vykdymui.</p>
            <p><strong>1.8. Klientų užklausų, skundų, pra&scaron;ymų ir atsiliepimų tvarkymas</strong></p>
            <p>Jūsų asmens duomenis naudosime atsakydami į Jūsų užklausas, skundus, pra&scaron;ymus ir administruodami Jūsų atsiliepimus (toliau &ndash; <b>Užklausa</b>).</p>
            <p><strong>Duomenys, tvarkomi klientų užklausų, skundų, pra&scaron;ymų ir atsiliepimų apdorojimo tikslais:</strong></p>
            <p>Duomenų kategorijos:&nbsp;</p>
            <ul>
                <li>Jūsų pateikti identifikavimo ir kontaktinės informacijos duomenys: vardas, pavardė, telefono numeris, el. pa&scaron;to adresas, adresas ir pan.;</li>
                <li>Jūsų Užklausos turinys: atvejis, dėl kurio kreipiatės, aplinkybės, data, vieta, Jūsų pra&scaron;ymas, reikalavimas ar atsiliepimas, prekė, kita Užklausoje pateikta informacija;</li>
                <li>kiti su Užklausa pateikti dokumentai ir (arba) duomenys: pavyzdžiui, prekės pirkimo &ndash; sąskaitos faktūros / sąskaitos / prekių važtara&scaron;čio duomenys, prekės nuotraukos;</li>
                <li>telefoninio pokalbio įra&scaron;as, jei kreipiatės į mūsų klientų aptarnavimui skirtą <em>Pagalbos liniją</em>.</li>
            </ul>
            <p>Duomenų tvarkymo teisinis pagrindas:&nbsp;savo teisinių įsipareigojimų vykdymas Jūsų užklausoms i&scaron;nagrinėti ir atsakyti, taip pat mūsų teisėti interesai vertinant Jūsų kaip kliento atsiliepimus ir įra&scaron;ant telefoninius pokalbius, siekiant tobulinti savo veiklos ir Jums teikiamų paslaugų kokybę bei apsaugoti bendrovės teisėtus interesus.</p>
            <p>Duomenų tvarkymo terminas:&nbsp;iki 12 mėnesių. Jūsų užklausas apie prekes galime i&scaron;saugoti ir ilgiau, kol baigiasi prievolinių reikalavimų, kurių gali atsirasti dėl sutartinių įsipareigojimų, terminas, taip pat, jei tai būtina, kad galėtume apginti savo teisėtus interesus, jei mums yra pareik&scaron;ti reikalavimai, pretenzijos ar ie&scaron;kiniai.</p>
            <p>Jūsų duomenis naudojame tik tam, kad tinkamai ir objektyviai galėtume i&scaron;nagrinėti Jūsų Užklausas, teikti Jums reikiamą informaciją, atsakyti į Jūsų klausimus, rasti i&scaron;eitį pagal Jūsų pateiktus pra&scaron;ymus ar reikalavimus (skundus). Mes galime analizuoti Jūsų duomenis, siekdami tobulinti savo veiklą ir Jums teikiamų paslaugų kokybę, atsižvelgdami į Jūsų pateiktą nuomonę ir pasiūlymus.</p>
            <p><strong>1.9. Kiek laiko mes tvarkome ir saugome Jūsų duomenis?</strong></p>
            <p>Jūsų Užklausą ir su ja susijusius duomenis mes tvarkome ir saugome tol, kol ją i&scaron;nagrinėjame, pateikiame Jums atsakymą ir vykdome priimtus sprendimus, taip pat iki 6 (&scaron;e&scaron;ių mėnesių) nuo Užklausos i&scaron;ai&scaron;kinimo, bet ne trumpiau kaip:</p>
            <ul>
                <li>užklausas, gautas elektroniniu būdu el. pa&scaron;tu &ndash; ne ilgiau kaip 6 mėnesius;</li>
                <li>skundus &ndash; 12 mėnesių;</li>
                <li>telefoninių pokalbių, gautų skambučių centre, įra&scaron;us &ndash; 14 dienų;</li>
                <li>atsiliepimų įvertinimus (žvaigždučių saugojimą) &ndash; ne ilgiau kaip 12 mėnesių.</li>
            </ul>
            <p>Jeigu dėl Užklausos pradėtas teisminis ginčas arba toks gali kilti, Jūsų duomenis galime saugoti ilgesnį laiką, kol pasibaigs įstatymu numatyti skundo ar ie&scaron;kinio pateikimo terminai ir (arba) įsigalios galutinis sprendimas.</p>
            <p>Pasibaigus &scaron;ioje Politikoje numatytam Jūsų duomenų tvarkymo ir saugojimo terminui, mes savaranki&scaron;kai sunaikinsime dokumentus, kuriuose yra Jūsų asmens duomenys, arba patikimai ir negrįžtamai i&scaron;trinsime ar anonimizuosime Jūsų asmens duomenis, gautus elektroniniu būdu, kuo greičiau, per protingą laiką, būtiną tokiems veiksmams atlikti.</p>
            <ol start={2}>
                <li><strong> I&scaron; kokių &scaron;altinių mes gauname ir surenkame Jūsų asmens duomenis?</strong></li>
            </ol>
            <p>Jūsų asmens duomenis gauname ir tvarkome vadovaudamiesi &scaron;iais teisiniais pagrindais:</p>
            <ul>
                <li>Jūsų sutikimu;</li>
                <li>mūsų teisėtais interesais;</li>
                <li>vykdant įsipareigojimus, kylančius ir taikomus Bendrovei.</li>
            </ul>
            <p>Jūsų duomenų tvarkymui gali būti taikomas vienas i&scaron; pirmiau nurodytų teisinių pagrindų.</p>
            <p>Beveik visus Jūsų asmens duomenis gauname tik i&scaron; Jūsų. Registruodamiesi svetainėje&nbsp;www.quicktouchmenu.eu ar mobiliojoje programėlėje Jūs duomenis pateikiate tiesiogiai mums, bet pirkimo duomenis &ndash; naudodamiesi Paslaugomis.</p>
            <p>Jūsų duomenis tiesiogiai i&scaron; Jūsų gauname ir tada, kai pateikiate Užklausą bet kuriuo pasirinktu būdu: para&scaron;ydami mums elektroninį lai&scaron;ką ir i&scaron;siųsdami jį mūsų el. pa&scaron;to adresu, pateikdami mums ra&scaron;ti&scaron;ką Užklausą arba žodžiu, skambindami telefonu į mūsų klientų aptarnavimo skambučių centrą ir kt.</p>
            <p>Kai mums reikia gauti papildomos informacijos arba i&scaron;tirti svarbias aplinkybes, kad galėtume kokybi&scaron;kai ir objektyviai i&scaron;nagrinėti Jūsų Užklausą, Jūsų Užklausos duomenis galime susieti su savo turimais ir (arba) Jūsų Užklausos nagrinėjimo metu surinktais duomenimis, pavyzdžiui, mes galime peržiūrėti pirkimo operacijų duomenis, Jūsų paskyros istoriją, apklausti savo darbuotojus ir pan.</p>
            <ol start={3}>
                <li><strong> Kaip mes duomenis saugome ir sunaikiname?</strong></li>
            </ol>
            <p>Pasibaigus &scaron;ioje Politikoje nurodytam Jūsų duomenų tvarkymo ir saugojimo laikotarpiui (i&scaron;skyrus toliau nurodytus atvejus), mes saugiai sunaikinsime Jūsų duomenis, Politikoje nurodytais atvejais &ndash; patikimai ir negrįžtamai anonimizuosime, kuo greičiau, per protingą ir pagrįstą laiką, būtiną tokiems veiksmams atlikti.</p>
            <p>Jūsų asmens duomenys gali būti saugomi ilgiau nei nurodyta &scaron;ioje Politikoje tik esant bet kuriai i&scaron; toliau nurodytų aplinkybių:</p>
            <ul>
                <li>tai būtina, kad galėtume apsiginti nuo reikalavimų, pretenzijų ar ie&scaron;kinių ir galėtume pasinaudoti savo teisėmis;</li>
                <li>asmens duomenų saugojimo terminas yra nustatytas arba atsiranda i&scaron; Latvijos Respublikos ir Europos Sąjungos teisės aktų;</li>
                <li>yra pagrįstų įtarimų dėl neteisėto elgesio, tokiu atveju atliekamas tyrimas;</li>
                <li>Jūsų duomenys būtini norint tinkamai i&scaron;nagrinėti ginčą, skundą;</li>
                <li>reikia pateikti atsarginę kopiją ir užtikrinti kitus pana&scaron;ius tikslus;</li>
                <li>esant kitiems teisiniams pagrindams.</li>
            </ul>
            <p>Esant &scaron;ioms aplinkybėms, informacija, kurioje yra asmens duomenų, bus saugoma iki atitinkamo proceso (baudžiamojo proceso, administracinių pažeidimų proceso, administracinio proceso pabaigos) perdavimo teisėsaugos institucijai arba iki galutinio jos priimto sprendimo.</p>
            <ol start={4}>
                <li><strong> Kokiais atvejais ir kokiems duomenų gavėjams mes atskleidžiame Jūsų duomenis?</strong></li>
            </ol>
            <p>Jūsų duomenis galime perduoti asmens duomenų gavėjams, t. y. asmenims, kurie mums padeda atlikti ir administruoti Paslaugų teikimą, teikia mums su klientų Užklausų administravimu susijusias paslaugas. &Scaron;ie asmenys gali būti IT duomenų bazių programinės įrangos tiekėjai, duomenų bazių administravimo paslaugų teikėjai, duomenų centrų priežiūros ir debesijos paslaugų teikėjai, tiesioginės rinkodaros paslaugų teikėjai, rinkos tyrimų ar verslo analizės paslaugų teikėjai ir pan. Kiekvienu atveju duomenų tvarkytojams pateikiame tik tiek duomenų, kiek reikia konkrečiai užduočiai atlikti arba konkrečiai paslaugai suteikti. Mūsų pasitelkti duomenų tvarkytojai Jūsų asmens duomenis gali tvarkyti tik pagal mūsų nurodymus ir negali jų naudoti kitiems tikslams ar be mūsų sutikimo perduoti kitiems asmenims. Be to, jie privalo užtikrinti Jūsų duomenų apsaugą pagal ga;iojančius įstatymus ir mūsų sudarytą ra&scaron;ytinį susitarimą.</p>
            <p>Jei teikdami Paslaugas suteiksime teisę naudotis savo partnerių paslaugomis (pvz., partnerių vykdomų lojalumo programų teikiamomis lengvatomis), dalis Jūsų duomenų bus prieinama ir mūsų verslo partneriams, tačiau tik tokio bendro paslaugų teikimo ar kitais bendradarbiavimo sąlygų tikslais.</p>
            <p>Jei Užklausoje apra&scaron;ytas atvejis bus pripažintas draudimo įvykiu, Jūsų Užklausą ir duomenis perduosime draudimo bendrovei (-ėms), kurioje (-iose) esame apdraudę savo civilinę atsakomybę, turtą arba kuri (-ios) suteikia mums kitą draudimo apsaugą, susijusią su Jūsų Užklausoje nurodytu atveju. Draudimo bendrovės veikia kaip nepriklausomi / atskiri duomenų valdytojai ir Jūsų duomenis tvarko pagal draudimo bendrovės sąlygas bei taisykles.</p>
            <p>Duomenys taip pat gali būti perduodami kompetentingoms / valstybinės priežiūros, savivaldybės ar teisėsaugos institucijoms, pavyzdžiui, policijai, prokuratūrai, teismui ar kontroliuojančioms / priežiūros institucijoms, tačiau tik gavus jų pagrįstą pra&scaron;ymą arba tik tada, kai tai būtina pagal galiojančius teisės aktus arba teisės aktų nustatytais atvejais ir tvarka, siekiant užtikrinti valstybės, savivaldybių ar teisėsaugos institucijų ir (arba) mūsų teises, mūsų pirkėjų, darbuotojų ir IT i&scaron;teklių saugumą, siekiant i&scaron;kelti, pateikti ir apginti teisinius ie&scaron;kinius ir pan.</p>
            <p>Jei naudojame svetainės analizės paslaugą (pvz., Google Analytics), kuri naudojama nustatyti, kaip Jūs nar&scaron;ote interneto svetainėje&nbsp;www.quicktouchmenu.eu arba mobiliojoje programėlėje pateiktą informaciją, Jūsų anoniminiais duomenimis galime keistis su asmenimis, kurie remiasi &scaron;ia informacija ir vertina, kaip nar&scaron;oma svetainėje ar mobiliojoje programėlėje, svetainės tvarkytojams parengiant ataskaitas apie svetainės ar mobiliosios programėlės veikimą ir teikiant kitas su svetainės, interneto ir mobiliosios programėlės naudojimu susijusias paslaugas. Daugiau informacijos apie tai rasite nar&scaron;yklės slapukų politikoje.</p>
            <ol start={5}>
                <li><strong> Kurioje teritorijoje mes tvarkome Jūsų asmens duomenis?</strong></li>
            </ol>
            <p>Jūsų asmens duomenis tvarkome tik Europos Sąjungos teritorijoje. &Scaron;iuo metu neketiname perduoti ir neperduodame Jūsų asmens duomenų trečiosioms &scaron;alims. Tuo atveju, jei Paslaugoms teikti ar komunikacijai naudojame&nbsp;<a href="http://www.facebook.com/">www.facebook.com</a> arba &bdquo;Google analytics&ldquo; paslaugas, tuomet, kalbant apie duomenų perdavimą trečiosioms &scaron;alims, atitinkami paslaugos teikėjai laikomi trečiuoju asmeniu ir kviečiame susipažinti su &scaron;ių paslaugų teikėjų privatumo politikomis.</p>
            <ol start={6}>
                <li><strong> Kokios yra Jūsų teisės ir kaip galite jomis pasinaudoti?</strong></li>
            </ol>
            <p>Duomenų apsaugos įstatymai suteikia Jums daugiau teisių, kuriomis galite laisvai naudotis, o mes suteikiame galimybę jomis naudotis. Informaciją apie konkrečias Jūsų teises ir jų įgyvendinimo būdus pateikiame &scaron;ioje Politikoje, pra&scaron;ome atidžiai ją perskaityti:</p>
            <p><strong>6.1. Teisė susipažinti su savo asmens duomenimis, kuriuos mes administruojame</strong></p>
            <p>Jūs turite teisę gauti mūsų patvirtinimą dėl to, ar mes tvarkome Jūsų asmens duomenis, taip pat teisę susipažinti su tvarkomais Jūsų asmens duomenimis ir informacija apie duomenų tvarkymo tikslus, tvarkomų duomenų kategorijas, duomenų gavėjų kategorijas, duomenų tvarkymo laikotarpį, duomenų gavimo &scaron;altinius, automatizuotų sprendimų priėmimą, įskaitant profiliavimą, taip pat jų reik&scaron;mę ir pasekmes Jums.</p>
            <p>Didžiąją dalį &scaron;ios informacijos pateikiame &scaron;ioje Politikoje ir tikime, kad ji bus naudinga.</p>
            <p>Jeigu esate svetainės www.quicktouchmenu.eu registruotas paskyros vartotojas, Jūs galite bet kada savo vartotojo paskyroje susipažinti su mūsų tvarkomais Jūsų asmens duomenimis (pvz., patikrinti savo pateiktą asmens duomenų informaciją, atnaujinti informaciją, savo sutikimus ir pan.), gauti Jums i&scaron;ra&scaron;ytas sąskaitas faktūras už pastaruosius 12 mėnesių. Jei esate mobiliosios programėlės vartotojas, su visa minėta informacija galite susipažinti mobiliojoje programėlėje.</p>
            <p>Jei &scaron;ioje Politikoje, interneto svetainės www.quicktouchmenu.eu&nbsp;Jūsų vartotojo paskyroje ar mobiliojoje programėlėje pateiktos informacijos nepakanka arba norite gauti daugiau nei 12 mėnesių pirkimo operacijų istoriją, Jūs visada galite susisiekti su mumis &scaron;ios Politikos 8 punkte nurodytais būdais.</p>
            <p><strong>6.2. Teisė taisyti asmens duomenis</strong></p>
            <p>Jeigu pasikeitė Jūsų registracijos metu nurodyti duomenys arba matote, kad mūsų tvarkoma informacija apie Jus yra netiksli ar neteisinga, Jūs turite teisę reikalauti, kad &scaron;i informacija būtų pakeista, patikslinta ar i&scaron;taisyta, susisiekus su mumis &scaron;ios Politikos 8 punkte nurodytais būdais arba</p>
            <p>Jūs galite savaranki&scaron;kai pataisyti (atnaujinti) savo duomenis svetainės www.quicktouchmenu.eu paskyroje arba mobiliojoje programėlėje.</p>
            <p><strong>6.3. Teisė at&scaron;aukti sutikimą</strong></p>
            <p>Tais atvejais, kai Jūsų duomenis tvarkome remdamiesi Jūsų sutikimu, Jūs turite teisę bet kuriuo metu at&scaron;aukti savo sutikimą, kuris bus pagrindas duomenų tvarkymui nutraukti. Sutikimo at&scaron;aukimas nedaro poveikio sutikimu pagrįsto duomenų tvarkymo, atlikto iki sutikimo at&scaron;aukimo, teisėtumui.</p>
            <p>Pavyzdžiui, bet kuriuo metu galite at&scaron;aukti savo sutikimą gauti pasiūlymus ir naujienas, taip pat sutikimą dėl duomenų profiliavimo, teikiant Jums asmeninius pasiūlymus. &Scaron;ių sutikimų at&scaron;aukimas neapribos Jūsų galimybės toliau naudotis Paslaugomis ir vykdyti Sutarties įsipareigojimus, tačiau tai rei&scaron;kia, kad mes nebegalėsime Jums teikti, o Jūs nebegalėsite gauti naujausių pasiūlymų ir naujienų.</p>
            <p>Savo sutikimus galite koreguoti (juos at&scaron;aukti arba juos duoti i&scaron; naujo), pateikdami savo atitinkamai atnaujintą registracijos informaciją apie duotus sutikimus, keisdami sutikimo sąlygas savo registruotoje vartotojo paskyroje arba mobiliojoje programėlėje ar susisiekdami su mumis &scaron;ios Politikos 8 punkte nurodytais būdais.</p>
            <p>Kai Jūsų sutikimas bus at&scaron;auktas, mes nutrauksime duomenų tvarkymą sutikimo pagrindu ir Politikoje nurodytais atvejais &ndash; duomenis patikimai ir negrįžtamai i&scaron;trinsime arba anonimizuosime.</p>
            <p>Bet kuriuo atveju Jūsų duotą sutikimą ir jo įrodymą mes galime saugoti ir ilgesnį laikotarpį, jei tai būtina, kad mes galėtume apginti savo teisėtus interesus nuo mums pareik&scaron;tų reikalavimų, pretenzijų ar ie&scaron;kinių.</p>
            <p><strong>6.4. Teisė pateikti skundą</strong></p>
            <p>Jei manote, kad Jūsų duomenis tvarkome pažeisdami duomenų tvarkymo / apsaugos teisės aktų reikalavimus, pirmiausia pra&scaron;ytume susisiekti su mumis. Tikime, kad galėsime i&scaron;sklaidyti visas Jūsų abejones, patenkinti pra&scaron;ymus ir pa&scaron;alinti klaidas, jei tokių atsirastų.</p>
            <p>Jeigu Jūsų netenkina mūsų siūlomi problemų sprendimo būdai arba, Jūsų nuomone, mes neatliksime reikiamų veiksmų pagal Jūsų pra&scaron;ymą, Jūs turite teisę pateikti skundą priežiūros institucijai &ndash; Latvijos Respublikos valstybinei duomenų apsaugos inspekcijai.</p>
            <p><strong>6.5. Teisė nesutikti su duomenų tvarkymu, kai tvarkymas grindžiamas teisėtais interesais</strong></p>
            <p>Jūs turite teisę nesutikti, kad būtų tvarkomi asmens duomenys, jeigu jie tvarkomi vadovaujantis mūsų teisėtais interesais. Tačiau, atsižvelgiant į Paslaugų teikimo tikslus ir abiejų &scaron;alių (tiek Jūsų, kaip duomenų subjekto, tiek mūsų, kaip asmens duomenų valdytojo) teisėtų interesų pusiausvyrą, Jūsų prie&scaron;taravimai gali reik&scaron;ti, kad nutraukus Jūsų duomenų tvarkymą dėl mūsų teisėtų interesų mes negalėsime suteikti Jums galimybės naudotis Paslaugomis, o Sutarties vykdymas bus sustabdytas ir ji bus nutraukta.</p>
            <p>Jei norite pasinaudoti &scaron;iame skyriuje nurodytomis teisėmis, pra&scaron;ome pateikti ra&scaron;ti&scaron;ką pra&scaron;ymą mūsų duomenų apsaugos pareigūnui, kurio kontaktinė informacija pateikta &scaron;ios Politikos 8 punkte.</p>
            <p><strong>6.6. Teisė i&scaron;tinti duomenis (teisė būti pamir&scaron;tam)</strong></p>
            <p>Esant tam tikroms duomenų tvarkymo teisės aktuose nurodytoms aplinkybėms (pavyzdžiui, jei asmens duomenys tvarkomi neteisėtai, i&scaron;nyksta teisinis duomenų tvarkymo pagrindas, asmens duomenys nebėra reikalingi, kad būtų pasiekti tikslai, kuriais jie buvo renkami ar kitaip tvarkomi ir kt.), Jūs turite teisę pra&scaron;yti, kad mes i&scaron;trintume Jūsų asmens duomenis. Jei norite pasinaudoti &scaron;ia teise, pra&scaron;ome pateikti ra&scaron;ti&scaron;ką pra&scaron;ymą mūsų duomenų apsaugos pareigūnui, kurio kontaktinė informacija pateikta &scaron;ios Politikos 8 punkte.</p>
            <p>Svarbu akcentuoti, kad Jūsų interneto svetainės www.quicktouchmenu.eu paskyroje registruoti vartotojo duomenys be atskiro Jūsų pra&scaron;ymo, o likusieji duomenys bus i&scaron;trinti arba saugiai anonimizuoti, jeigu Jūs nustosite naudotis svetainės www.quicktouchmenu.eu Paslaugomis ir bus praėję 2 (dveji) metai nuo paskutinių Jūsų, kaip registruoto vartotojo, atliktų veiksmų svetainėje&nbsp;www.quicktouchmenu.eu.</p>
            <p><strong>6.7. Teisė apriboti duomenų tvarkymą</strong></p>
            <p>Esant tam tikroms duomenų tvarkymo teisės aktuose nurodytoms aplinkybėms (jei asmens duomenys tvarkomi neteisėtai, Jūs ginčijate duomenų tikslumą, prie&scaron;taraujate duomenų tvarkymui, kuris grindžiamas mūsų teisėtais interesais ir kt.), Jūs taip pat turite teisę apriboti savo duomenų tvarkymą. Tačiau turime atkreipti dėmesį, kad dėl duomenų tvarkymo apribojimo ir &scaron;ių apribojimų laikotarpiu gali būti neįmanoma teikti Jums Paslaugas ir vykdyti Sutarties įsipareigojimus.</p>
            <p>Jei norite pasinaudoti &scaron;iame skyriuje nurodytomis teisėmis, pra&scaron;ome pateikti ra&scaron;ti&scaron;ką pra&scaron;ymą mūsų duomenų apsaugos pareigūnui, kurio kontaktinė informacija pateikta &scaron;ios Politikos 8 punkte.</p>
            <p><strong>6.8. Teisė į duomenų perkeliamumą</strong></p>
            <p>Duomenis, kuriuos tvarkome pagal Jūsų sutikimą ir automatizuotomis priemonėmis, Jūs turite teisę perkelti kitam duomenų valdytojui.&nbsp; Duomenis, kuriuos norite perkelti, mes pateiksime patogiu mūsų sistemose naudojamu ir kompiuteriu nuskaitomu formatu, tačiau jei Jūsų pageidausite ir turėsite techninių galimybių, duomenis perkelsime tiesiogiai kitam Jūsų nurodytam duomenų valdytojui.</p>
            <p>Jei norite pasinaudoti teise į duomenų perkeliamumą, pra&scaron;ome pateikti ra&scaron;ti&scaron;ką pra&scaron;ymą mūsų duomenų apsaugos pareigūnui, kurio kontaktinė informacija pateikta &scaron;ios Politikos 8 punkte.</p>
            <ol start={7}>
                <li><strong> Kaip mes nagrinėjame ir tvarkome Jūsų užklausas?</strong></li>
            </ol>
            <p>Siekdami apsaugoti savo pirkėjų duomenis nuo neteisėto atskleidimo, gavę Jūsų pra&scaron;ymą dėl Jūsų duomenų pateikimo ar pasinaudodami kitomis Jūsų teisėmis, mes turėsime patikrinti Jūsų tapatybę. &Scaron;iuo tikslu galime papra&scaron;yti nurodyti svetainės www.quicktouchmenu.eu savo vartotojo paskyroje arba mobiliojoje aplikacijoje vartotojo profilyje registruotus naujausius duomenis (pavyzdžiui, vardą, pavardę, adresą, el. pa&scaron;to adresą arba telefono numerį), tada palyginsime, ar Jūsų nurodyti duomenys sutampa su atitinkamais registruoto vartotojo duomenimis. &Scaron;io patikrinimo metu mes taip pat galime i&scaron;siųsti kontrolinį prane&scaron;imą pagal registracijos anketoje nurodytus kontaktinės informacijos duomenis (teksto žinute arba el. pa&scaron;tu), pra&scaron;ydami suteikti leidimą. Jei patikros procedūra nepavyks (pavyzdžiui, Jūsų pateikti duomenys nesutaps su svetainėje www.quicktouchmenu.eu arba mobiliojoje aplikacijoje registruotame vartotojo profilyje nurodytais duomenimis arba nesuteiksite įgaliojimo gavę i&scaron;siųstą trumpąją žinutę ar prane&scaron;imą el. pa&scaron;tu), būsime priversti konstatuoti, jog Jūs nesate pra&scaron;omų duomenų subjektas ir privalėsime atmesti Jūsų pateiktą pra&scaron;ymą.</p>
            <p>Gavę Jūsų pra&scaron;ymą dėl tam tikrų Jūsų teisių įgyvendinimo ir sėkmingai baigę pirmiau nurodytą patikros procedūrą mes įsipareigojame nedelsdami, tačiau bet kuriuo atveju ne vėliau kaip per vieną mėnesį nuo Jūsų pra&scaron;ymo gavimo dienos ir patikros procedūros pabaigos teikti Jums informaciją pagal Jūsų pateiktą pra&scaron;ymą. Atsižvelgdami į pra&scaron;ymų sudėtingumą ir jų kiekį turime teisę pratęsti vieno mėnesio terminą, kuris truktų dar du mėnesius, apie tai informuojant Jus iki pirmojo mėnesio pabaigos ir nurodant &scaron;io pratęsimo priežastis.</p>
            <p>Jei Jūsų pra&scaron;ymas buvo pateiktas elektroninio ry&scaron;io priemonėmis, atsakymą taip pat pateiksime pasinaudoję elektroninio ry&scaron;io priemonėmis, i&scaron;skyrus atvejus, kai tai nėra įmanoma (pavyzdžiui, dėl didelio informacijos kiekio) arba jeigu Jūs pra&scaron;ysite pateikti kitu būdu.</p>
            <p>Jūsų pra&scaron;ymą atsisakysime patenkinti pagrįstu atsakymu, jeigu bus nustatytos teisės aktuose numatytos aplinkybės, tais atvejais, kai negalime Jums suteikti informacijos, ir apie tai Jus informuosime ra&scaron;tu.</p>
            <ol start={8}>
                <li><strong> Kaip galite su mumis susiekti?</strong></li>
            </ol>
            <ul>
                <li>elektroniniu pa&scaron;tu, ra&scaron;ydami el. pa&scaron;to adresu rolands@elaw.ee</li>
                <li>skambindami mūsų Skambučių centro telefono numeriu +37126488822</li>
            </ul>
            <p>Duomenų apsaugos pareigūno kontaktiniai duomenys:</p>
            <ul>
                <li>pa&scaron;to adresas rolands@elaw.ee,</li>
                <li>adresas korespondencijai: Laki tn 30 Mustamae linnaosa, Tallinn Harju maakind 12915, Estonia. Lai&scaron;ką adresuokite: O&Uuml; &bdquo;AuraTrust&ldquo; duomenų apsaugos pareigūnui</li>
            </ul>
            <ol start={9}>
                <li><strong> Jūsų duomenų saugumas</strong></li>
            </ol>
            <p>Mes gerbiame Jūsų privatumą, todėl Jūsų asmens duomenų saugumas yra mūsų prioritetas. Naudojame tinkamas organizacines ir technines priemones, siekdami užtikrinti nuolatinį Jūsų asmens duomenų saugumą ir duomenų tvarkymo atitiktį asmens duomenų apsaugos įstatymams bei mūsų vidaus politikos reikalavimams.</p>
            <p>Mes nuosekliai laikomės asmens duomenų kiekio mažinimo principo ir nerenkame duomenų, kurių mums nereikia &scaron;ios Politikos tikslams pasiekti.</p>
            <p>Siekdami apsaugoti Jūsų asmeninę informaciją nuo neteisėtos prieigos, naudojimo ir atskleidimo, naudojame įvairias asmens duomenų apsaugos ir saugumo technologijas bei procedūras. Mūsų verslo partneriai yra kruop&scaron;čiai atrenkami, ir mes reikalaujame, kad jie naudotų atitinkamas priemones asmens duomenų konfidencialumui apsaugoti ir Jūsų asmeninės informacijos saugumui užtikrinti. Tačiau negalime garantuoti visi&scaron;ko saugumo, jeigu Jūs informaciją perduodate mums internetu ar mobiliuoju ry&scaron;iu; bet koks Jūsų informacijos perdavimas mums pirmiau nurodytais būdais atliekamas Jūsų pačių rizika.</p>
            <ol start={10}>
                <li><strong> Slapukai</strong></li>
            </ol>
            <p>Mes naudojame slapukus (tai nedidelė teksto rinkmena, kurią svetainė i&scaron;saugo Jūsų kompiuteryje ar kitame įrenginyje (pavyzdžiui, mobiliajame telefone), kai joje apsilankote, ir i&scaron;saugoma Jūsų naudojamoje nar&scaron;yklėje). Slapukai siunčiami į Jūsų kompiuterį ar kitą įrenginį, kad galėtume Jus atpažinti kaip Paslaugų vartotoją, kai lankotės mūsų svetainėje. Naudodami slapukus mes taip pat galime susieti Jūsų pirkimo istoriją, kitus duomenis, surinktus naudojantis Paslaugomis, su Jūsų nar&scaron;ymu internete. Informacija, gauta naudojant slapukus, leidžia Jums patogiau nar&scaron;yti, teikti patrauklesnius pasiūlymus ir daugiau sužinoti apie mūsų svetainės vartotojų elgseną, analizuoti tendencijas ir tobulinti tiek svetainę, tiek Jums teikiamas paslaugas.</p>
            <p>Mūsų svetainė taip pat naudoja trečiųjų &scaron;alių slapukus. Jie naudojami kuriant kiekvieno lankytojo nar&scaron;ymo istoriją, siekiant parodyti reklamą, kuri skirta būtent Jums, ir suteikti geriausią patirtį lankantis mūsų svetainėje. Jei Jūsų nar&scaron;yklė leidžia nustatyti trečiųjų &scaron;alių slapukus, mūsų pasirinktas partneris turės galimybę įra&scaron;yti savo slapukus Jūsų nar&scaron;yklėje.</p>
            <p>Daugiau informacijos apie slapukus, jų naudojimą ir atsisakymą rasite nar&scaron;yklės Slapukų politikos skyriuje.</p>
            <ol start={11}>
                <li><strong> Politikos galiojimas ir pakeitimai</strong></li>
            </ol>
            <p>O&Uuml; &bdquo;AuraTrust&ldquo; interneto svetainės www.quicktouchmenu.eu privatumo politika gali būti keičiama ar atnaujinama. Svetainėje www.quicktouchmenu.eu visada rasite naujausią privatumo politikos versiją.</p>
            <ol start={12}>
                <li><strong> Politikos taikymas</strong></li>
            </ol>
            <p>Politika nėra taikoma kitoms mūsų ar mūsų grupės įmonių teikiamoms paslaugoms.</p>
        </>
    );
};

export default PolicyLt;