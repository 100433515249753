import {FC} from 'react';

const ArrowIcon:FC = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.843133 0.343146L0.843134 2.33719L8.74152 2.34426L0.136027 10.9497L1.55024 12.364L10.1557 3.75847L10.1628 11.6569H12.1568V0.343146H0.843133Z" fill="white"/>
        </svg>
    );
};

export default ArrowIcon;